<template>
  <div class="content">
    <div class="column is-flex is-align-items-center pl-4 modal-steps is-justify-content-center">
      <h4 class="mr-4 is-flex is-align-items-center">Avaa pakkaus</h4>
      <button
        :class="{ active: activeAnimation === 0 }"
        class="upload-button button is-primary mt-4 mr-1"
        @click="() => activeAnimation=0"
      >
        <span>Auki</span>
      </button>
      <button
        :class="{ active: activeAnimation === 1 }"
        class="upload-button button is-primary mt-4 mr-1"
        @click="() => activeAnimation=1"
      >
        <span>Puoliksi auki</span>
      </button>
      <button
        :class="{ active: activeAnimation === 2 }"
        class="upload-button button is-primary mt-4 mr-1"
        @click="() => activeAnimation=2"
      >
        <span>Kiinni</span>
      </button>
    </div>
    <div class="pane">
      <div class="three-dee-component">
        <ThreeDeeMultipleObjects
          :data="data"
          :debug="false"
          :animation="data.animations[activeAnimation]"
          :options="threeDeeOptions"
          v-on:renderready="ready()"
        >
          <ThreeDeeOverlay :overlayMessage="'Kierrä kuvaa raahaamalla ja zoomaa kuvaa hiiren rullalla'"/>
        </ThreeDeeMultipleObjects>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import {ThreeDeeMultipleObjects, ThreeDeeOverlay} from '@packagemedia/vue-three-dee'
import {mapMutations} from "vuex";

export default {
  name: 'ThreeDPreviewMultipleObjects',
  components: {ThreeDeeMultipleObjects, ThreeDeeOverlay},
  props: {
    data: {
      required: true
    },
  },

  setup(props) {
    const activeAnimation = ref(0)
    const threeDeeOptions = computed(() => {
        return {
          autoRotate: false,
          autoRotateSpeed: -10,
          background: false,
          backgroundColor: '#FFFFFF',
          cameraDistance: {min: 250, max: 300,},
          grid: false,
          kiosk: false,
          preset: null,
          screenSpacePanning: false,
          skeleton: false,
          wireframe: false,
          zoom: 1,
        }
      }
    )
    return {
      threeDeeOptions,
      activeAnimation,
    }
  },

  methods: {
    ready() {
      this.$emit('renderready')
    }
  }

}

</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.relative {
  position: relative;
  height: 200px;
  width: 200px;
}

body {
  color: navy;

  h1 {
    font-size: 3rem;
    margin: 2rem 1rem;
  }

  .pane {
    //display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .three-dee-component {
    width: 100%;
    height: 70vh;
    min-height: 500px;
  }

  .content {
    margin-top: -10px;
  }

  .modal-steps {
    margin-bottom: 5px;

    h4 {
      font-size: 23px;
      font-weight: 500;
      color: #222;
      height: 40px;
      margin-bottom: 0px !important;
    }

    div {
      display: flex;
      gap: 10px;
      button {
        width: 120px;
      }
    }

    .button {
      background-color: #EBEFF5;
      color: #15256B;
    }

    .button.active {
      background-color: #15256B;
      color: #EBEFF5;
    }
  }

}
</style>
