<template>
  <div class="ordertype">
    <div class="hero is-fullheight">
      <SectionHeader></SectionHeader>
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="mt-6 has-text-centered">
              <h1>Kiitos viestistäsi.</h1>
              <p>
                Otamme sinuun yhteyttä mahdollisimman pian.
              </p>
              <button
                class="upload-button button is-primary mt-6"
                @click="toLandingPage()"
              >
                <span>Etusivulle</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import {useMeta} from "vue-meta"
import {mapGetters} from 'vuex'

export default {
  name: 'OrderDeliveryFormThanksPage',
  components: {
    SectionHeader,
    Footer
  },
  mounted() {
    if (!this.getCalendarMode()) {
      useMeta({
        title: 'Kiitos yhteydenotostasi – Custom Fazer',
        description: 'Kiitos yhteydenotostasi. Custom Fazer -palvelu tarjoaa personoituja tuotteita liike- ja asiakaslahjaksi ja henkilöstön muistamiseen.'
      })
    } else {
      useMeta({
        title: 'Kiitos yhteydenotostasi – Fazer Oma kalenteri',
        description:
          'Kiitos yhteydenotostasi. Fazer Oma kalenteri -palvelusta voit tilata suklaajoulukalenterin omalla kuvalla.',
      })
    }
    window.scrollTo(0, 0)
  },
  methods: {
    toLandingPage() {
      this.$router.push({name: 'Home'})
    },
    ...mapGetters({
      getCalendarMode: 'getCalendarMode'
    }),
  }
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

.hero-body {
  background-color: #EBEFF5;
  .container {
    background-color: #EBEFF5;
  }
}

@media screen and (min-width: 769px) {
  .margin-l-desktop-2 {
    margin-left: 2%;
  }
}

.grey-border {
  border: 1px solid #ebeff5;
}

input[type="radio"] {
  display: none;
}

.graphical-radio {
  background: white;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #15256B;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  position: relative;
  top: 4px;
}

input[type="radio"]:checked + .graphical-radio {
  background: #15256B;
}

label {
  font-size: 23px;
}

</style>
