<template>
  <div>

    <div class="columns is-mobile is-multiline is-hidden-tablet">
      <div v-if="isEditable" class="column is-2">
        <span v-if="hasMinimumAmount" class="icon minus-icon mr-2" @click="decreaseAmount()">
          <i class="fas fa-minus-circle"></i>
        </span>
        <span v-else class="icon minimum-required minus-icon mr-2" @click="decreaseAmount()">
          <i class="fas fa-minus-circle"></i>
        </span>
      </div>
      <div class="column is-6 has-text-centered ml-3 pr-0 is-relative">
        <p class="bubble-mobile" v-if="!hasMinimumAmount && !noLabel">Tarkista tilausmäärä</p>
        <input type="number" pattern="\d*" @keypress="preventNonNumericalInput(event)" min="1" :disabled="!isEditable" v-model.number="productAmount" @change="updateAmount" class="input has-text-centered amount-input" />
      </div>
      <div v-if="isEditable" class="column is-2">
        <span v-if="hasMinimumAmount"  class="icon plus-icon ml-2" @click="increaseAmount()">
          <i class="fas fa-plus-circle"></i>
        </span>
        <span v-else class="icon minimum-required plus-icon" @click="increaseAmount()">
          <i class="fas fa-plus-circle"></i>
        </span>
      </div>
    </div>

    <div class="is-hidden-mobile is-flex is-align-items-baseline">
      <span v-if="isEditable" class="icon minus-icon mr-2" @click="decreaseAmount()">
        <i class="fas fa-minus-circle"></i>
      </span>
      <p class="bubble" v-if="!hasMinimumAmount && !noLabel">Tarkista tilausmäärä</p>
      <!-- <span class="has-text-centered amount" :class="{ red: !hasMinimumAmount}">x {{ productAmount}}</span> -->
      <input type="number" pattern="\d*" @keypress="preventNonNumericalInput(event)" min="1" :disabled="!isEditable" v-model.number="productAmount" @change="updateAmount" class="input has-text-centered amount-input" data-testid="order-size" />
      <span v-if="isEditable" class="icon plus-icon ml-2" @click="increaseAmount()">
        <i class="fas fa-plus-circle"></i>
      </span>
    </div>

  </div>
</template>

<script>
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'
import {mapActions, mapGetters, mapMutations} from "vuex"
import debounce from 'lodash/debounce'

export default {
  name: "productAmount",
  props: ['modelValue', 'isEditable', 'product', 'noLabel'],
  data(){
    return {
      productAmount: this.modelValue
    }
  },
  methods: {
    ...mapActions({
     updateOrder: 'updateOrder'
    }),
    ...mapMutations([
      'setSpinnerState'
    ]),
    ...mapGetters({
      getSavedItems: 'getSavedItems',
      getCalendarMode: 'getCalendarMode'
     }),
    increaseAmount: debounce(function () {
      if ((this.getCalendarMode() && this.$store.getters.getTotalAmount('calendar') < 20) || !this.getCalendarMode()) {
        this.setSpinnerState(true)
        this.productAmount += 1
        this.$emit('update:modelValue', this.productAmount)
        this.updateOrder({'products': this.getSavedItems()}).then(() => {
          this.setSpinnerState(false)
        })
      }
    }, 100),
    decreaseAmount: debounce(function () {
      if (this.productAmount > 1) {
        this.setSpinnerState(true)
        this.productAmount -= 1
        this.$emit('update:modelValue', this.productAmount)
        this.updateOrder({'products': this.getSavedItems()}).then(() => {
          this.setSpinnerState(false)
        })
      }
    }, 100),
    updateAmount: debounce(function () {
      this.setSpinnerState(true)
      this.$emit('update:modelValue', this.productAmount)
      this.updateOrder({'products': this.getSavedItems()}).then(() => {
          this.setSpinnerState(false)
        })
    }, 100)
  },
  mixins: [inputNumberMixin],  
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    hasMinimumAmount() {
      if (this.product.product.name == "domino") {
        return this.$store.getters.getTotalAmount('domino') >= 24
      } else if (this.product.product.name == 'blue') {
        return this.$store.getters.getTotalAmount('blue') >= 20
      } else if (this.product.product.name == 'bar') {
        return this.$store.getters.getTotalAmount('bar') >= 50
      } else if (this.product.product.name == 'marianne') {
        return this.$store.getters.getTotalAmount('marianne') >= 50
      } else if (this.product.product.name == 'custom-candy-box') {
        return this.$store.getters.getTotalAmount('custom-candy-box') >= 50
      } else if (this.product.product.name == 'calendar') {
        return this.$store.getters.getTotalAmount('calendar') >= 1
      } else if (this.product.product.name == 'calendar-b2b') {
        return this.$store.getters.getTotalAmount('calendar-b2b') >= 1
      }
    },
    calendarOrderAmount() {
      let total = 0
      let self = this
      this.$store.getters.getOrderData.products.forEach(function(box) {
        if (box.product.name == 'calendar' && box.draft == false && box.id != self.product.id) {
          total += box.amount
        }
      })
      return total
    }
  },
  watch: {
    productAmount: function(newVal, oldVal) {
      if (newVal < 0) {
        this.productAmount = 0
      }
      else if (!this.getCalendarMode()) {
        if (newVal > 999999) {
          this.productAmount = 999999
        }
      } else {
        if (this.calendarOrderAmount + newVal > 20) {
          this.productAmount = 20 - this.calendarOrderAmount
        } else {
          this.productAmount = newVal
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.amount {
  display: inline-block;
  min-width: 3em;
  background-color: #dedbd9;
  border-radius: 3px;
  box-shadow: 1px 1px 0px #00000040;
  font-family: 'Open Sans Bold';
}

.icon {
  position: relative;
  top: 3px;
}

.icon.minimum-required {
  position: relative;
}

.icon:hover {
  cursor: pointer;
  color: #f4acbc;
}

.red {
  color: red !important;
}

.bubble {
  position: absolute;
  padding: 5px 10px 5px 10px;
  background: #FFFFFF;
  border: 1px solid red;
  font-size: 15px;
  color: red;
  top: -25px;
  right: 60px;
  @media only screen and (min-width: 1704px) {
    right: 63px;
  }
  @media screen and (max-width: 1215px) and (min-width: 768px) {
    right: 44px;
    font-size: 12px;
  }
}

.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -15px;
  left: 55px;
  @media screen and (max-width: 1215px) {
    left: 37px;
  }
}

.bubble:before
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: red transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -16px;
  left: 55px;
  @media screen and (max-width: 1215px) {
    left: 37px;
  }
}

.bubble-mobile {
  font-size: 14px;
  color: red;
  margin-bottom: 20px;
  position: absolute;
  z-index: 20;
  top: -21px;
  line-height: 12px;
  font-weight: bold;
  left: 8px;
}

.amount-input {
  width: 80px;
  border: 2px solid #dedbd9;
}


/* Remove arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
