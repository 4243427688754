<template>
  <div>
    <canvas id="front" ref="frontCanvas" width="3771" height="6354" class="is-hidden"></canvas>
    <canvas id="back" ref="backCanvas" width="3771" height="6354" class="is-hidden"></canvas>
  </div>
</template>

<script>


export default {
  name: "marianneCanvas",

  props: {
    canvasTitle: String, 
    canvasText: String,
    canvasSignature: String,
  },

  mounted() {
    this.frontCtx = this.$refs.frontCanvas.getContext("2d")
    this.backCtx = this.$refs.backCanvas.getContext("2d")
  },

  methods: {
    draw() {
      return Promise.all(
        [this.drawFrontCanvas(), this.drawBackCanvas()]
      )
    },

    async loadImage(src, canvas) {
      return new Promise((resolve, _) => {
        const image = new Image()
        image.crossorigin = "anonymous"
        image.onload = () => {
          canvas.drawImage(image, 0, 0, 3771, 6354)
          resolve()
        }
        image.src = src
      })
    },

    async drawBackCanvas() {
      const ctx = this.backCtx
      const text = this.canvasText
      const signature = this.canvasSignature
      return this.loadImage(process.env.VUE_APP_PM_FRONTEND_URL +'/imgs/back-canvas-image.png', ctx).then(() => {
        ctx.textAlign = "center"
        const lineHeight = 90
        const x = this.$refs.backCanvas.width / 2
        const y = 450;
        ctx.font = "87px 'Felt That'"
        ctx.textAlign = "center"

        // Split the text into lines with a maximum of 50 characters
        const lines = text.match(/.{1,50}(\s|$)/g) || []

        // Draw each line
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].trim()
          ctx.fillText(line, x, y + i * lineHeight)
        }
        // Draw the signature
        ctx.fillText(signature, this.$refs.frontCanvas.width / 2, 1290)
      })
    },

    async drawFrontCanvas() {
      const ctx = this.frontCtx
      const text = this.canvasTitle
      ctx.font = "135px 'Felt That'"
      return this.loadImage(process.env.VUE_APP_PM_FRONTEND_URL + '/imgs/front-canvas-image.png', ctx).then(() => {
        ctx.textAlign = "center"
        ctx.fillText(text, 1890, 930)
        ctx.fillText(text, 1890, 5010)
      })
    },
  },
}
</script>

  <style>
    
  </style>
  