<template>
  <div class="popover-content" :class="{ 'is-calendar': getCalendarMode() }">
    <template v-if="getCalendarMode()">
      <div class="columns is-mobile mt-5 mb-0">
        <div class="column is-7 mt-0 pt-0 mb-0 pb-0 is-flex">
          <figure class="image is-96x96 is-hidden-mobile">
            <img :src="$store.getters.getProductThumbnail('calendar')" />
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('calendar')" />
          </figure>
          <p class="is-hidden-mobile">Fazer suklaajoulukalenteri omalla kuvalla</p>
        </div>
        <div class="column mobile-column mt-0 pt-0 mb-0 pb-0 is-5 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('calendar')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>      
    </template>
    <template v-else>
      <div class="columns is-mobile mt-5 mb-0">
        <div class="column is-7 mt-0 pt-0 mb-0 pb-0 is-flex">
          <figure class="image is-96x96 is-hidden-mobile">
            <img :src="$store.getters.getProductThumbnail('blue')" />
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('blue')" />
          </figure>
          <p class="is-hidden-mobile">Konvehtirasia</p>
        </div>
        <div class="column mobile-column mt-0 pt-0 mb-0 pb-0 is-5 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('fazer')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile mt-0 mb-0">
        <div class="column is-7 mb-0 pb-0">
          <figure class="image is-96x96 is-hidden-mobile">
            <img :src="$store.getters.getProductThumbnail('domino')" />
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('domino')" />
          </figure>
          <p class="is-hidden-mobile">Domino</p>
        </div>
        <div class="column mobile-column is-5 mb-0 pb-0 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('domino')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile mt-0 mb-0">
        <div class="column is-7 mt-0 pt-0">
          <figure class="image is-96x96 is-hidden-mobile">
            <img :src="$store.getters.getProductThumbnail('custom-candy-box')" />
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('custom-candy-box')" />
          </figure>
          <p class="is-hidden-mobile">Custom Candy Box</p>
        </div>
        <div class="column mobile-column mt-0 pt-0 is-5 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('custom-candy-box')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile mt-0 mb-0">
        <div class="column is-7 mt-0 pt-0">
          <figure class="image is-96x96 is-hidden-mobile">
            <img :src="$store.getters.getProductThumbnail('marianne')" />
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('marianne')" />
          </figure>
          <p class="is-hidden-mobile">Marianne</p>
        </div>
        <div class="column mobile-column mt-0 pt-0 is-5 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('marianne')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile mt-0">
        <div class="column is-7 mt-0 pt-0 is-flex">
          <figure class="image is-96x96 is-hidden-mobile">
            <img :src="$store.getters.getProductThumbnail('bar')" />
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('bar')" />
          </figure>
          <p class="is-hidden-mobile">Suklaalevy</p>
        </div>
        <div class="column mobile-column mt-0 pt-0 is-5 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('fazer-bar')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile mt-0">
        <div class="column is-7 mt-0 pt-0 is-flex">
          <figure class="image is-hidden-mobile" style="padding-left: 20px; padding-right: 17px;">
            <img :src="$store.getters.getProductThumbnail('calendar-b2b')" style="width: 59px; height: 96px; max-height: 96px;"/>
          </figure>
          <figure class="image is-hidden-tablet mb-2">
            <img :src="$store.getters.getProductThumbnail('calendar-b2b')" />
          </figure>
          <p class="is-hidden-mobile">Fazer Oma Kalenteri</p>
        </div>
        <div class="column mobile-column mt-0 pt-0 is-5 has-text-right">
          <button class="upload-button button is-primary" @click="goToEditor('calendar-b2b')">
            <span>Lisää tilaukseen</span>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { setAxiosAuthToken } from '@/utils'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'cartModal',
  methods: {
    ...mapMutations(['setSpinnerState', 'setCartSpinnerState', 'setNotificationActiveState']),
    ...mapActions(['login', 'updateOrder', 'postFazerBar', 'postMarianneBox', 'postCustomCandyBox', 'postFazerBox']),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getSavedItems: 'getSavedItems',
      getCalendarMode: 'getCalendarMode',
    }),
    goToEditor(item) {
      let self = this
      self.setSpinnerState(true)
      this.login({})
        .then(() => {
          let token = self.$store.state.postLoginData.token
          setAxiosAuthToken(token)
        })
        .then(() => {
          // if order is not null then update first the current basket
          if (this.$store.state.order != null && this.$store.state.order.id != null) {
            this.updateOrder({ products: this.products })
              .then(() => {
                self.setSpinnerState(false)
                if (item == 'domino' || item == 'calendar' || item == 'calendar-b2b') {
                  self.$router.push({ name: 'upload', params: { product: item } })
                } else {
                  let data = {
                    product: this.$store.getters.getProductId(this.routerParamToProductId(item)),
                    type: 'ALL_TO_SAME_ADDRESS', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
                    csv_status: 'NO_CSV_NEEDED', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
                    draft: true,
                  }
                  if (this.$store.state.order != null) {
                    let order_id = this.$store.state.order.id
                    if (order_id != undefined) {
                      data['order_id'] = order_id
                    }
                  }
                  this.getPostFunction(item)(data).then(() => {
                    if (item == 'fazer') {
                      self.$router.push({ name: 'editor', params: { uuid: self.getOrderData().last_product } })
                    } else {
                      self.$router.push({ name: 'editor-tab', params: { uuid: self.getOrderData().last_product } })
                    }
                  })
                }
              })
              .catch(function (error) {
                if (!error.response) {
                  // no response from server.. maybe network down?
                  self.setSpinnerState(false)
                  self.errors = Object.assign({}, initial_errors, { non_field_errors: [error.message] }) // combine initial and response
                } else {
                  self.setSpinnerState(false)
                  self.errors = Object.assign({}, initial_errors, error.response.data) // combine initial and response
                }
              })
          } else {
            if (item == 'domino' || item == 'calendar' || item == 'calendar-b2b') {
              self.setSpinnerState(false)
              self.$router.push({ name: 'upload', params: { product: item } })
            } else {
              let data = {
                product: this.$store.getters.getProductId(this.routerParamToProductId(item)),
                type: 'ALL_TO_SAME_ADDRESS', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
                csv_status: 'NO_CSV_NEEDED', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
                draft: true,
              }
              if (this.$store.state.order != null) {
                let order_id = this.$store.state.order.id
                if (order_id != undefined) {
                  data['order_id'] = order_id
                }
              }
              this.getPostFunction(item)(data).then(() => {
                self.setSpinnerState(false)
                if (item == 'fazer') {
                  self.$router.push({ name: 'editor', params: { uuid: self.getOrderData().last_product } })
                } else {
                  self.$router.push({ name: 'editor-tab', params: { uuid: self.getOrderData().last_product } })
                }
              })
            }
          }
        })
    },
    getPostFunction(item) {
      const products = {
        'fazer-bar': this.postFazerBar,
        marianne: this.postMarianneBox,
        'custom-candy-box': this.postCustomCandyBox,
        fazer: this.postFazerBox,
      }
      return products[item]
    },
    routerParamToProductId(param) {
      if (param == 'fazer-bar') {
        return 'bar'
      } else if (param == 'marianne') {
        return 'marianne'
      } else if (param == 'custom-candy-box') {
        return 'custom-candy-box'
      } else if (param == 'fazer') {
        return 'blue'
      }
    },
  },
  computed: {
    products() {
      if (this.getSavedItems()) return this.getSavedItems()
      else return []
    },
  },
}
</script>

<style lang="scss" scoped>

.is-calendar {
  .popover-content {
    top: 65px;
    p {
      margin-top: 0;
    }
  }
}


.is-layout-fixed {
  position: fixed !important;
}

.mobile-modal {
  .buttons {
    right: 0;
    top: 0;
    position: absolute;
  }
}

.popover-content {
  position: absolute;
  background-color: white;
  width: 525px;
  right: -1px;
  padding: 20px 20px 20px 20px;
  border: 1px solid black;
  top: 86px;
  @media (max-width: 950px) {
    width: 100%;
    right: 0;
  }
  p {
    font-size: 19px;
    margin-left: 20px;
    float: left;
    margin-top: 15px;
  }
  figure {
    float: left;
  }
  button {
    width: 174px;
    @media (max-width: 768px) {
      margin-top: 25px;
    }
  }
}
@media (max-width: 768px) {
  button.upload-button {
    margin-top: 50px;
  }
  .mobile-column {
    padding-left: 0px;
  }
}

.is-96x96 img {
  max-height: 96px !important;
}
</style>
