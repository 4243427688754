<template>

  <div class="payment">
    <SectionHeader></SectionHeader>
    <div class="section pt-0">
      <div class="container has-background-white">
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section pb-0">
              <h1>Tilauksen vahvistaminen</h1>
            </div>
          </div>
        </div>
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section pt-0">
              <h3 class="title is-size-4">Tilauksen sisältö</h3>
              <product-list :is-editable="false" :is-discount-editable="false" />
            </div>
          </div>
        </div>
        <div class="columns mt-0">
          <div class="column is-4">
            <div class="section">
              <button @click="goBackToShipment" class="button is-primary is-fullwidth">
                <i class="fas fa-arrow-left mr-5"></i>
                Takaisin
              </button>
            </div>
          </div>
        </div>
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section">
              <div class="columns">
                <div class="column is-two-fifths-fifths mb-4">
                  <p class="title is-size-4" v-if="!getCalendarMode">Asiakastiedot / Toimitusosoite mikäli vain yksi toimitusosoite</p>
                  <p class="title is-size-4" v-else>Asiakas- ja toimitustiedot</p>
                  <p>Toimitusaikatoive: {{getReadableDate}}</p>
                  <p>Lähetyksen vastaanottajan nimi: {{ customer.recv_name }} {{ customer.recv_last_name }}</p>
                  <p v-if="customer.recv_company!=''">Yrityksen/Yhdistyksen nimi: {{ customer.recv_company }}</p>
                  <p>Vastaanottajan puhelinnumero: {{ customer.recv_phone }}</p>
                  <p>Sähköpostiosoite: {{ customer.cust_email }}</p>
                  <p>Katuosoite: {{ customer.recv_street }}</p>
                  <p>Postinumero: {{customer.recv_zip}}</p>
                  <p>Postitoimipaikka: {{customer.recv_town}}</p>
                  <p v-if="customer.company_reference!=''">Tilaajan oma viite laskulle: {{customer.company_reference}}</p>
                  <p v-if="customer.cust_vat_nr!=''">Y-tunnus: {{customer.cust_vat_nr}}</p>
                  <p v-if="customer.company_e_invoice_address!=''">Verkkolaskutusosoite: {{customer.company_e_invoice_address}}</p>
                  <p v-if="customer.company_operator_name!=''">Välittäjätunnus ja -nimi: {{customer.company_operator_name}}</p>

                  <div v-if="$store.state.billing">
                    <p class="title is-size-4 mt-5">Laskutusosoite</p>
                    <p v-if="customer.bill_name!='' || customer.bill_last_name!=''">Laskun vastaanottajan nimi: {{ customer.bill_name }} {{ customer.bill_last_name }}</p>
                    <p v-if="customer.bill_company!=''">Yrityksen/Yhdistyksen nimi: {{ customer.bill_company }}</p>
                    <p v-if="customer.bill_phone!=''">Puhelinnumero: {{ customer.bill_phone }}</p>
                    <p v-if="customer.bill_street!=''">Katuosoite: {{ customer.bill_street }}</p>
                    <p v-if="customer.bill_zip!=''">Postinumero: {{ customer.bill_zip }}</p>
                    <p v-if="customer.bill_town!=''">Postitoimipaikka: {{ customer.bill_town }}</p>
                    <p v-if="customer.bill_vat_nr!=''">Y-tunnus: {{customer.bill_vat_nr}}</p>
                    <p v-if="customer.bill_company_e_invoice_address!=''">Verkkolaskutusosoite: {{customer.bill_company_e_invoice_address}}</p>
                    <p v-if="customer.bill_company_operator_name!=''">Välittäjätunnus ja -nimi: {{customer.bill_company_operator_name}}</p>
                  </div>

                  <p v-if="customer.additional_details!=''">Lisätietoja tilaukselle: {{customer.additional_details}}</p>

                  <p v-if="getVoucherDiscount != null">Kampanjakoodi {{ getVoucherDiscount.code }}</p>
                </div>
                <div class="column is-one-fifths is-offset-two-fifths is-relative">
                  <p></p>
                  <div class="buttons mt-6 bottom-aligned">
                    <p v-if="!hasInternationalDeliveries" class="place-at-bottom mb-2">
                      Maksaessasi verkossa, sinut ohjataan Paytrail -maksusivulle. Maksutapana käy luottokortit ja
                      yleisimmät verkkomaksutavat. Maksun jälkeen sinut ohjataan takaisin palveluun.
                    </p>
                    <button v-if="!hasInternationalDeliveries" class="button is-primary is-fullwidth" @click="payCreditCard">
                      Maksa verkossa
                      <i class="fas fa-arrow-right ml-5"></i>
                    </button>
                    <span class="help is-danger mb-3" v-if="companyNumber == '' && billing">
                      Yrityksen y-tunnus on pakollinen, kun maksat laskulla
                    </span>
                    <div
                      class="field company-number-input"
                      v-show="billing && $store.getters.getContactData.cust_vat_nr == ''"
                    >
                      <div class="control">
                        <input v-model="cust_vat_nr" class="input" type="text" placeholder="Y-tunnus" />
                      </div>
                    </div>
                    <button
                      class="button is-primary is-fullwidth"
                      v-if="isCompany"
                      @click="payBill"
                      :disabled="companyNumber == '' && billing"
                    >
                      Maksa laskulla
                      <i class="fas fa-arrow-right ml-5"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import productList from '@/components/shipment/productList'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Footer from '@/components/Footer'
import {useMeta} from "vue-meta"

export default {
  name: 'PaymentPage',
  components: { SectionHeader, productList, Footer },
  data() {
    return {
      billing: false,
      cust_vat_nr: ''
    }
  },
  computed: {
    customer() {
      return this.$store.getters.getContactData
    },
    products() {
      return this.getSavedItems()
    },
    getVoucherDiscount() {
      return this.$store.getters.getVoucherDiscount
    },
    companyNumber() {
      if (this.customer.cust_vat_nr == '') {
        if (this.cust_vat_nr == '') {
          return ''
        } else {
          return this.cust_vat_nr
        }
      } else {
        return this.customer.cust_vat_nr
      }
    },
    isCompany() {
      return this.$store.getters.getIsCompany
    },
    getReadableDate() {
      if (this.customer.expected_delivery_date != '') {
        let [year, month, day] = this.customer.expected_delivery_date.split('-')
        let date = new Date(year, month - 1, day);
        year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
        month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date)
        day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
        return `${day}.${month}.${year}`
      } else {
        return ''
      }
    },
    hasInternationalDeliveries() {
      if (this.getOrderData().delivery_type == 'MIXED') {
        for (let i = 0; i < this.products.length; i++) {
          if (!this.products[i].draft) {
            if ((this.products[i].address_type == 'INTERNATIONAL_FREIGHT' || this.products[i].address_type == 'INTERNATIONAL_MAILBOX')) {
              return true
            }
          }
        }
      }
      return false
    }
  },
  mounted() {
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    }
    this.getProducts()
    this.listDiscountVolumes()
    this.listShippingPrices()
    this.listFreightPrices()
    this.setSpinnerState(false)
    this.setCartSpinnerState(false)
  },
  methods: {
    ...mapActions({
      partialUpdateOrder: 'partialUpdateOrder',
      createPaytrailSession: 'createPaytrailSession',
      listDiscountVolumes: 'listDiscountVolumes',
      listShippingPrices: 'listShippingPrices',
      listFreightPrices: 'listFreightPrices',
      getProducts: 'getProducts'
    }),
    ...mapGetters({
      getSavedItems: 'getSavedItems',
      getOrderData: 'getOrderData',
      getCalendarMode: 'getCalendarMode'
    }),
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState'
    ]),
    goBackToShipment() {
      this.$router.push({ name: 'shipment', params: { uuid: this.$store.getters.getOrderData.id } })
    },
    payCreditCard() {
      this.setSpinnerState(true)
      this.createPaytrailSession()
        .then(() => {
          let checkout = this.$store.state.paytrailSessionData['response']
          window.location.replace(checkout)
        })
        .catch(function (error) {
          console.error('Error:', error)
        })
    },
    payBill() {
      if (this.companyNumber == '') {
        this.billing = true
        return
      }

      this.setSpinnerState(true)
      this.billing = true

      let data = {
        status: 'ORDERED',
      }

      if (this.companyNumber != '') {
        let contactData = this.customer

        if (contactData['cust_vat_nr'] == '') {
          contactData['cust_vat_nr'] = this.cust_vat_nr
          this.setSpinnerState(true)
          this.setCartSpinnerState(true)
          this.$store
            .dispatch('updateContact', contactData)
            .then(() => {
              return this.partialUpdateOrder(data)
            })
            .then(() => {
              this.setSpinnerState(false)
              this.$router.push({ name: 'thanks' })
            })
        } else {
          this.setSpinnerState(true)
          this.setCartSpinnerState(true)
          this.partialUpdateOrder(data).then(() => this.$router.push({ name: 'thanks' }))
        }
      }
        },
      },
}
</script>

<style scoped lang="scss">
.company-number-input {
  width: 100%;
}
.section {
  background-color: white;
}
.button {
  text-align: center;
  display: block;
}
.bottom-aligned {
   @media (min-width: 768px) {
     position: absolute;
   }
  bottom: 0;
  width: 100%;
}
.place-at-bottom {
  margin-top: auto;
  font-family: gill-sans-nova;
  font-size: 18px;
  text-align: right;
}
</style>
