<template>
  <div class="is-relative" style="height:100px">
    <!-- full resolution for use for print -->
    <canvas id="textbox" ref="textBoxCanvas" width="1877" height="1062" class="nis-hidden"></canvas>
    <canvas id="titlebox" ref="titleBoxCanvas" width="1169" height="1355" class="nis-hidden"></canvas>
    <canvas id="front" ref="frontCanvas" width="943" height="1589" class="nis-hidden"></canvas>
    <canvas id="back" ref="backCanvas" width="943" height="1589" class="nis-hidden"></canvas>
  </div>
</template>

<script>
export default {
  name: "CustomCandyBoxCanvas",
  props: {
    canvasTitle: String,
    canvasText: String,
    canvasSignature: String,
    bgColor: String,
    fontStyle: {type: Object},
    productlogoUrl: String,
  },

  mounted() {
    this.frontCtx = this.$refs.frontCanvas.getContext("2d")
    this.backCtx = this.$refs.backCanvas.getContext("2d")
  },

  methods: {
    draw() {
      return Promise.all([this.drawFrontCanvas(), this.drawBackCanvas()])
    },

    async loadImage(src, canvas, left=0, top=0, width=943, height=1589) {
      return new Promise((resolve, _) => {
        const image = new Image()
        image.crossOrigin = "anonymous"
        image.onload = () => {
          canvas.drawImage(image, left, top, width, height)
          resolve()
        }
        image.src = src
      })
    },

    fillCanvasBackgroundWithColor(canvas) {
      // Get the 2D drawing context from the provided canvas.
      const context = canvas.getContext('2d')
      context.save()

      // Fill in the background. We do this by drawing a rectangle
      // filling the entire canvas, using the provided color.
      context.fillStyle = this.bgColor
      context.fillRect(0, 0, canvas.width, canvas.height)

      // Restore the original context state from `context.save()`
      context.restore()
    },
    copyAndMirrorCanvas(sourceCanvas, targetCanvas, destX, destY, scale) {
      const ctx = targetCanvas.getContext('2d');
      ctx.save();

      // Translate to the destination point, then apply the vertical flip
      ctx.translate(destX, destY + (sourceCanvas.height*scale));  // Adjust Y translation to consider the top-left corner
      ctx.scale(1, -1);

      // Draw the source canvas onto the target canvas without adjustments since we've already translated to the correct point
      ctx.drawImage(sourceCanvas, 0, 0, sourceCanvas.width, sourceCanvas.height, 0, 0, sourceCanvas.width*scale, sourceCanvas.height*scale);

      // Restore the canvas state to its original form
      ctx.restore();
    },

    copyCanvas(sourceCanvas, targetCanvas, destX, destY, scale) {
      const ctx = targetCanvas.getContext('2d');
      ctx.save();

      // Translate to the destination point, then apply the vertical flip
      ctx.translate(destX, destY);  // Adjust Y translation to consider the top-left corner

      // Draw the source canvas onto the target canvas without adjustments since we've already translated to the correct point
      ctx.drawImage(sourceCanvas, 0, 0, sourceCanvas.width, sourceCanvas.height, 0, 0, sourceCanvas.width*scale, sourceCanvas.height*scale);

      // Restore the canvas state to its original form
      ctx.restore();
    },

    drawTextCanvas() {
      // this function creates small box that is filled normal orientation and copied upside down to actual image
      // output of this function is observable in canvas#textbox
      const text = this.canvasText
      const signature = this.canvasSignature

      const canvas = this.$refs.textBoxCanvas
      const ctx = canvas.getContext('2d')

      // fill canwas with white bg
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      ctx.fillStyle = '#000000'
      ctx.textAlign = "center"
      const lineHeight = 100

      const x = 936
      const y = 186

      ctx.font = "65px " + this.fontStyle['font-family']
      ctx.textAlign = "center"

      // Split the text into lines with a maximum of 50 characters
      const lines = text.match(/.{1,50}(\s|$)/g) || []

      // Draw each line
      let i = 0;
      for (i = 0; i < lines.length; i++) {
        const line = lines[i].trim()
        ctx.fillText(line, x, y + i * lineHeight)
      }

      // Draw the signature
      ctx.font = "75px " + this.fontStyle['font-family']
      ctx.fillText(signature, x, 860)

      return canvas
    },

    async drawTitleCanvas() {
      // this function creates small box that is filled with logo and title text
      // output of this function is observable in canvas#titlebox
      const text = this.canvasTitle
      const canvas = this.$refs.titleBoxCanvas
      const ctx = canvas.getContext('2d')

      // fill canvas with white bg
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = '#000000'

      if (this.productlogoUrl) {
        await this.loadImage(this.productlogoUrl, ctx, 119, 144, 930, 500)
      }

      ctx.textAlign = "center"
      ctx.font = "100px " + this.fontStyle['font-family']
      ctx.fillText(text, 585, 940)
      return canvas
    },
    async drawBackCanvas() {
      const ctx = this.backCtx
      const text = this.canvasText
      const signature = this.canvasSignature
      this.fillCanvasBackgroundWithColor(this.$refs.backCanvas)

      return this.loadImage(process.env.VUE_APP_PM_FRONTEND_URL + '/imgs/candybox_inside.png', ctx)
          .then(() => {
            this.copyAndMirrorCanvas(this.drawTextCanvas(), this.$refs.backCanvas, 944/4, 358/4, 0.25)
          })
    },

    async drawFrontCanvas() {
      const ctx = this.frontCtx
      const text = this.canvasTitle
      const titleCanvas = await this.drawTitleCanvas()
      this.fillCanvasBackgroundWithColor(this.$refs.frontCanvas)
      return this.loadImage(process.env.VUE_APP_PM_FRONTEND_URL + '/imgs/candybox_outside.png', ctx)
          .then(() => {
            this.copyCanvas(titleCanvas, this.$refs.frontCanvas, 1294/4,60/4, 0.25)
            this.copyCanvas(titleCanvas, this.$refs.frontCanvas, 1294/4,4082/4, 0.25)
          })
    },
  },
}
</script>
<style>
</style>
