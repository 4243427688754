<template>
  <div class="shipment">
    <SectionHeader></SectionHeader>
    <div class="section pt-0">
      <div class="container has-background-white">
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section pb-0">
              <h1>Kassa ja toimitustiedot</h1>
            </div>
          </div>
        </div>
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section pt-0">
              <h3 class="title is-size-4">Tilauksen sisältö</h3>
              <product-list :is-editable="false" ref="productData"/>
            </div>
          </div>
        </div>
        <div class="columns mt-0">
          <div class="column is-4">
            <div class="section">
                <button @click="goBackToCartPage" class="button is-primary is-wide is-hidden-mobile">
                  <i class="fas fa-arrow-left mr-5"></i>
                  Takaisin
                </button>
              <button @click="goBackToCartPage" class="button is-primary is-fullwidth is-hidden-tablet">
                  <i class="fas fa-arrow-left mr-5"></i>
                  Takaisin
              </button>
            </div>
          </div>
        </div>
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section">
              <div class="columns">
                <div class="column is-8">
                  <discount-code class="mb-6"></discount-code>
                  <shipment-address ref="shipmentAddress"></shipment-address>
                  <div class="control mt-5">
                    <div v-bind:class="{ 'is-danger-border': !accept_terms }">
                      <label class="checkbox-container mb-5">
                        <span class="checkbox-text" v-html="getTermsPolicy"></span>
                        <input type="checkbox" v-model="accept_terms">
                        <span class="mark"></span>
                      </label>
                    </div>
                    <p v-if="!accept_terms" class="is-danger">Hyväksy ehdot ennen jatkamista</p>
                    <div v-bind:class="{ 'is-danger-border': !accept_rules }">
                      <label class="checkbox-container"> Hyväksyn Custom Fazer -palvelun <router-link target="_blank" :to="{ name: 'rules'}">sopimus- ja toimitusehdot</router-link>.
                        <input type="checkbox" v-model="accept_rules">
                        <span class="mark"></span>
                      </label>
                    </div>
                    <p v-if="!accept_rules" class="is-danger">Hyväksy ehdot ennen jatkamista</p>
                  </div>
                </div>
                <div class="column is-4 bottom-aligned right-button-column">
                  <p class="place-at-bottom mb-3">
                    Ennen vahvistusta voit muokata keskeneräistä
                    tilausta kahden viikon ajan. Kopioi linkki
                    talteen edelliseltä sivulta.
                  </p>
                  <button class="button is-primary is-wide is-hidden-mobile" @click="submitForm"
                          :disabled="(this.$store.getters.getDominosTotalAmount != 0 && this.$store.getters.getDominosTotalAmount < 24)
                           || (this.$store.getters.getFazersTotalAmount != 0 && this.$store.getters.getFazersTotalAmount < 20)
                           || !accept_rules || !accept_terms"
                  >
                    Jatka vahvistukseen
                    <i class="fas fa-arrow-right ml-5"></i>
                  </button>
                  <button class="button is-primary is-fullwidth is-hidden-tablet is-block has-text-right" @click="submitForm"
                          :disabled="(this.$store.getters.getDominosTotalAmount != 0 && this.$store.getters.getDominosTotalAmount < 24)
                           || (this.$store.getters.getFazersTotalAmount != 0 && this.$store.getters.getFazersTotalAmount < 20)
                           || !accept_rules || !accept_terms"
                  >
                    Jatka vahvistukseen
                    <i class="fas fa-arrow-right ml-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import productList from '@/components/shipment/productList'
import SectionHeader from '@/components/SectionHeader'
import shipmentAddress from "@/components/shipment/shipmentAddress"
import discountCode from "@/components/shipment/discountCode"
import Footer from '@/components/Footer'
import {useMeta} from "vue-meta"

import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'OrderDetails',
  data(){
    return {
      triggerSubmit: false,
      accept_terms: false,
      accept_rules: false,
      total: 0
    }
  },
  emits: ['submit-form'],
  components: {
    SectionHeader,
    productList,
    'shipment-address': shipmentAddress,
    discountCode,
    Footer
  },
  computed: {
    products() {
      return this.getOrderData().products
    },
    getTermsPolicy() {
      return this.htmlDecode(this.$store.getters.getTermsPolicy)
    }
  },
  methods:{
    ...mapMutations([
      'setSpinnerState'
    ]),
    ...mapActions([
      'getProducts'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getCalendarMode: 'getCalendarMode'
    }),
    goBackToCartPage() {
      this.$refs.shipmentAddress.saveTemporalContact()
      this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
    },
    submitForm() {
      if (this.accept_terms && this.accept_rules) {
        this.$refs.shipmentAddress.submitForm()
      }
    },
    htmlDecode(input){
      var e = document.createElement('textarea');
      e.innerHTML = input;
      // handle case of empty input
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
  },
  mounted() {
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    }
    this.setSpinnerState(false)
    this.getProducts()
    this.$watch(() => {
      this.total = this.$refs.productData.total_pcs
      return this.$refs.productData.total_pcs
    }, (value) => this.total = value)
  },
}
</script>

<style scoped lang="scss">

@import '../assets/sass/mybulma';

#bulk-discount-box {
  border: 2px solid #f2f1f0;
  .bulk-discount-title {
    font-family: 'Open Sans Bold';
  }
  .grey-background {
    background-color: #F2F1F0;
  }
  p {
    display: flow-root;
  }
  .bulk-discount-row {
    font-size: 0.9em;
  }
}

.bottom-aligned {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .button {
    //max-width: 350px;
    //margin-left: auto;
  }
}

.place-at-bottom {
  margin-top: auto;
  font-family: gill-sans-nova;
  font-size: 18px;
  text-align: right;
}

.is-danger {
  color: red;
}

.is-danger-border {
  border: 1px solid red;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

section {
  background-color: white;
}

.control {
  font-family: gill-sans-nova;
  label {
    font-family: gill-sans-nova;
  }
  p {
    font-family: gill-sans-nova;
    font-size: 15px !important;
  }
}

// checkbox
.checkbox-container {
  display: table;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 16px;
  font-family: gill-sans-nova;
}

/* Hide the default checkbox */
.checkbox-container input {
  //visibility: hidden;   //takes visual space, display none keeps it accesible and doesn't take space
  display: none;
  cursor: pointer;
}


.container input:checked ~ .mark {
  background-color: $primary;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 1px solid $primary;
}

.checkbox-container:hover input ~ .mark {
  background-color: gray;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.checkbox-container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.checkbox-container .mark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.button {
  text-align: center;
  display: block;
}

.right-button-column {
  @media only screen and (min-width: 1701px) {
    width: 25% !important;
    margin-left: 8.33333337% !important;
  }
}

</style>
