<template>
  <div class="ordertype">
    <div class="hero is-fullheight">
      <SectionHeader></SectionHeader>
      <div class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-full is-8-desktop is-offset-2-desktop is-12-mobile is-12-tablet">
              <div class="ml-2 mr-2">
                <h1 class="has-text-centered">Ota yhteyttä</h1>
                <p class="has-text-centered mt-6" v-if="!getCalendarMode()">
                  Ole hyvä ja täytä yhteydenottolomakkeeseen tarvittavat tiedot.
                  Voit olla yhteydessä myös sähköpostitse yrityspalvelu@fazer.com.
                  Otamme sinuun yhteyttä mahdollisimman pian.
                </p>
                <p class="has-text-centered mt-6" v-else>
                  Ole hyvä ja täytä yhteydenottolomakkeeseen tarvittavat tiedot. Otamme sinuun yhteyttä mahdollisimman pian.
                </p>
                <div class="columns mt-5">
                  <div class="column is-8 is-offset-2 has-text-left grey-border">
                    <delivery-order-form ref="deliveryOrderForm"></delivery-order-form>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <p>
                      * Pakollinen tieto
                    </p>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <div class="control mt-5">
                      <div v-bind:class="{ 'is-danger-border': !accept }">
                        <label class="checkbox-container mt-2 is-inline-block">
                          <input type="checkbox" style="display: none;" v-model="accept">
                          <div class="is-inline">
                            Olen tietoinen, että Fazer tallentaa ja käsittelee henkilötietojani tietosuojaselosteessa kuvatulla tavalla
                          </div>
                          <span class="mark"></span>
                        </label>
                      </div>
                      <p v-if="!accept" class="is-danger">Hyväksy ehdot ennen jatkamista</p>
                    </div>
                  </div>
                </div>
                <div class="columns mb-5">
                  <div class="column is-2 is-offset-5">
                    <button
                      class="upload-button button is-primary"
                      :disabled="!accept"
                      @click="sendForm()"
                    >
                      <span>Lähetä</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import deliveryOrderForm from "../components/shipment/deliveryOrderForm"
import {mapGetters} from 'vuex'
import {useMeta} from "vue-meta"

export default {
  name: 'OrderDeliveryFormPage',
  components: {
    SectionHeader,
    Footer,
    deliveryOrderForm
  },
  data() {
    return {
      product: null,
      accept: false
    }
  },
  mounted() {
    if (!this.getCalendarMode()) {
      useMeta({
        title: 'Ota yhteyttä – Custom Fazer',
        description: 'Ota yhteyttä asiakaspalveluumme. Custom Fazer -palvelu tarjoaa personoituja tuotteita liike- ja asiakaslahjaksi ja henkilöstön muistamiseen.'
      })
    } else {
      useMeta({
        title: 'Ota yhteyttä – Fazer Oma kalenteri',
        description:
          'Ota yhteyttä asiakaspalveluumme. Fazer Oma kalenteri -palvelussa voit luoda oman suklaajoulukalenterin.',
      })
    }

    window.scrollTo(0, 0)
    if (this.getOrderData() == null) {
      this.$router.push('/')
    }
    else {
      this.product = this.$store.getters.getProductById(this.$route.params.uuid)
      if (this.product == null) {
        this.product = this.getOrderData().products[0]
      }
    }
  },
  methods: {
    ...mapGetters({
      getOrderData: 'getOrderData',
      getCalendarMode: 'getCalendarMode'
    }),
    sendForm() {
      if (this.accept) {
        this.$refs.deliveryOrderForm.submitForm()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

.hero-body {
  background-color: #EBEFF5;
  .container {
    background-color: #EBEFF5;
  }
}

@media screen and (min-width: 769px) {
  .margin-l-desktop-2 {
    margin-left: 2%;
  }
}

.grey-border {
  border: 1px solid #ebeff5;
}

// checkbox
.checkbox-container {
  display: inline;
  position: relative;
  padding-left: 24px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: gill-sans-nova;
}

/* Hide the default checkbox */
.checkbox-container input {
  visibility: hidden;
  cursor: pointer;
}


.container input:checked ~ .mark {
  background-color: $primary;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 1px solid $primary;
}

.checkbox-container:hover input ~ .mark {
  background-color: gray;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.checkbox-container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.checkbox-container .mark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.is-danger {
  color: red;
}

.is-danger-border {
  border: 1px solid red;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}
</style>
