<template>
  <div class="shipmentForm">

    <div class="field">
      <div class="control">
        <input
            v-model=contact.first_name
            class="input"
            type="text"
            placeholder="Etunimi *"
        />
      </div>
      <p v-if="v$.first_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field">
      <div class="control">
        <input
            v-model=contact.last_name
            class="input"
            type="text"
            placeholder="Sukunimi *"
        />
      </div>
      <p v-if="v$.last_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field" v-if="!getCalendarMode()">
      <div class="control">
        <input
            v-model=contact.company_name
            class="input"
            type="text"
            placeholder="Yrityksen nimi"
        />
      </div>
      <p v-if="v$.company_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field" v-if="!getCalendarMode()">
      <div class="control">
        <input v-model=contact.company_code class="input" type="text" placeholder="Y-tunnus"/>
      </div>
      <p v-if="v$.company_code.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>


    <div class="field">
      <div class="control has-icons-left">
        <input v-model=contact.email
               class="input" type="email" placeholder="Sähköpostiosoite *"/>
        <span class="icon is-small is-left">
        <i class="fas fa-envelope"></i>
        </span>
      </div>

      <p v-if="v$.email.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field">
      <div class="control">
        <input
            v-model=contact.phone
            class="input"
            type="text"
            placeholder="Puhelinnumero (esim +358401234567)"
        />
      </div>
      <p v-if="v$.phone.$error" class="help is-danger">Syötä puhelinnumero oikeassa muodossa (esim +358401234567). Puhelinnumerossa tulee olla 7-15 numeroa. Numerossa tulee olla suuntanumero eikä siinä saa olla välilyöntejä.</p>
    </div>

    <div class="field">
      <div class="control">
        <input
            v-model=contact.subject
            class="input"
            type="text"
            placeholder="Aihe *"
        />
      </div>
      <p v-if="v$.subject.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field" v-if="!getCalendarMode()">
      <div class="control has-icons-left">
        <textarea
            v-model=contact.message rows="5"
            placeholder="Kirjoita viestisi tähän. Kuvaile myös, millaisia erityistarpeita tilauksellasi on ja asiakaspalvelumme auttaa tilauksen teossa tapauskohtaisesti (esim. useampia toimitusosoitteita tai ulkomaan toimituksia).">
        </textarea>
        <p v-if="v$.message.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>
    </div>

    <div class="field" v-else>
      <div class="control has-icons-left">
        <textarea
            v-model=contact.message rows="5"
            placeholder="Kirjoita viestisi tähän *">
        </textarea>
        <p v-if="v$.message.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>
    </div>

  </div>
</template>

<script>
// docs on vuelidate on vue 3 -> https://vuelidate-next.netlify.app/#install-via-plugin-in-vue-3-0
import { reactive } from 'vue'
import {email, required} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'deliveryOrderForm',
  setup: () => {
    let contactData = {
      company_name: '',
      contact_person: '',
      company_code: '',
      email: '',
      message: ''
    }
    const contact = reactive(contactData)
    const phoneValidation = (value) =>  value == undefined || value == '' || (value.startsWith('+') && value.length >= 8 && value.length < 17)

    const rules = {
      company_name: {
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      company_code: {
      },
      email: {
        email,
        required
      },
      phone: {
        phoneValidation
      },
      subject: {
        required
      },
      message: {
        required
      }
    }
    const v$ = useVuelidate(rules, contact)

    return { contact, v$ }
  },

  methods: {
    ...mapMutations(['setSpinnerState']),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getCalendarMode: 'getCalendarMode',
    }),
    submitForm() {
      this.v$.$touch()
      if (this.v$.$error) return

      this.setSpinnerState(true)
      if (this.getCalendarMode()) {
        this.contact.calendar_mode = true
      }
      this.$store.dispatch('postSpecialOrderForm', this.contact).then(()=>{
        this.$router.push({name: 'orderthanks'})
      }).catch((error) => {
        // FIXME: doAsync handles the catch of axios call, we are never getting to here
        console.log('Error saving contact', error)
      }).finally(()=>{
         this.setSpinnerState(false)
      })
    }
  }
}
</script>

<style scoped lang="scss">

.footnote {
  color: #dedbd9;
}

p {
  font-size: 18px;
  font-family: gill-sans-nova;
}

textarea::placeholder {
  color: rgba(54, 54, 54, 0.3);
}

textarea {
  resize: none;
  width: 100%;
  border: 2px solid #dedbd9;
  padding: 11px;
  color: black;
  font-size: 1rem;
}

input[type="number"] {
  border: 2px solid #dedbd9;
}

</style>
