<template>
  <div class="editor" ref="fazerEditorRefWidth">
    <asModalDialog heading-text="3d esikatselu" :show-ok="false" :show-cancel="false" width="80%" min-width="500px"
      ref="previewModal">
      <ThreeDPreviewMultipleObjects
        :data="threeDdataSet"
      />
    </asModalDialog>
    <MarianneCanvas
      ref="marianneCanvas" :canvasText="product.text"
      :canvasTitle="product.title" :canvasSignature="product.signature" />

    <div class="columns mt-6">
      <div class="column is-5 is-offset-4">
        <div class="remove-logo" @click="previewModal()">
          <i class="fas fa-2x fa-cube"></i>
          <p class="underline">Katso 3D-kuva valmiista tuotteesta</p>
        </div>
      </div>
    </div>

    <div class="columns is-mobile mt-0 mb-0">
      <div class="column">
        <div id="front-editor-wrapper" v-show="isCover">
          <figure class="image">
            <img src="../../public/imgs/B2B-Marianne-front.jpg">
          </figure>
          <p v-html="title" class="is-primary" id="title-box-preview"></p>
        </div>
        <div id="back-editor-wrapper" v-if="!isCover">
          <figure class="image">
            <img src="../../public/imgs/B2B-Marianne-back.png">
          </figure>
          <p v-html="text" class="is-primary first-para" ></p>
          <p v-html="signature" class="is-primary second-para"></p>   
        </div>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <p class="column has-text-left is-hidden-tablet"><span class="has-text-weight-bold editor-span-left">Pakkauksen
          teksti</span></p>
      <div class="column is-one-third is-hidden-mobile has-text-left">
        <p><span class="has-text-weight-bold has-text-left editor-span-left">Näytä</span></p>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <div id="toggle-orientation" class="field is-flex is-align-items-center gap-17">
          <label for="switchRoundedDefault" class="left-label">Kortti</label>

          <input id="switchRoundedDefault" type="checkbox" v-model="isCover" name="switchRoundedDefault"
            class="switch is-rounded" checked="checked" />

          <label for="switchRoundedDefault" class="right-label is-flex">Pakkaus</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-tablet">
        <div id="toggle-orientation" class="field is-flex is-align-items-center gap-17">
          <label for="switchRoundedDefault" class="left-label">Kortti</label>

          <input id="switchRoundedDefault" type="checkbox" v-model="isCover" name="switchRoundedDefault"
            class="switch is-rounded" checked="checked" />

          <label for="switchRoundedDefault" class="right-label is-flex">Pakkaus</label>
        </div>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <div class="column is-one-third is-hidden-mobile has-text-left">
        <p><span class="has-text-weight-bold has-text-left editor-span-left">Pakkauksen teksti</span></p>
        <p class="mb-3"><span class="has-text-weight-bold has-text-left editor-span-left">Etukansi / otsikko</span></p>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor :notext="false" :maxRows="1" name="text-editor-title-desktop"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }" @inputtextarea="inputTitle"
          :maxCharactersPerRow="16" :producttext="product.title" ref="titleEditor" @click="toggleBox">
        </TextEditor>
        <span v-if="titleEmpty" style="color: red;">Pakollinen kenttä</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0 mb-3">
        <p class="has-text-left"><span class="has-text-weight-bold editor-span-left">Pakkauksen teksti</span></p>
        <p class="mb-3 has-text-left"><span class="has-text-weight-bold editor-span-left">Etukansi / otsikko</span>
        </p>
        <TextEditor :notext="false" :maxRows="1" name="text-editor-title-mobile"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }" @inputtextarea="inputTitle"
          :maxCharactersPerRow="16" :producttext="product.title" ref="titleEditor" @click="toggleBox">
        </TextEditor>
        <span v-if="titleEmpty" style="color: red;">Pakollinen kenttä</span>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <span class="has-text-weight-bold has-text-left is-hidden-tablet editor-span-left">Kortti / viesti</span>

      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
          <input
            class="is-checkradio is-secondary"
            id="noTextRadioMobile"
            type="checkbox"
            name="noTextRadioMobile"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label class="checkradio-label" for="noTextRadioMobile">Ei tekstiä</label>
      </div>
      <div class="column is-one-third has-text-left is-hidden-mobile">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kortti / viesti</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="noTextRadioDesktop"
            type="checkbox"
            name="noTextRadioDesktop"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label for="noTextRadioDesktop">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor :notext="product.notext" :maxRows="6" name="text-editor-main-desktop"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '130px !important' }" @inputtextarea="inputTextarea"
          :maxCharactersPerRow="50" :producttext="product.text" ref="textEditor" @click="toggleCard" >
        </TextEditor>
        <span v-if="textEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0">
        <TextEditor :notext="product.notext" :maxRows="6" name="text-editor-main-mobile"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '130px !important' }" @inputtextarea="inputTextarea"
          :maxCharactersPerRow="50" :producttext="product.text" ref="textEditor" @click="toggleCard" >
        </TextEditor>
        <span v-if="textEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <span class="has-text-weight-bold has-text-left is-hidden-tablet editor-span-left">Kortti / allekirjoitus</span>
      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
          <input
            class="is-checkradio is-secondary"
            id="noSignatureRadioMobile"
            type="checkbox"
            name="noSignatureRadioMobile"
            v-model="product.nosignature"
            @click="toggleNoSignature()"
          />
          <label class="checkradio-label" for="noSignatureRadioMobile">Ei tekstiä</label>
      </div>
      <div class="column is-one-third has-text-left is-hidden-mobile">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kortti / allekirjoitus</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="noSignatureRadioDesktop"
            type="checkbox"
            name="noSignatureRadioDesktop"
            v-model="product.nosignature"
            @click="toggleNoSignature()"
          />
          <label for="noSignatureRadioDesktop">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor :notext="product.nosignature" :maxRows="1" name="text-editor-signature-desktop"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }" @inputtextarea="inputSignature"
          :maxCharactersPerRow="30" :producttext="product.signature" ref="signatureEditor" @click="toggleCard">
        </TextEditor>
        <span v-if="signatureEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0 mb-3">
        <TextEditor :notext="product.nosignature" :maxRows="1" name="text-editor-signature-mobile"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }" @inputtextarea="inputSignature"
          :maxCharactersPerRow="30" :producttext="product.signature" ref="signatureEditor" @click="toggleCard">
        </TextEditor>
        <span v-if="signatureEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
    </div>

    <div class="columns is-mobile is-flex is-flex-wrap-wrap" style="margin-left: 1px;margin-right: 1px;">
      <div class="column is-4 is-hidden-mobile has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
      </div>
      <div class="column is-3 is-hidden-tablet has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
      </div>
      <div class="column is-4 is-hidden-mobile is-offset-2-mobile has-text-left">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input type="number" @keypress="preventNonNumericalInput(event)" min="0" @change="changedAmount()" v-model.number="product.amount"
              class="input has-text-centered" />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
      <div class="column is-8 is-hidden-tablet has-text-right">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input type="number" @keypress="preventNonNumericalInput(event)" min="0" @change="changedAmount()" v-model.number="product.amount"
              class="input has-text-centered" />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor'
import getSize from 'element-size'
import debounce from 'lodash/debounce'
import asModalDialog from '@/components/helpers/asModalDialog'
import ThreeDPreviewMultipleObjects from '@/components/3d/ThreeDPreviewMultipleObjects'
import MarianneCanvas from '@/components/MarianneCanvas.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'

export default {
  name: 'MarianneEditor',
  props: {
    productId: String
  },
  components: {
    TextEditor,
    asModalDialog,
    ThreeDPreviewMultipleObjects,
    MarianneCanvas
  },
  mixins: [inputNumberMixin],  
  data() {
    return {
      threeDdata: {
        name: 'Marianne',
        uri: `${process.env.BASE_URL}3d/marianne.glb`,
        objects: [],
        animations: ['FullyOpen', 'PartiallyOpen', 'Closed']
      },
      textureUri: `${process.env.BASE_URL}3d/fazer-candy-box-v2.glb`,
      marianneEditorWidth: 657,
      marianneEditorHeight: 263,
      temporalText: '',
      isCover: true,
      product: {
        notext: false,
        nosignature: false,
        amount: 1,
        id: null,
        text: '',
        title: '',
        signature: '',
        front_side_picture: '',
        back_side_picture: ''
      },
      element_zoom: 0, // text preview element zoom
      Url: null,
      textAsImageUrl: '',
      backAsImageUrl: '',
      editorScale: 1,
      titleEmpty: false,
      textEmpty: false,
      signatureEmpty: false
    }
  },

  mounted() {
    this.initEditorState(this.productId)
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.handleTextPreviewResize()
      this.onResize()
    })
    if(!this.product.draft) {
      if(!this.product.signature && !this.product.nosignature) this.toggleNoSignature();
      if(!this.product.text && !this.product.notext) this.toggleNoText();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    threeDdataSet() {
      return {...this.threeDdata, objects: [this.textImageTexture, this.backImageTexture]}
    },
    textImageTexture() {
      return {
        id: 1,
        objectName: 'bottom',
        name: 'Bottom',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.textAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    backImageTexture() {
      return {
        id: 2,
        name: 'Top',
        objectName: 'top',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.backAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          }
        ],
      }
    },
    text() {
      if (this.product.text == '' && !this.product.notext) {
        return 'Viesti (max 300 merkkiä)'
      } else {
        return this.product.text.replace(/\n/g, '<br>')
      }
    },
    signature() {
      if (this.product.signature == '' && !this.product.nosignature) {
        return 'Allekirjoitus (max 30 merkkiä)'
      } else {
        return this.product.signature.replace(/\n/g, '<br>')
      }
    },
    title() {
      if (this.product.title == '' && !this.product.notext) {
        return 'Otsikko (max 16)'
      } else {
        return this.product.title.replace(/\n/g, '<br>')
      }
    },
    viewportWidthHeight() {
      return {
        width: this.marianneEditorWidth * this.editorScale,
        height: this.marianneEditorHeight * this.editorScale,
      }
    },
    backPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        'font-family': 'Karl Fazer',
        'font-size': '24pt',
        'color': '#c69b67 !important',
        'position': 'relative'
      })
      return Object.assign(styles, {
        height: `${this.marianneEditorHeight * this.editorScale}px`,
        width: `${this.marianneEditorWidth * this.editorScale}px`,
      })
    },
    sideFontPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        transform: 'scale(' + this.element_zoom + ')',
        'max-width': this.marianneEditorWidth + 'px',
        'color': '#c69b67 !important',
        'font-size': '40pt',
        'white-space': 'nowrap',
      })
      return styles
    },
    textareaCharacters() {
      return this.product.text.length
    },
    editorHeightResizer() {
      if (this.$refs.editorWrapper != null) {
        return this.$refs.editorWrapper.clientHeight
      }
      return this.marianneEditorHeight
    },
  },
  watch: {
    productId: function (newId, oldId) {
      if (newId === oldId) {
        return
      }
      this.setSpinnerState(true)
      const previousBox = JSON.parse(JSON.stringify(this.product)) // copy of old box, drops proxies
      this.saveBox(previousBox)
        .then(() => {
          this.initEditorState(this.productId)
        })
        .catch((error) => {
          console.log('error saving box', error)
        })
        .finally(() => {
          this.setSpinnerState(false)
        })
    },
    temporalText: function (val) {
      val = val.split('\n').slice(0, 3).join('\n')

      if (!this.product.notext) {
        this.product.text = val
      }
    },
  },
  methods: {
    ...mapActions(['updateOrder']),
    ...mapMutations([
      'setSpinnerState',
      'updateProductAmount'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData'
    }),
    onResize() {
      if (this.$refs.fazerEditorRefWidth) {
        let editor = this.$refs.fazerEditorRefWidth
        let size = getSize(editor) // uncomment this to enable zoom support
        this.editorScale = size[0] / this.marianneEditorWidth
      }
      this.handleTextPreviewResize()
    },
    async loadTextures() {
      let self = this
      return self.$refs.marianneCanvas.draw().then(() => {
        self.backAsImageUrl = self.$refs.marianneCanvas.$refs.frontCanvas.toDataURL('image/jpeg', 0.2)
        self.product.back_side_picture = self.backAsImageUrl
        self.textAsImageUrl = self.$refs.marianneCanvas.$refs.backCanvas.toDataURL('image/jpeg', 0.2)
        self.product.front_side_picture = self.textAsImageUrl
      })
    },
    previewModal() {
      // take snapshot of image and text as it is and show 3d preview
      let self = this
      this.setSpinnerState(true)
      this.loadTextures()
        .then(() => {
          this.setSpinnerState(false)
          return self.$refs.previewModal.showModal()
        })
        .then((amount, ...args) => {
          console.log('3d preview closed', args)
        })
        .catch((error) => {
          console.log('3d preview closed', error)
        })
    },
    initEditorState(productId) {
      let product = this.$store.getters.getProductById(productId)
      this.product = product
      this.temporalText = this.product.text
    },
    result(output) {
      this.cropped = output
    },
    increaseAmount() {
      this.product.amount += 1
      this.updateProductAmount({ 'product_id': this.product.id, 'amount': this.product.amount })
    },
    decreaseAmount() {
      if (this.product.amount > 1) {
        this.product.amount -= 1
      }
      this.updateProductAmount({ 'product_id': this.product.id, 'amount': this.product.amount })
    },
    changedAmount() {
      this.updateProductAmount({ 'product_id': this.product.id, 'amount': this.product.amount })
    },
    saveBox(productObject) {
      this.titleEmpty = false
      this.textEmpty = false
      this.signatureEmpty = false
      let scrollToError = ''
      if (this.product.title == '') {
        this.titleEmpty = true
        scrollToError = 'text-editor-title'
      }
      if (this.product.text == '' && !this.product.notext) {
        this.textEmpty = true
        if (!scrollToError) scrollToError = 'text-editor-main'
      }
      if (this.product.signature == '' && !this.product.nosignature) {
        this.signatureEmpty = true
        if (!scrollToError) scrollToError = 'text-editor-signature'
      }
      if (this.titleEmpty || this.signatureEmpty || this.textEmpty) {
        this.setSpinnerState(false);
        if(scrollToError) {
          document.getElementById(`${scrollToError}-desktop`).scrollIntoView({ block: "center", behavior: "smooth" });
          document.getElementById(`${scrollToError}-mobile`).scrollIntoView({ block: "center", behavior: "smooth" });
        }        
        return Promise.reject('Check product')
      }
      else {
        return this.loadTextures()
            .then(() => {
              if (productObject == null) productObject = this.product
              productObject['draft'] = false
              productObject['type'] = 'ALL_TO_SAME_ADDRESS'
              return this.updateOrder({'products': [productObject]})
            })
      }
    },
    handleTextPreviewResize: debounce(function () {
      let size = document.querySelector('.text-preview')?.offsetWidth
      this.element_zoom = size / 900
    }, 300),
    inputTextarea(event) {
      this.product.text = event
    },
    inputTitle(event) {
      this.product.title = event
    },
    inputSignature(event) {
      this.product.signature = event
    },
    toggleCard() {
      this.isCover = false
    },
    toggleBox() {
      this.isCover = true
    },
    toggleNoText() {
      this.product.notext = !this.product.notext
      if (this.product.notext) {
        this.product.text = ''
      } else {
        this.product.text = this.temporalText
      }
    },
    toggleNoSignature() {
      this.product.nosignature = !this.product.nosignature
      if (this.product.nosignature) {
        this.product.signature = ''
      } else {
        this.product.signature = this.temporalText
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

$total-width: 1110px;
$original-width: 4 00px;
$switch-background: #726f6f;
$switch-background-active: #726f6f;

.editor {
  @media (max-width: 500px) {
    margin-top: 20px;
  }

  span {
    font-size: 18px;

    @media (max-width: 400px) {
      font-size: 12px;
    }

    font-family: gill-sans-nova;
    color: $primary;
  }

  label {
    font-size: 18px;
    font-family: gill-sans-nova;
    color: $primary;
  }

  .switch[type='checkbox'].is-rounded+label::before {
    border: 1px solid grey;
  }

  label.togglebold {
    color: $primary;
    font-weight: bold;
  }

  .right-label {
    padding-left: 4rem !important;
    margin-left: 1rem;
  }

  .amount-options {
    .svg-wrapper {
      display: inline-block;

      svg {
        font-size: 25px;
        top: 3px;
        position: relative;
        color: $primary;
      }

      svg:hover {
        cursor: pointer;
        color: $primary;
      }
    }

    span {
      width: 70px;
      display: inline-block;
      border: 1px solid gray;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #editor-title {
    text-align: left;
  }

  .is-checkradio[type='radio']:hover:not([disabled])+label::before {
    border: 0.1rem solid $secondary !important;
  }

  .is-checkradio[type='radio']:checked+label::after {
    background-color: $secondary !important;

    &:hover {
      border: 0.1rem solid $secondary !important;
    }
  }
}

.level-right {
  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
}

.level-item p {
  color: $primary;
  font-family: gill-sans-nova;
  margin-top: 33px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.amount-input-wrapper {
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

#title-box-preview {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);  
  font-size: 47px;
  color: #222;
  font-weight: 500;
  font-family: 'Felt That';

  @media screen and (max-width: 500px) {
    font-size: 32px;
  }
}

.checkradio-label {
  font-size: 18px !important;

  @media screen and (max-width: 500px) {
    font-size: 12px !important;
  }
}

.editor-top-span {
  border-bottom: 1px solid $primary;
  padding-bottom: 10px;
}

.editor-span-left {
  padding-left: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    padding-left: 10px;
    padding-bottom: 0px;
  }
}

.border-bottom {
  margin-left: 1px;
  margin-right: 1px;
  border-bottom: 1px solid $primary;
}

.border-right {
  border-right: 1px solid $primary;
}

#front-editor-wrapper {
  position: relative;
}

#back-editor-wrapper {
  position: relative;
  .first-para {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);      
    font-size: 20px;
    font-weight: 500;
    font-family: 'Felt That';
    color: #222;
    @media screen and (max-width: 500px) {
      font-size: 12px;
      line-height: 12px;
    }    
  }
  .second-para {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);        
    font-family: 'Felt That';
    font-size: 30px;
    font-weight: 500;
    color: #222;
    @media screen and (max-width: 500px) {
      font-size: 20px;
    }    
  }
}

.remove-logo:hover {
  cursor: pointer;
}

.remove-logo {
  cursor: pointer;
  color: $primary;

  svg {
    font-size: 35px;

    @media screen and (max-width: 500px) {
      font-size: 25px;
    }
  }

  svg:hover {
    cursor: pointer;
  }

  p {
    color: $primary;
    font-family: gill-sans-nova;
  }

  p:hover {
    text-decoration: underline;
  }
}

label.right-label {
  font-size: 18px !important;
}
</style>
