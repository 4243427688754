<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body has-text-centered">
          <div class="content">
            <p class="pt-3">Valitettavasti palvelussa on teknisiä ongelmia. Korjaamme vikaa parhaillaan. Yritäthän myöhemmin uudelleen. Pahoittelemme aiheutunutta vaivaa.</p>
          </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DisabledSiteModal'
}
</script>
<style type="scss" scoped>

.modal-card {
  max-width: 850px;
  width: 90%;
  border-radius: 5px;
}

.content {
  text-align: left;
}

p {
  color: #15256B;
}

.modal {
  z-index: 1000;
  position: fixed;
}
</style>
