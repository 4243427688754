<template>
  <div class="rules">
    <SectionHeader></SectionHeader>
    <div class="section">
      <div class="container is-relative fix-position">
        <div class="columns">
          <div class="column is-full mb-6">
            <div class="content is-medium">
              <h1 class="has-text-centered">Vinkkejä pakkauksen suunnitteluun</h1>
              <p>Suosittelemme päivittämään selaimen uusimpaan versioon ja käyttämään pöytäkonetta tilauksen tekemisessä.</p>

              <h3>Oma Domino pakkausta suunnitellessa, valitse kuva, mikä on riittävän terävä (tiedostokooltaan n.2 Mt ja max 20Mt)</h3>
              <p>Oma Domino -pakkaukseen ladattavan kuvan tiedostomuotona suositeltava on .JPG</p>

              <p>Kuvan alkuperäinen koko auttaa, kun asemoit kuvaa oikeaan kokoon ja kohtaan koko pakkauksen etupuolen peittäväksi.</p>

              <p>Huomioi kuvia valitessa pakkauksen etupuolen pitkulainen muoto 204x103 mm ja logon osalta lopullinen koko 35x35 mm</p>

              <h3>Pakkausversiot samassa tilauksessa</h3>

              <p>
                Samassa tilauksessa voi olla useita eri kuvia ja pakkausversioita. Samaan tilaukseen voit halutessasi lisätä kaikkia
                erilaisia personoituja tuotteita.
              </p>

              <p>
                Samassa Oma Domino -tilauksessa voi olla sekä pysty- että vaakaversioita ja erilaisia määriä. Oma Domino -pakkauksen
                etupuoli voidaan valita pysty ja vaaka asentoon käytettävän kuvan mukaan. Vaakakuvat voivat olla esimerkiksi ryhmäkuvia,
                piirustuksia, lemmikkejä, maisemia tai jokin muu sopiva vaakakuva. Pystykuvat voivat olla esimerkiksi yksittäisen pelaajien,
                jäsenten, lemmikin kuva tai jokin muu sopiva pystykuva.
              </p>

              <p>
                Muiden tuotteiden asentoa ei voi muuttaa.
              </p>

              <h3>Kerralla ei tarvitse saada valmista, vaan voit tallentaa linkin ja jatkaa myöhemmin.</h3>

              <p>
                Linkin voi jakaa toiselle henkilölle, mutta vain yksi kerrallaan voi työstää tilauksen pakkauksia ja ladata lisää kuvia.
              </p>

              <p>
                Linkkiä voi käyttää myös hyväksyntää varten, mikäli haluat muiden näkevän kuvat ennen tilauksen lopullista lähettämistä.
              </p>

              <h3>
                Tekstin lisäys
              </h3>

              <p>
                Kaikkiin personoituihin tuotteisiinne voit lisätä itse tekstiä. Oma Domino -pakkaukseen voit lisätä tekstiä pakkauksen
                toiseen kylkeen, johon on valittavissa 3 eri fonttia (koko teksti samalla fontilla). Oma Domino -pakkauksessa ei ole pakko
                käyttää tekstiä.
              </p>

              <p>
                Karl Fazer -konvehtipakkaukseen on pakko lisätä tekstiä etupuolelle. Sama teksti näkyy pienempänä paketin kyljessä.
                Takapuolen teksti ei ole pakollinen. Konvehtipakkauksen fonttia ei voi vaihtaa.
              </p>

              <p>
                Karl Fazer -suklaalevyyn on pakko lisätä tekstiä etupuolelle. Takapuolen teksti ei ole pakollinen. Konvehtipakkauksen fonttia ei voi vaihtaa.
              </p>

              <p>
                Marianne-pakettiin on pakko lisätä tekstiä etupuolelle. Takapuolen teksti ei ole pakollinen. Konvehtipakkauksen fonttia ei voi vaihtaa.
              </p>

              <p>
                Custom Candy Box -karkkipakettiin on pakko lisätä tekstiä etupuolelle. Takapuolen teksti ei ole pakollinen. Voit myös vaihtaa fonttia sekä paketin väriä.
              </p>

              <h3>Logon käyttö (ei pakollinen)</h3>

              <p>
                Oma Domino -pakkaukseen voit ladata logon ja asemoida sen palvelussa 35x35 mm ruutuun sopivaksi.
                (huom. logossa .PNG kuva toistuu ilman taustaa. Voit toki käyttää .JPG kuvaa, jolloin logoon jää tausta)
                Logo on aina kaikissa tilatuissa pakkauksissa sama ja sillä on vakiopaikka sekä pysty että vaakamallisessa
                pakkauksessa oikeassa alakulmassa. Neliön muotoinen kuvatiedosto sopii parhaiten pakettiin.
              </p>

              <p>
                Custom Candy Box -karkkipakkaukseen voit ladata logon ja asemoida sen palvelussa 93x50 mm ruutuun sopivaksi.
                (huom. logossa .PNG kuva toistuu ilman taustaa. Voit toki käyttää .JPG kuvaa, jolloin logoon jää tausta) Logo
                on aina kaikissa tilatuissa pakkauksissa sama ja sillä on vakiopaikka paketin keskellä. Suosittelemme logon olemaan
                isompi kuin 372x200px, jotta se on sopivan kokoinen ruutuun.
              </p>

              <p>Karl Fazer -konvehtipakkaukseen tai suklaalevyyn ei voi lisätä logoa.</p>

              <h3>Valmiin tuotteen esikatselu</h3>

              <p>
                Voit esikatsella valmista tuotetta suunnitteluvaiheessa löytyvän 3D-esikatselun avulla. 3D-esikatselu
                löytyy sivun yläosasta.
              </p>

              <button
                class="upload-button button is-primary mt-6"
                @click="$router.push({name: 'Home'})"
              >
                <span>Takaisin</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import { useMeta } from 'vue-meta'
import {mapActions, mapGetters} from "vuex"

export default {
  name: 'HintsPage.vue',
  components: {
    SectionHeader,
    Footer
  },
  methods: {
    ...mapActions([
      'getProducts'
    ]),
    ...mapGetters({
      getCalendarMode: 'getCalendarMode'
    }),
  },
  mounted() {
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    } else {
      useMeta({
        title: 'Vinkkejä personoidun Fazer-tuotteen suunnitteluun – Custom Fazer',
        description: 'Kun otat suunnittelussa tekstien ja kuvien kanssa huomioon muutaman asian, niin et voi epäonnistua. Lue keräämämme vinkit suunnitteluun.'
      })
    }
    this.getProducts()
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/mybulma';

h1 {
  color: $primary !important;
  font-size: 45px !important;
}

h3 {
  color: $primary !important;
}

ol {
  counter-reset: item;
  margin-bottom: 2rem;
}

li {
  display: block;
  font-weight: bold;

  li{
    font-weight: normal;
  }
}

li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
  font-weight: bold;
}

.content {
  padding: 20px;
}

button {
  span {
    font-size: 20px;
  }
}
</style>
