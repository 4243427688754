// define a mixin object
let asModalDialogMixin = {
  props: {
    showOk: {
      type: Boolean,
      default: true,
    },
    okButtonText: {
      type: String,
      default: 'Kyllä',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Peruuta',
    },
    headingText: {
      type: String,
      default: '',
    },
  },
  emits: ['modal-result-ok', 'modal-result-cancel'],
  data() {
    return {
      isActive: false,
      resolve: null,
      reject: null,
    }
  },
  computed: {
    hasButtons() {
      return this.showOk || this.showCancel
    },
  },
  methods: {
    showModal() {
      document.documentElement.style.overflow = 'hidden'
      // promise based function to show dialog
      this.isActive = true
      this.$nextTick().then(()=>{
        this.$refs.modalroot.focus()
      })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    answerOK(...args) {
      document.documentElement.style.overflow = 'auto'
      console.log('OK args', args)
      this.$emit('modal-result-ok')
      this.isActive = false
      if (this.resolve != null) {
        return this.resolve(...args)
      }
    },
    answerNAY(...args) {
      document.documentElement.style.overflow = 'auto'
      console.log('closing')
      this.$emit('modal-result-cancel')
      this.isActive = false
      if (this.reject != null) {
        return this.reject(...args)
      }
    },
  },
}



export default asModalDialogMixin;
