let camelCase = require('lodash.camelcase')
/*
 * createAsyncMutation('GET_INFO_ASYNC') will lead to:
 *
 * GET_INFO_ASYNC = {
 *      BASE: GET_INFO_ASYNC_BASE,
 *      SUCCESS: GET_INFO_ASYNC_SUCCESS,
 *      PENDING: GET_INFO_ASYNC_PENDING,
 *      FAILURE: GET_INFO_ASYNC_FAILURE,
 *      loadingKey: getInfoAsyncPending,
 *      errorCode: getInfoAsyncErrorCode,
 *      stateKey: getInfoAsyncData
 * }
 *
 */
const createAsyncMutation = (type) => ({
  BASE: `${type}`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: `${camelCase(type)}Pending`,
  statusCode: `${camelCase(type)}StatusCode`,
  stateKey: `${camelCase(type)}Data`,
})

export const POST_IMAGES = createAsyncMutation('POST_IMAGES')
export const PUT_LOGO = createAsyncMutation('PUT_LOGO')
export const REMOVE_BOX = createAsyncMutation('REMOVE_BOX')
export const CLONE_DOMINO_BOX = createAsyncMutation('CLONE_DOMINO_BOX')
export const CLONE_FAZER_BOX = createAsyncMutation('CLONE_FAZER_BOX')
export const CLONE_FAZER_BAR = createAsyncMutation('CLONE_FAZER_BAR')
export const CLONE_CANDY_BOX = createAsyncMutation('CLONE_CANDY_BOX')
export const CLONE_MARIANNE_BOX = createAsyncMutation('CLONE_MARIANNE_BOX')
export const CLONE_CALENDAR = createAsyncMutation('CLONE_CALENDAR')
export const POST_LOGIN = createAsyncMutation('POST_LOGIN')
export const UPDATE_ORDER = createAsyncMutation('UPDATE_ORDER')
export const PARTIAL_UPDATE_ORDER = createAsyncMutation('PARTIAL_UPDATE_ORDER')
export const POST_CONTACT = createAsyncMutation('POST_CONTACT')
export const PUT_CONTACT = createAsyncMutation('PUT_CONTACT')
export const LIST_DISCOUNT_VOLUMES = createAsyncMutation('LIST_DISCOUNT_VOLUMES')
export const USE_VOUCHER_DISCOUNT = createAsyncMutation('USE_VOUCHER_DISCOUNT')
export const LIST_SHIPPING_PRICES = createAsyncMutation('LIST_SHIPPING_PRICES')
export const CHECK_EXPIRATION_SESSION = createAsyncMutation('CHECK_EXPIRATION_SESSION')
export const GET_POLICIES = createAsyncMutation('GET_POLICIES')
export const GET_PRODUCTS = createAsyncMutation('GET_PRODUCTS')
export const GET_SETTINGS = createAsyncMutation('GET_SETTINGS')
export const PAYTRAIL_SESSION = createAsyncMutation('PAYTRAIL_SESSION')
export const POST_FAZER_BOX = createAsyncMutation('POST_FAZER_BOX')
export const POST_FAZER_BAR = createAsyncMutation('POST_FAZER_BAR')
export const POST_MARIANNE_BOX = createAsyncMutation('POST_MARIANNE_BOX')
export const PARTIAL_UPDATE_BOX = createAsyncMutation('PARTIAL_UPDATE_BOX')
export const SPECIAL_ORDER_FORM = createAsyncMutation('SPECIAL_ORDER_FORM')
export const LIST_FREIGHT_PRICES = createAsyncMutation('LIST_FREIGHT_PRICES')
export const POST_CUSTOM_CANDY_BOX = createAsyncMutation('POST_CUSTOM_CANDY_BOX')
export const PARTIAL_UPDATE_CUSTOM_CANDY_BOX = createAsyncMutation('PARTIAL_UPDATE_CUSTOM_CANDY_BOX')
export const GET_MARKETING_LANDING_PAGE_TEXT = createAsyncMutation('GET_MARKETING_LANDING_PAGE_TEXT')
export const GET_MARKETING_LANDING_PAGE_TITLE = createAsyncMutation('GET_MARKETING_LANDING_PAGE_TITLE')
export const GET_CALENDAR_DOMAIN = createAsyncMutation('GET_CALENDAR_DOMAIN')
export const GET_DELIVERY_ADDRESS = createAsyncMutation('GET_DELIVERY_ADDRESS')
export const POST_CALENDAR = createAsyncMutation('POST_CALENDAR')
export const SCAN_BAR_CODE = createAsyncMutation('SCAN_BAR_CODE')

