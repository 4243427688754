<template>
  <div class="editor" v-if="getOrderData()">
    <SectionHeader></SectionHeader>
      <section class="hero is-fullheight" v-if="editorTabActive == true">
        <div class="hero-body pb-6">
          <div class="container is-fullwidth">
            <div class="columns">
              <div class="column is-8-desktop is-offset-2-desktop is-12-mobile is-12-tablet">
                <div class="columns is-mobile">
                  <div class="column has-background-white is-full" ref="barEditorContainer" v-if="currentProduct != null && currentProduct.product.name == 'bar'">
                    <h1 class="mb-6 mt-2">Pakkauksen muokkaaminen</h1>
                    <div class="columns mb-6">
                      <div class="column">
                        <p class="size16 has-text-centered">
                          Valitse alta, haluatko muokata ja
                          esikatsella tuotteen ennen tilausta vai haluatko toimittaa jälkikäteen tekstit tiedoston avulla.
                          Jälkikäteen toimittamista suositellaan, mikäli haluat jokaiseen levyyn erilaisen tekstin ja
                          levyjä on useita. Kun olet lisännyt tuotteen ostoskoriin, pääset ostoskorissa valitsemaan
                          haluamasi toimitustavan tuotteelle.
                        </p>
                      </div>
                    </div>
                    <div class="columns m-0">
                      <div class="column py-0">
                        <p class="size16 has-text-left">Suklaalevy</p>
                      </div>
                    </div>
                    <div class="columns mt-5 is-mobile">
                      <div class="column is-4 pr-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == true }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == false"></span>
                          <span class="full-circle" v-else></span>
                          Muokkaan tekstit itse</p>
                      </div>
                      <div class="column is-8 pl-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == false }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == true"></span>
                          <span class="full-circle" v-else></span>
                          Lähetän tekstit jälkikäteen (suositellaan, kun useita erilaisia)</p>
                      </div>
                    </div>
                    <bar-editor
                      v-if="currentBox"
                      :productId="currentBox"
                      class="editor"
                      ref="barEditor"
                    >
                    </bar-editor>
                    <div v-else class="">
                      <h3>Valitse muokattava pakkauskuva</h3>
                    </div>
                  </div>
                  <div class="column has-background-white is-full" ref="marianneEditorContainer" v-else-if="currentProduct != null && currentProduct.product.name == 'marianne'">
                    <h1 class="mb-6 mt-2">Pakkauksen muokkaaminen</h1>
                    <div class="columns mb-6">
                      <div class="column">
                        <p class="size16 has-text-centered">
                          Valitse alta, haluatko muokata ja
                          esikatsella tuotteen ennen tilausta vai haluatko toimittaa jälkikäteen tekstit tiedoston avulla.
                          Jälkikäteen toimittamista suositellaan, mikäli haluat jokaiseen levyyn erilaisen tekstin ja
                          levyjä on useita. Kun olet lisännyt tuotteen ostoskoriin, pääset ostoskorissa valitsemaan
                          haluamasi toimitustavan tuotteelle.
                        </p>
                      </div>
                    </div>
                    <div class="columns m-0">
                      <div class="column py-0">
                        <p class="size16 has-text-left">Marianne</p>
                      </div>
                    </div>
                    <div class="columns mt-5">
                      <div class="column is-4 pr-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == true }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == false"></span>
                          <span class="full-circle" v-else></span>
                          Muokkaan tekstit itse</p>
                      </div>
                      <div class="column is-8 pl-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == false }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == true"></span>
                          <span class="full-circle" v-else></span>
                          Lähetän tekstit jälkikäteen (suositellaan, kun useita erilaisia)</p>
                      </div>
                    </div>
                    <marianne-editor
                      v-if="currentBox"
                      :productId="currentBox"
                      class="editor"
                      ref="marianneEditor"
                    >
                    </marianne-editor>
                    <div v-else class="">
                      <h3>Valitse muokattava pakkauskuva</h3>
                    </div>
                  </div>
                  <div class="column has-background-white is-full" ref="customCandyBoxEditorContainer" v-else-if="currentProduct != null && currentProduct.product.name == 'custom-candy-box'">
                    <h1 class="mb-6 mt-2">Pakkauksen muokkaaminen</h1>
                    <div class="columns mb-6">
                      <div class="column">
                        <p class="size16 has-text-centered">
                          Valitse alta, haluatko muokata ja
                          esikatsella tuotteen ennen tilausta vai haluatko toimittaa jälkikäteen tekstit tiedoston avulla.
                          Jälkikäteen toimittamista suositellaan, mikäli haluat jokaiseen levyyn erilaisen tekstin ja
                          levyjä on useita. Kun olet lisännyt tuotteen ostoskoriin, pääset ostoskorissa valitsemaan
                          haluamasi toimitustavan tuotteelle.
                        </p>
                      </div>
                    </div>
                    <div class="columns m-0">
                      <div class="column py-0">
                        <p class="size16 has-text-left">Custom Candy Box</p>
                      </div>
                    </div>
                    <div class="columns mt-5">
                      <div class="column is-4 pr-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == true }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == false"></span>
                          <span class="full-circle" v-else></span>
                          Muokkaan tekstit itse</p>
                      </div>
                      <div class="column is-8 pl-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == false }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == true"></span>
                          <span class="full-circle" v-else></span>
                          Lähetän tekstit jälkikäteen (suositellaan, kun useita erilaisia)</p>
                      </div>
                    </div>
                    <custom-candy-box-editor
                      v-if="currentBox"
                      :productId="currentBox"
                      class="editor"
                      ref="customCandyBoxEditor"
                    >
                    </custom-candy-box-editor>
                    <div v-else class="">
                      <h3>Valitse muokattava pakkauskuva</h3>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <editor-footer v-if="currentProduct != null"
                      class="is-full"
                      :productId="currentBox"
                      :productType="currentProduct"
                      @continue-to-cart="toCartPage"
                      @continue-to-bar-editor="goToEditor"
                      @continue-to-marianne-editor="goToEditor"
                      @continue-to-candy-box-editor="goToEditor"
                      @save-link="saveBox"
                    ></editor-footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </section>

      <section class="hero is-fullheight" v-else>
        <div class="hero-body">
          <asModalDialog
            heading-text="3d esikatselu"
            :show-ok="false"
            :show-cancel="false"
            width="80%"
            min-width="500px"
            ref="previewModal"
          >
          <ThreeDPreview
              :texture-width=preview.textureWidth
              :texture-height=preview.textureHeight
              :material-name="preview.gblVariable"
              :textures="preview.textures"
              :texture-uri="preview.gblPath"
          />
          </asModalDialog>
          <asModalDialog
            heading-text="3d esikatselu"
            :show-ok="false"
            :show-cancel="false"
            width="80%"
            min-width="500px"
            ref="mariannePreview"
          >
          <ThreeDPreviewMultipleObjects
            :data="threeDdataSetMarianne"
          />
          </asModalDialog>

          <asModalDialog
            heading-text="3d esikatselu"
            :show-ok="false"
            :show-cancel="false"
            width="80%"
            min-width="500px"
            ref="customCandyBoxPreview"
            :visibility="threeDeeRenderReady"
          >
          <ThreeDPreviewMultipleObjects
            :data="threeDdataSetCandyBox"
             v-on:renderready="threeDeerenderReady()"
          />
          </asModalDialog>

          <asModalDialog :show-ok="false" :show-cancel="false" heading-text="Aseta Logo" ref="logoEditorModal">
            <logo-editor
                :mutation="'partialUpdateCustomCandyBox'"
                :productIdProp="this.$route.params.uuid"
                :metadata="{
                // real size (93x50)
                desktop: {
                  viewport: {  // *4
                    width: 372,
                    height: 200,
                  }
                },
                noDesktop: {
                  viewport: {  // *2
                    width: 186,
                    height: 100,
                  }
                },
              }"/>
          </asModalDialog>

          <canvas-image
          ref="smallSideCanvas"
          :image-width=1024
          :image-height=61
          :options="{
            backgroundColor: 'transparent'
          }"
          />

          <canvas-image
            ref="bigSideCanvas"
            :image-width=1024
            :image-height=300
            :options="{
              backgroundColor: 'transparent'
            }"
          />



          <CustomCandyBoxCanvas
            ref="customCandyBoxCanvas" :canvasText="''"
            :canvasTitle="''" :canvasSignature="''"
            :fontStyle="{ 'font-family': fontFamily }"
            :bgColor=candyBoxColor
            :productlogoUrl="custom_candy_box_logo_url"
            style="display: none;"
          />
          <CustomCandyBoxCanvas
            ref="customCandyBoxCanvasPreview" :canvasText="'Tämä on malliteksti, jonka tilalle tulee haluamasi<200b>\n\n'+
                 'teksti.  Merkkejä voi olla enintään 300.<200b>\n\n'+
                 'Tekstiä voi olla enintään 6 riviä.'"
            :canvasTitle="'Malliteksti'" :canvasSignature="'Allekirjoitus'"
            :fontStyle="{ 'font-family': fontFamily }"
            :bgColor=candyBoxColor
            :productlogoUrl="custom_candy_box_logo_url"
            style="display: none;"
          />

            <div class="container is-fullwidth">
              <div class="columns">
                <div class="column is-full has-background-white is-8-desktop is-offset-2-desktop is-12-mobile is-12-tablet">

                    <div class="columns mb-6">
                      <div class="column">
                        <h1 class="mb-6 mt-2 has-text-centered">Pakkauksen muokkaaminen</h1>
                        <p class="size16 has-text-centered">
                          Valitse alta, haluatko muokata ja
                          esikatsella tuotteen ennen tilausta vai haluatko toimittaa jälkikäteen tekstit tiedoston avulla.
                          Jälkikäteen toimittamista suositellaan, mikäli haluat jokaiseen levyyn erilaisen tekstin ja
                          levyjä on useita. Kun olet lisännyt tuotteen ostoskoriin, pääset ostoskorissa valitsemaan
                          haluamasi toimitustavan tuotteelle.
                        </p>
                      </div>
                    </div>

                    <div class="columns m-0" v-if="currentProduct.product.name == 'bar'">
                      <div class="column py-0">
                        <p class="size16 has-text-left">Suklaalevy</p>
                      </div>
                    </div>
                    <div class="columns m-0" v-else-if="currentProduct.product.name == 'marianne'">
                      <div class="column py-0">
                        <p class="size16 has-text-left">Marianne</p>
                      </div>
                    </div>
                    <div class="columns m-0" v-else-if="currentProduct.product.name == 'custom-candy-box'">
                      <div class="column py-0">
                        <p class="size16 has-text-left">Custom Candy Box</p>
                      </div>
                    </div>

                    <div class="columns mt-5 is-mobile">
                      <div class="column is-4 pr-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == true }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == false"></span>
                          <span class="full-circle" v-else></span>
                          Muokkaan tekstit itse</p>
                      </div>
                      <div class="column is-8 pl-0">
                        <p class="editor-tab"
                           :class="{ 'is-editor-tab-active': editorTabActive == false }"
                           @click="switchTab()">
                          <span class="circle" v-if="editorTabActive == true"></span>
                          <span class="full-circle" v-else></span>
                          Lähetän tekstit jälkikäteen (suositellaan, kun useita erilaisia)</p>
                      </div>
                    </div>
                    <h1 class="has-text-centered">Tilauksen jatkokäsittely tiedoston avulla</h1>
                    <p class="has-text-centered">
                      Kun tilaukset toimitetaan kotiosoitteisiin tai tilauksella on useita erilaisia versioita, käsittelemme
                      tilauksen excel-tiedoston avulla. Asiakaspalvelumme lähettää sinulle tilauksen jälkeen excel-tiedoston,
                      jossa on selkeät ohjeet viestien ja osoitteiden kirjaamiselle. Täytettyäsi tarvittavat tiedot, toimita
                      taulukko takaisin meille ja käsittelemme tilauksesi valmiiksi.
                    </p>
                    <p class="has-text-centered">
                      Seuraavaksi syötä kappalemäärä tuotteelle.
                    </p>
                    <div class="columns mt-5">
                      <div class="column is-3 is-offset-3">
                        <img :src=getProductThumbnail>
                      </div>
                      <div class="column is-3 has-text-centered">
                        <p>{{getProductDescription}}</p>
                        <div class="amount-options mt-6">
                          <div class="svg-wrapper" @click="decreaseAmount()">
                            <i class="fas fa-minus-circle"></i>
                          </div>
                          <div class="amount-input-wrapper control is-inline-flex">
                            <input type="number" @keypress="preventNonNumericalInput(event)" min="0" @change="changedAmount()" v-model.number="amount" class="input has-text-centered" />
                          </div>
                          <div class="svg-wrapper" @click="increaseAmount()">
                            <i class="fas fa-plus-circle"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns mt-5 mb-5 product-options" v-if="this.product && this.product.product.name == 'custom-candy-box'">
                      <div class="column is-6 is-offset-3 has-text-left light-blue" style="padding-top: 2px;">
                        <span class="icon is-small is-clickable is-inline-block" @click="changeLogo">
                          <i class="fas fa-star"></i>
                        </span>
                        <p class="is-inline-block" @click="changeLogo">Aseta logo</p>
                      </div>
                    </div>
                    <div class="columns mt-5 mb-5 product-options" v-if="this.product && this.product.product.name == 'custom-candy-box' && custom_candy_box_logo_url != ''">
                      <div class="column is-6 is-offset-3 has-text-left light-blue" style="padding-top: 2px;">
                        <span class="icon is-small is-clickable is-inline-block" @click="removeLogo">
                          <i class="fas fa-trash-alt trash-icon"></i>
                        </span>
                        <p class="is-inline-block" @click="removeLogo">Poista logo</p>
                      </div>
                    </div>
                    <div class="columns mt-5 mb-5 product-options" v-if="this.product && this.product.product.name == 'custom-candy-box'">
                      <div class="column is-6 is-offset-3 has-text-left light-blue" style="padding-top: 2px;">
                        <span class="icon is-small is-clickable is-inline-block" @click="fontFamilyMenuActive=!fontFamilyMenuActive">
                          <i class="fas fa-font"></i>
                        </span>
                        <p class="is-inline-block" @click="fontFamilyMenuActive=!fontFamilyMenuActive">Valitse tekstien fontti</p>
                      </div>
                      <div class="font-selection" v-if="fontFamilyMenuActive">
                        <p
                          role="button"
                          class="dropdown-item has-text-centered"
                          style="font-family: 'Gotham Black'"
                          :class="{ 'is-active': tmpCandyBoxFont === 'F_GOTHAM' }"
                          @click="tmpCandyBoxFont = 'F_GOTHAM'"
                        >
                          Gotham Black
                        </p>
                        <p
                          role="button"
                          class="dropdown-item has-text-centered"
                          style="font-family: 'Tartine Script Black'"
                          :class="{ 'is-active': tmpCandyBoxFont === 'F_TARTINE' }"
                          @click="tmpCandyBoxFont = 'F_TARTINE'"
                        >
                          Tartine Script
                        </p>
                        <p
                          role="button"
                          class="dropdown-item has-text-centered"
                          style="font-family: 'Praho Pro'"
                          :class="{ 'is-active': tmpCandyBoxFont === 'F_PARAHO' }"
                          @click="tmpCandyBoxFont = 'F_PARAHO'"
                        >
                          Praho Pro
                        </p>
                        <p
                          role="button"
                          class="dropdown-item has-text-centered"
                          style="font-family: 'myriadpro-cond'"
                          :class="{ 'is-active': tmpCandyBoxFont === 'F_MYRIADPRO' }"
                          @click="tmpCandyBoxFont = 'F_MYRIADPRO'"
                        >
                          Myriadpro Cond
                        </p>
                        <p
                          role="button"
                          class="dropdown-item has-text-centered"
                          style="font-family: 'helvetica-neue'"
                          :class="{ 'is-active': tmpCandyBoxFont === 'F_HELVETICA' }"
                          @click="tmpCandyBoxFont = 'F_HELVETICA'"
                        >
                          Helvetica Neue
                        </p>
                        <button class="mt-3 mr-1 color-picker-button button is-primary" @click="changeFont()">
                          <span>Valitse fontti</span>
                        </button>
                        <button class="mt-3 mr-1 color-picker-button button is-primary" @click="fontFamilyMenuActive=false">
                          <span>Peruuta</span>
                        </button>
                      </div>
                    </div>
                    <div class="columns mt-5 mb-5 product-options" v-if="this.product && this.product.product.name == 'custom-candy-box'">
                      <div class="column is-6 is-offset-3 has-text-left light-blue" style="padding-top: 2px;">
                        <span class="icon is-small is-clickable is-inline-block color-icon" @click="showBgColorPicker = !showBgColorPicker" :style="candyBoxColorCss">

                        </span>
                        <p class="is-inline-block" @click="showBgColorPicker = !showBgColorPicker">Valitse pakkauksen väri</p>
                        <div class="bg-color-picker-wrapper" v-if=showBgColorPicker>
                          <ColorPicker
                            :color="candyBoxColor || '#44BDC7'"
                            @color-change="updateBgColor"
                            :visible-formats="['hex']"
                            alpha-channel="hide"
                          >
                          </ColorPicker>
                          <ColorPickerFooter :bgColor="candyBoxColor || '#44BDC7'" @cp-confirmButton="changeBgColor()" @cp-cancelButton="showBgColorPicker=false" />
                         </div>
                      </div>
                    </div>
                    <div class="columns mt-5 mb-5 product-options">
                      <div class="column is-6 is-offset-3 has-text-left light-blue" style="padding-top: 2px;">
                        <span class="icon is-small is-clickable is-inline-block" @click="openPreview(product.id)">
                          <i class="fas fa-cube"></i>
                        </span>
                        <p class="is-inline-block" @click="openPreview(product.id)">Katso 3D-kuva valmiista tuotteesta</p>
                      </div>
                    </div>
                    <div id="flavour-selector" v-if="isProductWithFlavor && product.product.flavors.length > 0">
                      <p class="has-text-centered mb-4">Valitse maku</p>
                      <div class="columns is-multiline is-flex">
                        <div v-for="(flavor, idx) in product.product.flavors" class="column is-3 is-6-mobile has-text-centered" v-bind:class="{ 'selected': selectedFlavor==flavor.id }">
                          <label v-bind:class="{ 'selected': selectedFlavor==flavor.id }">
                            <input type="radio" v-model=selectedFlavor :value=flavor.id>
                            <img :src=flavor.thumbnail alt="blue">
                            <p class="has-text-centered pb-2">{{flavor.flavor}}</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <p class="has-text-centered">
                      Jatka ostoskoriin. Voit maksaa tilauksen kassalla normaalisti valitsemallasi maksutavalla. Tuotteen
                      esikatselussa näkyy "Malliteksti", mutta muutamme tähän kohtaan oikeat tekstit kun saamme sinulta
                      tarvittavat tiedot tilauksen jälkeen.
                    </p>
                    <div class="columns is-hidden-mobile">
                      <div class="column is-4">
                        <button
                          class="upload-button button is-primary mt-6"
                          @click="returnPage()"
                        >
                          <span>Takaisin</span>
                        </button>
                      </div>
                      <div class="column is-offset-4 is-4">
                        <button
                          class="upload-button button is-primary mt-6"
                          @click="toCartPageFromAmountTab()"
                        >
                          <span>Jatka</span>
                        </button>
                      </div>
                    </div>
                    <div class="columns is-hidden-tablet has-text-centered">
                      <div class="column is-2 is-offset-4">
                        <button
                          class="upload-button button is-primary mt-6"
                          @click="returnPage()"
                        >
                          <span>Takaisin</span>
                        </button>
                      </div>
                      <div class="column is-2">
                        <button
                          class="upload-button button is-primary mb-4"
                          @click="toCartPageFromAmountTab()"
                        >
                          <span>Jatka</span>
                        </button>
                      </div>
                    </div>

                </div>
              </div>
            </div>
        </div>
        <Footer></Footer>
      </section>
    </div>
</template>

<script>
import MarianneEditor from '@/components/MarianneEditor'
import CustomCandyBoxEditor from "@/components/CustomCandyBoxEditor"
import BarEditor from '@/components/BarEditor'
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import editorFooter from '@/components/editorFooter'
import ThreeDPreview from '@/components/3d/ThreeDPreview'
import ThreeDPreviewMultipleObjects from '@/components/3d/ThreeDPreviewMultipleObjects'
import {mapMutations, mapGetters, mapActions} from 'vuex'
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'
import { ColorPicker } from 'vue-accessible-color-picker'
import ColorPickerFooter from "@/components/ColorPickerFooter"
import LogoEditor from '@/components/LogoEditor'
import canvasImage from '@/components/canvasImage'
import CustomCandyBoxCanvas from '@/components/CustomCandyBoxCanvas.vue'
import asModalDialog from '@/components/helpers/asModalDialog'

let fontMapping = {
  F_GOTHAM: ['Gotham Black', '22px', '30px'],
  F_TARTINE: ['Tartine Script Black', '24px', '30px'],
  F_PARAHO: ['Praho Pro', '24px', '30px'],
  F_MYRIADPRO: ['myriadpro-cond', '24px', '30px'],
  F_HELVETICA: ['helvetica-neue', '30px', '30px'],
}

export default {
  name: 'EditorTabPage',
  components: {
    // editor page section
    SectionHeader,
    BarEditor,
    MarianneEditor,
    CustomCandyBoxEditor,
    editorFooter,
    Footer,
    // product amount page section
    ThreeDPreview,
    ThreeDPreviewMultipleObjects,
    CustomCandyBoxCanvas,
    canvasImage,
    LogoEditor,
    ColorPickerFooter,
    ColorPicker,
    asModalDialog
  },
  mixins: [inputNumberMixin],
  data() {
    return {
      // editor page section
      currentBox: null,
      currentProduct: null,
      editorTabActive: true,
      // amount page section
      preview: {
        gblPath: '',
        textures: [],
        gblVariable: '',
        textureWidth: 0,
        textureHeight: 0,
        fazer: {
          frontAsImageUrl: '',
          backAsImageUrl: '',
          sideAsImageUrl: ''
        },
        marianne: {
          frontAsImageUrl: '',
          backAsImageUrl: ''
        },
        textAsImageUrlCandyBox: '',
        backAsImageUrlCandyBox: ''
      },
      threeDdataMarianne: {
        name: 'Marianne',
        uri: `${process.env.BASE_URL}3d/marianne.glb`,
        objects: [],
        animations: ['FullyOpen', 'PartiallyOpen', 'Closed']
      },
      threeDdataCandyBox: {
        name: 'Candybox',
        uri: `${process.env.BASE_URL}3d/candybox.glb`,
        objects: [],
        animations: ['FullyOpen', 'PartiallyOpen', 'Closed']
      },
      candyBoxColor: '#44BDC7',
      showBgColorPicker: false,
      fontFamilyMenuActive: false,
      candyBoxFont: 'F_GOTHAM',
      tmpCandyBoxFont: 'F_GOTHAM',
      productId: null, // used for custom candy box logo editor modal
      custom_candy_box_logo_url: '',
      threeDeeRenderReady: false,
      product: null,
      selectedFlavor: 1,
      amount: 1,
      fazerGblPath: `${process.env.BASE_URL}3d/fazer-milk-chocolate-bar-v3.glb`,
      publicPath: process.env.BASE_URL,
      fazerPreviewWidth: 1024,
      fazerPreviewHeight: 1167,
      mariannePreviewWidth: 1280,
      mariannePreviewHeight: 960,
    }
  },
  mounted() {
    this.getProducts()
    window.scrollTo(0, 0)
    if (this.getOrderData() == null) {
      this.$router.push('/')
    }
    else {
      this.product = this.$store.getters.getProductById(this.$route.params.uuid)
      if (this.product == undefined) {
        this.product = this.getOrderData().products[0]
      }
      this.changeBox(this.product)

      // order amount page init
      if (this.product.product.name == 'custom-candy-box') {
        this.candyBoxColor = this.product.background_color
        this.candyBoxFont = this.product.font
        this.custom_candy_box_logo_url = this.product?.croppied_logo_url
      }
      this.amount  = this.product.amount

      if (this.product.flavor != null) {
        this.selectedFlavor = this.product.flavor.id
      }
      else if (this.product.product.flavors.length == 0) {
        this.selectedFlavor = null
      } else {
        this.selectedFlavor = this.product.product.flavors[0].id
      }
    }
    this.setSpinnerState(false)
    this.setCartSpinnerState(false)
  },
  computed: {
    threeDdataSetMarianne() {
      return {...this.threeDdataMarianne, objects: [this.textImageTexture, this.backImageTexture]}
    },
    textImageTexture() {
      return {
        id: 1,
        objectName: 'bottom',
        name: 'Bottom',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.marianne.frontAsImageUrl.uri,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    backImageTexture() {
      return {
        id: 2,
        name: 'Top',
        objectName: 'top',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.marianne.backAsImageUrl.uri,
            width: 1257,
            height: 2118,
            rotation: 0,
          }
        ],
      }
    },
    isProductWithFlavor() {
      if (this.product != null && (this.product.product.name == 'bar' || this.product.product.name == 'custom-candy-box')) {
        return true
      }
      return false
    },
    getProductDescription() {
      if (this.product != null) {
        return this.product.product.description
      }
      return ''
    },
    getProductThumbnail() {
      if (this.product != null) {
        return this.product.product.thumbnail
      }
      return ''
    },
    threeDdataSetCandyBox() {
      return {...this.threeDdataCandyBox, objects: [this.textImageTextureCandyBox, this.backImageTextureCandyBox]}
    },
    textImageTextureCandyBox() {
      return {
        id: 1,
        objectName: 'bottom',
        name: 'Bottom',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.textAsImageUrlCandyBox,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    backImageTextureCandyBox() {
      return {
        id: 2,
        name: 'Top',
        objectName: 'top',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.preview.backAsImageUrlCandyBox,
            width: 1257,
            height: 2118,
            rotation: 0,
          }
        ],
      }
    },
    candyBoxColorCss() {
      return {'background-color': this.candyBoxColor}
    },
    fontFamily() {
      if ( this.candyBoxFont && fontMapping[this.candyBoxFont][0] ) {
        return fontMapping[this.candyBoxFont][0]
      } else {
        return this.candyBoxFont
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getProductId: 'getProductId'
    }),
    ...mapActions([
      'postFazerBox',
      'postFazerBar',
      'postMarianneBox',
      'postCustomCandyBox',
      'getProducts',
      'updateOrder'
    ]),
    changeBox(product) {
      if (product.id == this.currentBox) {
        if (this.currentProduct.product.name == 'bar') {
          this.$refs.barEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'marianne') {
          this.$refs.marianneEditor.initEditorState(product.id)
        } else if (this.currentProduct.product.name == 'custom-candy-box') {
          this.$refs.customCandyBoxEditor.initEditorState(product.id)
        }
      }
      this.currentBox = product.id
      this.currentProduct = this.$store.getters.getProductById(this.currentBox)
    },
    toCartPage() {
      this.setSpinnerState(true)
      if (this.currentProduct.product.name == 'bar') {
        this.$refs.barEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'marianne') {
        this.$refs.marianneEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      } else if (this.currentProduct.product.name == 'custom-candy-box') {
        this.$refs.customCandyBoxEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
        }).catch(() => {
          console.log('Product is not ready')
          }
        )
      }
    },
    toCartPageFromAmountTab() {
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      this.product['amount'] = this.amount
      this.product['draft'] = false
      this.product['csv_status'] = 'NEED_CSV'
      if (this.product.product.name == 'bar' || this.product.product.name == 'custom-candy-box') {
        this.product['flavor'] = this.selectedFlavor
      }
      if (this.product.product.name == 'custom-candy-box') {
        this.product['background_color'] = this.candyBoxColor
        this.product['font'] = this.candyBoxFont
        this.loadTexturesCandyBox()
          .then(() => {
            this.loadEmptyTexturesCandyBox()
              .then(() => {
                this.updateOrder({'products': [this.product]})
                    .then(() => {
                      this.setSpinnerState(false)
                      this.setCartSpinnerState(false)
                      this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
                    })
              })
          })
      } else {
        this.updateOrder({'products': [this.product]})
            .then(() => {
              this.setSpinnerState(false)
              this.setCartSpinnerState(false)
              this.$router.push({name: 'cart', params: {uuid: this.getOrderData().id}})
            })
      }
    },
    goToEditor() {
      let self = this
      self.setSpinnerState(true)
      self.setCartSpinnerState(true)
      let data = {}
      let order_id = self.$store.state.order.id
      if (order_id != undefined) {
        data['order_id'] = order_id
      }

      if (this.currentProduct.product.name == 'bar') {
        this.$refs.barEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('bar')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postFazerBar(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor-tab', params: {uuid: self.$store.state.postFazerBarData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
      else if (this.currentProduct.product.name == 'marianne') {
        this.$refs.marianneEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('marianne')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postMarianneBox(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor-tab', params: {uuid: self.$store.state.postMarianneBoxData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
      else if (this.currentProduct.product.name == 'custom-candy-box') {
        this.$refs.customCandyBoxEditor.saveBox().then(() => {
          this.setSpinnerState(false)
          data['product'] =  this.$store.getters.getProductId('custom-candy-box')
          data['draft'] = true
          data['type'] = 'ALL_TO_SAME_ADDRESS'
          data['csv_status'] = 'NO_CSV_NEEDED'
          this.postCustomCandyBox(data).then(() => {
            self.setSpinnerState(false)
            self.setCartSpinnerState(false)
            self.$router.push({name: 'editor-tab', params: {uuid: self.$store.state.postCustomCandyBoxData.last_product}}).then(() => {
              window.location.reload()
            })
          })
        })
      }
    },
    saveBox() {
      if (this.currentProduct.product.name == 'bar') {
        this.$refs.barEditor.saveBox()
      } else if (this.currentProduct.product.name == 'marianne') {
        this.$refs.marianneEditor.saveBox()
      } else if (this.currentProduct.product.name == 'custom-candy-box') {
        this.$refs.customCandyBoxEditor.saveBox()
      }
    },
    switchTab() {
      this.editorTabActive = !this.editorTabActive
    },

    // order amount page methods
    returnPage() {
      this.$router.go(-1)
    },
    async loadTexturesCandyBox() {
      let self = this
      return self.$refs.customCandyBoxCanvasPreview.draw().then(() => { // draw placeholder texts
        self.preview.backAsImageUrlCandyBox = self.$refs.customCandyBoxCanvasPreview.$refs.frontCanvas.toDataURL('image/jpeg', 0.2)
        self.preview.textAsImageUrlCandyBox = self.$refs.customCandyBoxCanvasPreview.$refs.backCanvas.toDataURL('image/jpeg', 0.2)
      })
    },
    async loadEmptyTexturesCandyBox() {
      let self = this
        return self.$refs.customCandyBoxCanvas.draw().then(() => { // draw actual no text textures for printing
          self.product.back_side_picture = self.$refs.customCandyBoxCanvas.$refs.titleBoxCanvas.toDataURL('image/jpeg', 0.9)
          self.product.front_side_picture = self.$refs.customCandyBoxCanvas.$refs.textBoxCanvas.toDataURL('image/jpeg', 0.9)
        })
    },
    openPreview(productId) {
      let self = this
      this.previewId = productId
      if(this.product.product.name == 'marianne')  {
        self.preview.marianne.frontAsImageUrl = {
          uri: this.product.product.json_data.marianne_card,
          height: 435,
          width: 1085,
          rotation: 0,
        }
        self.preview.marianne.backAsImageUrl = {
          uri: this.product.product.json_data.marianne_cover,
          height: 435,
          width: 1280,
          rotation: 0,
        }
        self.preview.gblPath = self.marianneGblPath
        self.preview.textures = [self.preview.marianne.backAsImageUrl, self.preview.marianne.sideAsImageUrl, self.preview.marianne.frontAsImageUrl]
        self.preview.gblVariable = 'Sleeve'
        self.preview.textureWidth = self.mariannePreviewWidth
        self.preview.textureHeight = self.mariannePreviewHeight
        return self.$refs.mariannePreview.showModal(this.product.product.name)
      } else if(this.product.product.name == 'bar') {
        let textTexture = {
          uri: this.product.product.json_data.back_texture,
          height: 399,
          width: 1024,
          rotation: 0,
        }
        let coverTexture = {
          uri: this.product.product.json_data.front_texture,
          height: 399,
          width: 1024,
          rotation: 0,
        }
        this.$refs.smallSideCanvas.getImageAsDataUrl().then(function (dataUrl) {
          let smallSideAsImageUrl = dataUrl
          self.$refs.bigSideCanvas.getImageAsDataUrl().then(function (dataUrl) {
            let bigSideAsImageUrl = dataUrl
            let smallSideImageTexture = {
              uri: smallSideAsImageUrl,
              height: 61,
              width: 1024,
              rotation: 0,
            }
            let bigSidebackImageTexture = {
              uri: bigSideAsImageUrl,
              height: 300,
              width: 1024,
              rotation: 0,
            }
            self.preview.gblPath = self.fazerGblPath
            self.preview.textures = [bigSidebackImageTexture, coverTexture, smallSideImageTexture, textTexture ]
            self.preview.gblVariable = 'Overlay'
            self.preview.textureWidth = self.fazerPreviewWidth
            self.preview.textureHeight = self.fazerPreviewHeight
          })
        })
        return self.$refs.previewModal.showModal()
      } else if(this.product.product.name == 'custom-candy-box') {
        this.threeDeeRenderReady = false
        this.setSpinnerState(true)
        this.loadTexturesCandyBox()
        .then(() => {
          return self.$refs.customCandyBoxPreview.showModal()
        })
        .then((amount, ...args) => {
          console.log('3d preview closed', args)
        })
        .catch((error) => {
          console.log('3d preview closed', error)
        })
      }
    },
    increaseAmount() {
      this.amount += 1
      this.product['amount'] = this.amount
    },
    decreaseAmount() {
      if (this.amount > 1) {
        this.amount -= 1
      }
      this.product['amount'] = this.amount
    },
    changedAmount() {
      this.product['amount'] = this.amount
    },
    updateBgColor(eventData) {
      this.candyBoxColor = eventData.cssColor
    },
    changeBgColor() {
      this.showBgColorPicker = false
    },
    changeFont() {
      this.candyBoxFont = this.tmpCandyBoxFont
      this.fontFamilyMenuActive = false
    },
    changeLogo() {
      let self = this
      this.productId = this.product.id
      this.$refs.logoEditorModal
        .showModal()
        .then(() => {
          self.product = self.$store.getters.getProductById(self.productId)
          self.custom_candy_box_logo_url = self.product.croppied_logo_url
        })
        .catch(() => {
          console.log('error')
        })
    },
    removeLogo() {
      if (this.product.original_logo_url) {
        this.setSpinnerState(true)
        let boxData = {
          id: this.productId,
          logo: '',
          logo_croppie_points: null,
          logo_croppie_zoom: null,
          logo_croppie_orientation: null,
          original_logo: ''
        }
        this.$store
          .dispatch('partialUpdateCustomCandyBox', boxData)
          .then(() => {
            this.product = this.$store.getters.getProductById(this.productId)
            this.custom_candy_box_logo_url = ''
            this.setSpinnerState(false)
          })
          .catch(() => {
            this.setSpinnerState(false)
          })
      }
    },
    threeDeerenderReady() {
      this.threeDeeRenderReady = true
      this.setSpinnerState(false)
    }
  },
  watch: {
  '$route.params.uuid': {
    handler: function(search) {
      let product = this.$store.getters.getProductById(this.$route.params.uuid)
      if (this.currentProduct != null && product != undefined) {
        if (this.currentProduct.product.name != product.product.name) {
          this.changeBox(product)
        }
      }
    },
    deep: true,
    immediate: true
  }
}
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

.hero-body {
  background-color: #EBEFF5;
  .container {
    background-color: #EBEFF5;
  }
}

@media screen and (min-width: 769px) {
  .margin-l-desktop-2 {
    margin-left: 2%;
  }
}

p.editor-tab {
  font-size: 15px;
  color: $primary;
  border-bottom: 1px solid black;
  background-color: #EBEFF5;
  text-align: center;
  @media screen and (max-width: 630px) {
    height: 43px;
  }
  span {
    @media screen and (min-width: 1700px) {
      margin-right: 1.5rem;
    }
  }
}

p.editor-tab.is-editor-tab-active {
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: none;
  font-weight: bold;
  background-color: white;
  text-decoration: none;
}

p.editor-tab:hover {
  cursor: pointer;
}

// order amount tab styles

@media screen and (min-width: 769px) {
  .margin-l-desktop-2 {
    margin-left: 2%;
  }
}

.amount-options {
  .svg-wrapper {
    display: inline-block;

    svg {
      font-size: 25px;
      top: 3px;
      position: relative;
      color: $primary;
    }

    svg:hover {
      cursor: pointer;
      color: $primary;
    }
  }

  span {
    width: 70px;
    display: inline-block;
    border: 1px solid gray;
    margin-left: 10px;
    margin-right: 10px;
  }
  input {
    margin-left: 10px;
    margin-right: 10px;
    width: 70px;
    border: 1px solid black;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .amount-options {
    input {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}

// Remove arrows on amount input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#flavour-selector {
  border: 1px solid #DEDBD9;
  padding: 20px;
  margin-bottom: 40px;

  label {
    p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  img {
    padding: 20px;
  }

  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type=radio]:checked + img {
    background-color: #EBEFF5;
  }
  .selected {
    background-color: #EBEFF5;
  }
  label.selected {
    background-color: #EBEFF5;
    p {
      background-color: #EBEFF5;
    }
  }
}

.light-blue {
  background-color: #EBEFF5;
}

.product-options {
  p {
    font-size: 15px;
    margin-left: 15px;
    text-decoration: underline;
    color: #15256b;
  }
  p:hover {
    cursor: pointer;
  }
  .icon {
    font-size: 25px;
    position: relative;
    top: 4px;
  }
}

.bg-color-picker-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid gray;
  background-color: white;
  z-index: 99;
}

.font-selection {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid gray;
  background-color: white;
  z-index: 99;

  p {
    font-size: 18px;
    text-decoration: none;
  }

  p.is-active {
    background-color: #15256B;
    color: white;
  }

  span.is-medium-important {
    font-size: 1.8rem !important;
  }
}

.circle {
  border: 1px solid #707070;
  height: 16px;
  border-radius: 50%;
  width: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.full-circle {
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 50%;
  background-color: $primary;
  display: inline-block;
  position: relative;
  top: 3px;
}

</style>
