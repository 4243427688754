<template>
  <div class="editor-w">
    <div class="columns"></div>
    <div class="columns is-flex-touch is-flex-direction-column-touch is-align-items-center">
      <div class="column is-6 col-left-button">
        <div class="logo-file">
          <label class="file-label">
            <input class="file-input" type="file" name="resume" ref="file" @change="croppieChange" accept=".png,.jpg,.jpeg" />
            <button class="button logo-button is-primary is-fullwidth" @click="$refs.file.click()">
              <p class="has-text-centered is-fullwidth">
                Lataa logo
              </p>
            </button>
          </label>
        </div>
      </div>
      <div class="column is-6 col-right-button">
        <button class="button logo-button is-primary is-fullwidth" @click="saveLogo" :disabled="!logo_loaded">
          <p class="has-text-centered is-fullwidth">
            OK
          </p>
        </button>
      </div>
    </div>
    <div class="columns is-mobile logo-croppie">
      <div class="column is-12">
        <div class="croppieBoundary is-hidden-touch" v-if="isDesktop">
          <vue-croppie
            ref="croppieRef"
            :boundary="{ width: 500, height: 500 }"
            :viewport="{ ...metadata.desktop.viewport, type: 'square'}"
            :enableOrientation="true"
            :enableExif="true"
            :showZoomer="true"
            :enableResize="false"
          >
          </vue-croppie>
        </div>
        <div class="croppieBoundary is-hidden-desktop" v-if="!isDesktop">
          <vue-croppie
            ref="croppieRef"
            :boundary="{ width: 260, height: 260 }"
            :viewport="{ ...metadata.noDesktop.viewport, type: 'square' }"
            :enableOrientation="true"
            :enableExif="true"
            :showZoomer="true"
            :enableResize="false"
          >
          </vue-croppie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCroppieComponent from '@/components/helpers/VueCroppieComponent'

import {mapMutations} from 'vuex'

require('style-loader!../../node_modules/croppie/croppie.css')

export default {
  name: 'LogoEditor',
  props: {
    mutation: {
      type: String
    },
    metadata: {
      type: Object,
      default: {
        desktop: {
          viewport: {
            width: 300,
            height: 300,
          }
        },
        noDesktop: {
          viewport: {
            width: 250,
            height: 250,
          }
        }
      }
    },
    options: {
      type: Object,
      default: {
        size: {
          width: 400,
          height: 400
        },
      }
    },
    productIdProp: String
  },
  data() {
    return {
      box: null,
      croppieImage: '',
      cropped: null,
      logo_loaded: false,
      error: '',
      croppie_zoom: 1,
      editing_logo: false,
      productId: ''
    }
  },
  components: {
    'vue-croppie': VueCroppieComponent
  },
  mounted() {
    if (this.productIdProp != undefined) {
      this.productId = this.productIdProp
    } else {
      this.productId = this.$parent.$parent.productId
    }
    this.box = this.$store.getters.getProductById(this.productId )
    if (this.box.original_logo_url) {
      this.bindCroppieImage()
      this.logo_loaded = true
      this.editing_logo = true
    }
  },
  emits: ['modal-result-ok', 'modal-result-cancel'],
  computed: {
    isDesktop() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      return vw >= 1024
    }
  },
  methods: {
    ...mapMutations(['setSpinnerState']),
    croppieChange(e) {
      let self = this
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      let reader = new FileReader()
      reader.onload = (e) => {
        self.$refs.croppieRef.bind({
          url: e.target.result,
        })
      }
      reader.readAsDataURL(files[0])
      this.logo_loaded = true
      this.editing_logo = false
    },
    saveLogo() {
      this.setSpinnerState(true)
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let croppie_options = {
        type: 'base64',
        ...this.options,
        format: 'png',
      }
      this.$refs.croppieRef.result(croppie_options, (output) => {
        this.cropped = this.croppieImage = output

        let croppieInst = this.$refs.croppieRef
        const { orientation, points, zoom } = croppieInst.get()

        let boxData = {
          id: this.productId,
          logo: this.croppieImage,
          logo_croppie_points: JSON.stringify(points),
          logo_croppie_zoom: zoom,
          logo_croppie_orientation: orientation,
        }

        if (!this.editing_logo) {
          boxData['original_logo'] = this.$refs.croppieRef.croppie.data.url
        }
        this.$store
          .dispatch(this.mutation, boxData)
          .then(() => {
            this.$parent.answerOK()
            this.setSpinnerState(false)
          })
          .catch(() => {
            this.$parent.answerNAY()
            this.setSpinnerState(false)
          })
      })
    },
    bindCroppieImage() {
      // we need 1 render cycle to change the parameters of vue-croppie
      // poins is stored as json string in backend
      const points = JSON.parse(this.box.logo_croppie_points)
      if (this.box.logo_croppie_zoom == null) {
        this.box.logo_croppie_zoom = this.croppie_zoom
      }
      this.croppie = this.$refs.croppieRef.bind({
        url: this.box.original_logo_url,
        points: points,
        zoom: this.box.logo_croppie_zoom,
      })
      this.$nextTick().then(() => {
        //console.log('this', this.$refs.croppieRef)
        this.croppie_zoom = this.logo_croppie_zoom
      })
    },
  },
}
</script>

<style lang="scss">
@import '../assets/sass/mybulma';

.is-flex-direction-column-touch {
  @media screen and (max-width: 1023px) {
    flex-direction: column !important;
  }
}

.logo-croppie {
  .croppieBoundary {
    min-height: 300px;
    padding-bottom: 60px;
  }
  .cr-viewport {
    z-index: 1000 !important;
  }
  .cr-overlay {
    z-index: 1001 !important; // should be higher than cr-viewport otherwise user can't move inside viewport
  }  
}

.col-left-button {
  @media screen and (min-width: 1024px) {
    padding-left: 62px;
  }

}

.col-right-button {
  @media screen and (min-width: 1024px) {
    padding-right: 62px;
  }
}

.logo-file {
  display: block !important;
  @media screen and (max-width: 768px) {
    width: 260px;  
  }
}

.logo-button {
  z-index: 100; // to show hover effect
  border-radius: 20px !important;
  padding-bottom: 10px !important;
  p {
    color: white;
    font-family: gill-sans-nova;
  }
  @media screen and (max-width: 768px) {
    width: 260px !important;
  }  
}

</style>
