<template>
  <div class="editor" ref="fazerEditorRefWidth">
    <asModalDialog
      heading-text="3d esikatselu"
      :show-ok="false"
      :show-cancel="false"
      width="80%"
      min-width="500px"
      ref="previewModal"
    >
      <ThreeDPreview
        texture-width=1024
        texture-height=1167
        material-name="Overlay"
        :textures="[bigSidebackImageTexture, textImageTexture, smallSideImageTexture, backImageTexture]"
        :texture-uri="textureUri"
      />
    </asModalDialog>

    <div class="columns mt-6">
      <div class="column is-4">
        <div class="remove-logo" @click="previewModal()">
          <i class="fas fa-2x fa-cube"></i>
          <p class="underline">Katso 3D-kuva valmiista tuotteesta</p>
        </div>
      </div>
    </div>

    <canvas id="rotateText" width=1085 height=435 class="is-hidden"></canvas>
    <canvas id="rotateTitle" width=1085 height=435 class="is-hidden"></canvas>
    
    <canvas-image
      ref="frontCanvas"
      :image-width=1024
      :image-height=399
      :canvas-text="product.title"
      :options="{
        backgroundColor: 'transparent',
        color: '#c69b67',
        font: 'Karl Fazer',
        fontSize: '124px',
        lineHeight: '124px',
        translate: [-40, 110],
        rotate: -11.5
      }"
    />

    <canvas-image
      ref="backCanvas"
      :image-width=1024
      :image-height=399
      :canvas-text="product.text"
      :canvas-signature="product.signature"
      :options="{
        backgroundColor: 'transparent',
        color: '#c69b67',
        font: 'PT Sans Narrow',
        fontSize: '18px',
        lineHeight: '18px',
        product: 'bar'
      }"
    />

    <canvas-image
      ref="smallSideCanvas"
      :image-width=1024
      :image-height=61
      :options="{
        backgroundColor: 'transparent'
      }"
    />

    <canvas-image
      ref="bigSideCanvas"
      :image-width=1024
      :image-height=300
      :options="{
        backgroundColor: 'transparent'
      }"
    />

    <div class="columns is-mobile mt-0 mb-0">
      <div class="column pt-0 pb-0">
        <div id="front-editor-wrapper" ref="editorWrapper">
          <div class="column is-fullwidth">
            <div
              v-if="$refs.fazerEditorRefWidth"
              class="text-preview columns is-vcentered is-flex is-justify-content-center is-align-items-center is-mobile"
              :style="frontPreviewCss"
              id="text-box-wrapper-preview"
            >
              <p v-html="title" class="is-primary" id="title-box-preview" :style="frontFontPreviewCss"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile mt-0">
      <div class="column pt-0">
        <div id="back-editor-wrapper" ref="editorWrapper">
          <div class="column is-fullwidth">
            <div
              v-if="$refs.fazerEditorRefWidth"
              class="text-preview columns is-vcentered is-flex is-justify-content-center is-align-items-center is-mobile"
              :style="backPreviewCss"
              id="signature-box-wrapper-preview"
            >
              <p v-html="text" class="is-primary" id="text-box-preview" :style="messageFontPreviewCss"></p>
              <p v-html="signature" class="is-primary" id="signature-box-preview" :style="backFontPreviewCss"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <div class="column is-one-third is-hidden-mobile has-text-left">
        <p><span class="has-text-weight-bold has-text-left editor-span-left">Pakkauksen teksti</span></p>
        <p class="mb-3"><span class="has-text-weight-bold has-text-left editor-span-left">Etukansi / otsikko</span></p>
      </div>
      <div class="column is-two-thirds is-hidden-mobile" v-bind:class="{ 'is-danger-border': titleEmpty }">
        <TextEditor
          :notext="false"
          :maxRows="1"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }"
          @inputtextarea="inputTitle"
          :maxCharactersPerRow="16"
          :producttext="product.title"
          name="text-editor-title-desktop"
          ref="titleEditor">
        </TextEditor>
        <span v-if="titleEmpty" style="color: red;">Pakollinen kenttä</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0 mb-3" v-bind:class="{ 'is-danger-border': titleEmpty }">
        <p><span class="has-text-weight-bold has-text-centered editor-span-left">Pakkauksen teksti</span></p>
        <p class="mb-3"><span class="has-text-weight-bold has-text-centered editor-span-left">Etukansi / otsikko</span></p>
        <TextEditor
          :notext="false"
          :maxRows="1"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }"
          @inputtextarea="inputTitle"
          :maxCharactersPerRow="16"
          :producttext="product.title"
          name="text-editor-title-mobile"
          ref="titleEditor"
        >
        </TextEditor>
        <span v-if="titleEmpty" style="color: red;">Pakollinen kenttä</span>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <span class="has-text-weight-bold has-text-left is-hidden-tablet editor-span-left">Takakansi / viesti</span>
      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
          <input
            class="is-checkradio is-secondary"
            id="noTextRadioMobile"
            type="checkbox"
            name="noTextRadioMobile"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label class="checkradio-label" for="noTextRadioMobile">Ei tekstiä</label>
      </div>
      <div class="column is-one-third has-text-left is-hidden-mobile">
        <span class="has-text-weight-bold has-text-left editor-span-left">Takakansi / viesti</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="noTextRadioDesktop"
            type="checkbox"
            name="noTextRadioDesktop"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label for="noTextRadioDesktop">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile" v-bind:class="{ 'is-danger-border': textEmpty }">
        <TextEditor
          :notext="product.notext"
          :maxRows="6"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '130px !important' }"
          @inputtextarea="inputTextarea"
          :maxCharactersPerRow="50"
          :producttext="product.text"
          name="text-editor-text-desktop"
          ref="textEditor">
        </TextEditor>
        <span v-if="textEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0" v-bind:class="{ 'is-danger-border': textEmpty }">
        <TextEditor
          :notext="product.notext"
          :maxRows="6"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '130px !important' }"
          @inputtextarea="inputTextarea"
          :maxCharactersPerRow="50"
          :producttext="product.text"
          name="text-editor-text-mobile"
          ref="textEditor"
        >
        </TextEditor>
        <span v-if="textEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <span class="has-text-weight-bold has-text-left is-hidden-tablet editor-span-left">Takakansi / allekirjoitus</span>
      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
          <input
            class="is-checkradio is-secondary"
            id="noSignatureRadioMobile"
            type="checkbox"
            name="noSignatureRadioMobile"
            v-model="product.nosignature"
            @click="toggleNoSignature()"
          />
          <label class="checkradio-label" for="noSignatureRadioMobile">Ei tekstiä</label>
      </div>
      <div class="column is-one-third has-text-left is-hidden-mobile">
        <span class="has-text-weight-bold has-text-left editor-span-left">Takakansi / allekirjoitus</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="noSignatureRadioDesktop"
            type="checkbox"
            name="noSignatureRadioDesktop"
            v-model="product.nosignature"
            @click="toggleNoSignature()"
          />
          <label for="noSignatureRadioDesktop">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile" v-bind:class="{ 'is-danger-border': signatureEmpty }">
        <TextEditor
          :notext="product.nosignature"
          :maxRows="1"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }"
          @inputtextarea="inputSignature"
          :maxCharactersPerRow="30"
          :producttext="product.signature"
          name="text-editor-signature-desktop"
          ref="signatureEditor"
        >
        </TextEditor>
        <span v-if="signatureEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0 mb-3" v-bind:class="{ 'is-danger-border': signatureEmpty }">
        <TextEditor
          :notext="product.nosignature"
          :maxRows="1"
          :fontStyle="{ 'font-family': 'PT Sans Narrow', 'height': '35px !important' }"
          @inputtextarea="inputSignature"
          :maxCharactersPerRow="30"
          :producttext="product.signature"
          name="text-editor-signature-mobile"
          ref="signatureEditor"
        >
        </TextEditor>
        <span v-if="signatureEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
    </div>

    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <div class="column is-4 has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
      </div>
      <div class="column is-8 is-offset-2-mobile has-text-left">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input type="number" @keypress="preventNonNumericalInput(event)" min="0" @change="changedAmount()" v-model.number="product.amount" class="input has-text-centered" />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="columns mt-6" style="position: relative">
      <div class="column is-12 has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Valitse maku</span>
      </div>
    </div>
    <div class="columns mt-3 is-mobile" style="position: relative" v-if="flavors.length > 0">
      <div class="column is-12">
        <div id="flavour-selector">
          <div class="columns is-multiline is-flex">
            <div v-for="(flavor, idx) in flavors" class="column is-3 is-6-mobile">
              <label v-bind:class="{ 'selected': product.flavor==flavor.id }">
                <input type="radio" v-model=product.flavor :value=flavor.id>
                <img :src=flavor.thumbnail alt="blue">
                <p class="has-text-centered pb-2">{{flavor.flavor}}</p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor'
import getSize from 'element-size'
import debounce from 'lodash/debounce'
import asModalDialog from '@/components/helpers/asModalDialog'
import ThreeDPreview from '@/components/3d/ThreeDPreview'
import canvasImage from '@/components/canvasImage'
import {mapMutations, mapGetters, mapActions} from 'vuex'
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'

export default {
  name: 'BarEditor',
  props: {
    productId: String
  },
  components: {
    TextEditor,
    asModalDialog,
    ThreeDPreview,
    canvasImage
  },
  mixins: [inputNumberMixin],
  data() {
    return {
      textureUri: `${process.env.BASE_URL}3d/fazer-milk-chocolate-bar-v3.glb`,
      fazerEditorWidth: 657,
      fazerEditorHeight: 263,
      temporalText: '',
      product: {
        notext: false,
        nosignature: false,
        amount: 1,
        flavor: 1,
        id: null,
        text: '',
        title: '',
        signature: '',
        front_side_picture: '',
        back_side_picture: '',
        preview_texture: ''
      },
      element_zoom: 0, // text preview element zoom
      Url: null,
      textAsImageUrl: '',
      backAsImageUrl: '',
      smallSideAsImageUrl: '',
      bigSideAsImageUrl: '',
      editorScale: 1,
      titleEmpty: false,
      textEmpty: false,
      signatureEmpty: false,
      flavors: []
    }
  },

  mounted() {
    this.initEditorState(this.productId)
    this.flavors = this.product.product.flavors
    if (this.flavors.length == 0) { // default to empty flavor
      this.product.flavor = null
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.handleTextPreviewResize()
      this.onResize()
    })
    if(!this.product.draft) {
      if(!this.product.signature && !this.product.nosignature) this.toggleNoSignature()
      if(!this.product.text && !this.product.notext) this.toggleNoText()
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    textImageTexture() {
      return {
        uri: this.textAsImageUrl,
        height: 399,
        width: 1024,
        rotation: 0,
      }
    },
    backImageTexture() {
      return {
        uri: this.backAsImageUrl,
        height: 399,
        width: 1024,
        rotation: 0,
      }
    },
    smallSideImageTexture() {
      return {
        uri: this.smallSideAsImageUrl,
        height: 61,
        width: 1024,
        rotation: 0,
      }
    },
    bigSidebackImageTexture() {
      return {
        uri: this.bigSideAsImageUrl,
        height: 300,
        width: 1024,
        rotation: 0,
      }
    },
    text() {
      if (this.product.text == '' && !this.product.notext) {
        return 'Kirjoita teksti'
      } else {
        return this.product.text.replace(/\n/g, '<br>')
      }
    },
    signature() {
      if (this.product.signature == '' && !this.product.nosignature) {
        return 'Kirjoita teksti'
      } else {
        return this.product.signature.replace(/\n/g, '<br>')
      }
    },
    title() {
      if (this.product.title == '') {
        return 'Kirjoita teksti'
      } else {
        return this.product.title.replace(/\n/g, '<br>')
      }
    },
    viewportWidthHeight() {
      return {
        width: this.fazerEditorWidth * this.editorScale,
        height: this.fazerEditorHeight * this.editorScale,
      }
    },
    frontPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        'font-family': 'Karl Fazer',
        'font-size': '116px',
        'line-height': '116px',
        'color': '#c69b67 !important',
        'transform': 'rotate(-11.5deg)',
      })
      return Object.assign(styles, {
        height: `${this.fazerEditorHeight * this.editorScale}px`,
        width: `${this.fazerEditorWidth * this.editorScale}px`,
      })
    },
    frontFontPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        transform: 'scale(' + this.element_zoom + ')',
        'max-width': this.fazerEditorWidth + 'px',
        'min-width': this.fazerEditorWidth + 'px',
        'overflow': 'hidden',
        'white-space': 'nowrap',
        'height': '130px',
        'color': '#c69b67 !important',
        'font-size': '100pt',
      })
      return styles
    },
    messageFontPreviewCss() {
      let styles = {}
      let width = document.documentElement.clientWidth
      let top = 20
      if (width >= 1704) {
        top = 130
      } else if (width >= 600 && width < 1704) {
        top = 80
      } else if (width < 400) {
        top = 0
      }
      styles = Object.assign(styles, {
        transform: 'scale(' + this.element_zoom + ')',
        'max-width': this.fazerEditorWidth + 'px',
        'color': '#c69b67 !important',
        'font-family': 'PT Sans Narrow',
        'font-size': '15pt',
        'line-height': '1.1',
        'position': 'absolute',
        'top': top + 'px',
        'max-height': '130px !important',
        'height': '130px'
      })
      return styles
    },
    backPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        'font-family': 'Karl Fazer',
        'font-size': '24pt',
        'color': '#c69b67 !important',
        'position': 'relative'
      })
      return Object.assign(styles, {
        height: `${this.fazerEditorHeight * this.editorScale}px`,
        width: `${this.fazerEditorWidth * this.editorScale}px`,
      })
    },
    backFontPreviewCss() {
      let styles = {}
      let width = document.documentElement.clientWidth
      let top = 130
      if (width >= 1704) {
        top = 300
      } else if (width >= 600 && width < 1704) {
        top = 210
      } else if (width < 400) {
        top = 95
      }
      styles = Object.assign(styles, {
        transform: 'scale(' + this.element_zoom + ')',
        'max-width': this.fazerEditorWidth + 'px',
        'color': '#c69b67 !important',
        'font-size': '28px',
        'line-height': '28px',
        'position': 'absolute',
        'top': top + 'px'
      })
      return styles
    },
    sideFontPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        transform: 'scale(' + this.element_zoom + ')',
        'max-width': this.fazerEditorWidth + 'px',
        'color': '#c69b67 !important',
        'font-size': '40pt',
        'white-space': 'nowrap',
      })
      return styles
    },
    textareaCharacters() {
      return this.product.text.length
    },
    editorHeightResizer() {
      if (this.$refs.editorWrapper != null) {
        return this.$refs.editorWrapper.clientHeight
      }
      return this.fazerEditorHeight
    },
  },
  watch: {
    productId: function (newId, oldId) {
      if (newId === oldId) {
        return
      }
      this.setSpinnerState(true)
      const previousBox = JSON.parse(JSON.stringify(this.product)) // copy of old box, drops proxies
      this.saveBox(previousBox)
        .then(() => {
          this.initEditorState(this.productId)
        })
        .catch((error) => {
          console.log('error saving box', error)
        })
        .finally(() => {
          this.setSpinnerState(false)
        })
    },
    temporalText: function (val) {
      val = val.split('\n').slice(0, 3).join('\n')

      if (!this.product.notext) {
        this.product.text = val
      }
    },
    // generate text textures on text change
    'product.text'() {
      this.loadTextures()
        .then(() => {
          console.log("loaded textures")
        })
    },
    'product.signature'() {
      this.loadTextures()
        .then(() => {
          console.log("loaded textures")
        })
    },
    'product.title'() {
      this.loadTextures()
        .then(() => {
          console.log("loaded textures")
        })
    },
  },
  methods: {
    ...mapActions(['updateOrder']),
    ...mapMutations([
      'setSpinnerState',
      'updateProductAmount'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData'
    }),
    onResize() {
      if (this.$refs.fazerEditorRefWidth) {
        let editor = this.$refs.fazerEditorRefWidth
        let size = getSize(editor) // uncomment this to enable zoom support
        this.editorScale = size[0] / this.fazerEditorWidth
      }
      this.handleTextPreviewResize()
    },
    async loadTextures() {
      let self = this
      this.$refs.frontCanvas.getImageAsDataUrl().then(function (dataUrl) {
        self.textAsImageUrl = dataUrl
        self.product.front_side_picture = dataUrl
      })
      this.$refs.backCanvas.getImageAsDataUrl().then(function (dataUrl) {
        self.backAsImageUrl = dataUrl
        self.product.back_side_picture = dataUrl
      })
      this.$refs.smallSideCanvas.getImageAsDataUrl().then(function (dataUrl) {
        self.smallSideAsImageUrl = dataUrl
      })
      this.$refs.bigSideCanvas.getImageAsDataUrl().then(function (dataUrl) {
        self.bigSideAsImageUrl = dataUrl
      })
    },
    previewModal() {
      // take snapshot of image and text as it is and show 3d preview
      let self = this
      this.loadTextures()
        .then(() => {
          return self.$refs.previewModal.showModal()
        })
        .then((amount, ...args) => {
          console.log('3d preview closed', args)
        })
        .catch((error) => {
          console.log('3d preview closed', error)
        })
    },
    initEditorState(productId) {
      let product = this.$store.getters.getProductById(productId)
      this.product = product
      if (this.product.flavor == null) {
          this.product.flavor = 1
      } else {
        this.product.flavor = this.product.flavor.id
      }
      this.temporalText = this.product.text
    },
    result(output) {
      this.cropped = output
    },
    increaseAmount() {
      this.product.amount += 1
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
    },
    decreaseAmount() {
      if (this.product.amount > 1) {
        this.product.amount -= 1
      }
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
    },
    changedAmount() {
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
    },
    toggleNoText() {
      this.product.notext = !this.product.notext
      if (this.product.notext) {
        this.product.text = ''
      } else {
        this.product.text = this.temporalText
      }
    },
    toggleNoSignature() {
      this.product.nosignature = !this.product.nosignature
      if (this.product.nosignature) {
        this.product.signature = ''
      } else {
        this.product.signature = this.temporalText
      }
    },
    saveBox(productObject) {
      this.titleEmpty = false
      this.textEmpty = false
      this.signatureEmpty = false
      let scrollToError = ''
      if (this.product.title == '') {
        this.titleEmpty = true
        scrollToError = 'text-editor-title'
      }
      if (this.product.text == '' && !this.product.notext) {
        this.textEmpty = true
        if (!scrollToError) scrollToError = 'text-editor-text'
      }
      if (this.product.signature == '' && !this.product.nosignature) {
        this.signatureEmpty = true
        if (!scrollToError) scrollToError = 'text-editor-signature'
      }
      if (this.titleEmpty || this.signatureEmpty || this.textEmpty) {
        this.setSpinnerState(false)
        if(scrollToError) {
          document.getElementById(`${scrollToError}-desktop`).scrollIntoView({ block: "center", behavior: "smooth" });
          document.getElementById(`${scrollToError}-mobile`).scrollIntoView({ block: "center", behavior: "smooth" });
        }        
        return Promise.reject('Check product')
      }
      else {
        return this.loadTextures()
            .then(() => {
              if (productObject == null) productObject = this.product
              productObject['draft'] = false
              productObject['type'] = 'ALL_TO_SAME_ADDRESS'
              return this.updateOrder({'products': [productObject]})
            })
      }
    },
    handleTextPreviewResize: debounce(function () {
      let size = document.querySelector('.text-preview').offsetWidth
      this.element_zoom = size / 900
    }, 300),
    inputTextarea(event) {
      this.product.text = event
    },
    inputTitle(event) {
      this.product.title = event
    },
    inputSignature(event) {
      this.product.signature = event
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

$total-width: 1110px;
$original-width: 4 00px;
$switch-background: #726f6f;
$switch-background-active: #726f6f;

.editor {
  @media (max-width: 500px) {
    margin-top: 20px;
  }
  span {
    font-size: 18px;
    @media (max-width: 400px) {
      font-size: 12px;
    }
    font-family: gill-sans-nova;
    color: $primary;
  }

  label {
    font-size: 18px;
    font-family: gill-sans-nova;
    color: $primary;
  }

  .switch[type='checkbox'].is-rounded + label::before {
    border: 1px solid grey;
  }

  label.togglebold {
    color: $primary;
    font-weight: bold;
  }

  .amount-options {
    .svg-wrapper {
      display: inline-block;

      svg {
        font-size: 25px;
        top: 3px;
        position: relative;
        color: $primary;
      }

      svg:hover {
        cursor: pointer;
        color: $primary;
      }
    }

    span {
      width: 70px;
      display: inline-block;
      border: 1px solid gray;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #editor-title {
    text-align: left;
  }

  .is-checkradio[type='radio']:hover:not([disabled]) + label::before {
    border: 0.1rem solid $secondary !important;
  }

  .is-checkradio[type='radio']:checked + label::after {
    background-color: $secondary !important;

    &:hover {
      border: 0.1rem solid $secondary !important;
    }
  }
}

.level-right {
  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
}

.level-item p {
  color: $primary;
  font-family: gill-sans-nova;
  margin-top: 33px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.amount-input-wrapper {
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

#text-box-preview {
  max-height: 110px;
  overflow: hidden;
  overflow-wrap: break-word;
  color: #503120;
}

.checkradio-label {
  font-size: 18px !important;
  @media screen and (max-width: 500px) {
    font-size: 12px !important;
  }
}

.editor-top-span {
  border-bottom: 1px solid $primary;
  padding-bottom: 10px;
}

.editor-span-left {
  padding-left: 20px;
  padding-bottom: 20px;
}

.border-bottom {
  margin-left: 1px;
  margin-right: 1px;
  border-bottom: 1px solid $primary;
}

.border-right {
  border-right: 1px solid $primary;
}

#front-editor-wrapper {
  background-image: url('../assets/img/bluebar-front.jpg');
  background-size: contain;
  border-bottom: 1px solid #c69b67;
}

#back-editor-wrapper {
  background-image: url('../assets/img/bluebar-back.jpg');
  background-size: contain;
}

.remove-logo:hover {
  cursor: pointer;
}

.remove-logo {
  cursor: pointer;
  color: $primary;
  svg {
    font-size: 35px;
  }
  svg:hover {
    cursor: pointer;
  }
  p {
    color: $primary;
    font-family: gill-sans-nova;
  }
  p:hover {
    text-decoration: underline;
  }
}

#flavour-selector {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;

  label {
    p {
      font-size: 18px;
      font-weight: bold;
      color: #15256B;
    }
  }

  img {
    padding: 20px;
  }

  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type=radio]:checked + img {
    background-color: #EBEFF5;
  }
  label.selected {
    background-color: #EBEFF5;
    p {
      background-color: #EBEFF5;
    }
  }
}

</style>
