<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="isActive" @keydown.esc=answerNAY tabindex="0" ref="modalroot" :style="getVisibility">
      <div class="modal-background"></div>
      <div class="modal-card" :style="widthStyle">
        <header class="modal-card-head">
          <p class="modal-card-title title has-text-centered mb-0">{{ headingText }}</p>
          <div class="buttons is-right head-button-wrapper">
            <button class="button is-secondary is-inverted" @click="answerNAY">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <slot></slot>
        </section>
        <footer class="modal-card-foot" v-if="hasButtons">
          <div class="buttons">
            <button v-if="showOk" class="button is-primary" @click="answerOK">{{ okButtonText }}</button>
            <button v-if="showCancel" class="button is-secondary" @click="answerNAY">{{ cancelButtonText }}</button>
          </div>
        </footer>
      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: 'ModalDialog',
  props: {
    showOk: {
      type: Boolean,
      default: true,
    },
    okButtonText: {
      type: String,
      default: 'Kyllä',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Peruuta',
    },
    headingText: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '440px'
    },
    minWidth: {
      type: String,
      default: '440px'
    },
    visibility: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['modal-result-ok', 'modal-result-cancel'],
  data() {
    return {
      isActive: false,
      activeStep: 1,
      resolve: null,
      reject: null,
    }
  },
  computed: {
    hasButtons() {
      return this.showOk || this.showCancel
    },
    widthStyle() {
      return {
        width: this.width,
        'min-width': this.minWidth
      }
    },
    getVisibility() {
      return {}
      // this breaks on fazer windows10/firefox
      /*
      if (!this.visibility) {
        return {'visibility': 'hidden'}
      }
      return {}
      */
    }
  },
  methods: {
    showModal(name) {
      document.documentElement.style.overflow = 'hidden'
      this.isActive = true
      this.$nextTick().then(() => {
        this.$refs.modalroot.focus()
      })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    setActiveStep(step) {
      this.activeStep = step;
    },

    answerOK(...args) {
      document.documentElement.style.overflow = 'auto'
      this.$emit('modal-result-ok')
      this.isActive = false
      if (this.resolve != null) {
        return this.resolve(...args)
      }
    },
    answerNAY(...args) {
      document.documentElement.style.overflow = 'auto'
      this.$emit('modal-result-cancel')
      this.isActive = false
      if (this.reject != null) {
        return this.reject(...args)
      }
    },
  },
}
</script>
<style scoped lang="scss">

.modal-card-head {
  position: relative;
  .head-button-wrapper {
    position: absolute;
    top: 6px;
    right: 0;
  }

}
.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}

.modal {
  z-index: 100;
  .modal-card {
    .modal-steps{
      margin-top: 10px;
      h4{
        font-size: 23px;
        font-weight: 500;
        color: #222
      }
      div{
        display: flex;
        gap: 10px;

        span{
          height: 44px;
          width: 44px;
          border-radius: 50%;
          font-size: 20px;
          font-weight: 600;
          background-color: #EBEFF5;
          color: #15256B;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active{
            background-color: #15256B;
            color: #fff;
          }
        }
      }
    }
    @media (max-width: 500px) {
      min-width: 0px !important;
      width: 100% !important;
    }
  }
}

.buttons {
  margin-left: 15px;
  .button {
    min-width: 50px;
    text-align: center;
    display: block;
  }
}
</style>
