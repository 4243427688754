<template>
  <div class="discountCodeForm">
    <p class="title is-size-4">Kampanjakoodi</p>

    <div class="field has-addons">
      <div id="code-input" class="control">
        <input v-model="code" class="input" type="text" placeholder="Syötä kampanjakoodi tähän" />
      </div>
      <div class="control">
        <button class="button is-primary" @click="submitDiscountCode" :disabled="code == ''">LISÄÄ</button>
      </div>
    </div>
    <p v-if="voucherCode == 404" class="help is-danger">Kampanjakoodi on virheellinen.</p>
  </div>
</template>

<script>
import SpinnerModal from '@/components/modals/SpinnerModal'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'discountCode',
  data: () => {
    return {
      code: '',
    }
  },
  methods: {
    ...mapGetters({ getVoucherDiscount: 'getVoucherDiscount' }),
    ...mapActions({
      partialUpdateOrder: 'partialUpdateOrder',
    }),
    ...mapMutations(['setSpinnerState']),
    submitDiscountCode() {
      this.setSpinnerState(true)
      this.$store
        .dispatch('useVoucherDiscount', this.code)
        .then(() => {
          if (this.getVoucherDiscount() != null) {
            this.partialUpdateOrder({
              discount_code: this.getVoucherDiscount().id,
            })
          }
        })
        .finally(() => {
          this.setSpinnerState(false)
        })
    },
  },
  computed: {
    voucherCode() {
      return this.$store.state.useVoucherDiscountStatusCode
    },
  },
}
</script>

<style scoped lang="scss">
#code-input {
  width: 100%;
}
</style>
