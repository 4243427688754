import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_PM_BACKEND_URL

export const doAsync = (store, {url, mutationTypes, method, data, callback, headers}) => {
  store.commit(mutationTypes.BASE, {type: mutationTypes.PENDING, value: true})
  let config = {
    method: method,
    data: data,
    headers: headers,
  }

  return axios('/api/v1/' + url, config)
    .then((response) => {
      let data = response.data
      if (callback) {
        data = callback(response)
      }
      store.commit(mutationTypes.BASE, {type: mutationTypes.SUCCESS, data, statusCode: response.status})
      store.commit(mutationTypes.BASE, {type: mutationTypes.PENDING, value: false})
    })
    .catch((error) => {
      let err = error.response ? error.response.data : {}
      store.commit(mutationTypes.BASE, {type: mutationTypes.PENDING, value: false})
      store.commit(mutationTypes.BASE, {type: mutationTypes.FAILURE, statusCode: error.response.status, error: err})
    })
}