<template>
  <div class="shipmentForm">

    <p class="title is-size-4" v-if="!getCalendarMode">Asiakastiedot / Toimitusosoite mikäli vain yksi toimitusosoite</p>
    <p class="title is-size-4" v-else>Asiakas- ja toimitustiedot</p>

    <div class="field">
      <div class="control" v-bind:class="{ 'date-picker-placeholder': tmp_expected_delivery_date == ''}">
        <label>Huomioithan, että normaali toimitusaika on vähintään 2-3 viikkoa. Pyrimme toimittamaan tuotteet mahdollisimman lähelle toivottua päivämäärää.</label>
        <VueDatePicker
            v-model="tmp_expected_delivery_date"
            placeholder="Toimitusaikatoive"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            locale="fi-FI"
            cancelText="Hylkää"
            selectText="Valitse"
        ></VueDatePicker>
      </div>
      <p v-if="v$.expected_delivery_date.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field">
      <div class="control">
        <input
            v-model=contact.recv_name
            class="input"
            type="text"
            placeholder="Etunimi"
        />
      </div>
      <p v-if="v$.recv_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field">
      <div class="control">
        <input
            v-model=contact.recv_last_name
            class="input"
            type="text"
            placeholder="Sukunimi"
        />
      </div>
      <p v-if="v$.recv_last_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div v-if="isCompany" class="field">
      <div class="control">
        <input v-model=contact.recv_company
               class="input"
               type="text"
               placeholder="Yrityksen/Yhdistyksen nimi"/>
      </div>
      <p v-if="v$.recv_company.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field">
      <div class="control">
        <input v-model=contact.recv_phone class="input" type="text" placeholder="Puhelinnumero (esim +358401234567)"/>
      </div>
      <p v-if="v$.recv_phone.$error" class="help is-danger">Syötä puhelinnumero oikeassa muodossa (esim +358401234567). Puhelinnumerossa tulee olla 7-15 numeroa. Numerossa tulee olla suuntanumero eikä siinä saa olla välilyöntejä.</p>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <input v-model=contact.cust_email
               class="input" type="email" placeholder="Sähköpostiosoite"/>
        <span class="icon is-small is-left">
        <i class="fas fa-envelope"></i>
        </span>
      </div>

      <p v-if="v$.cust_email.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field">
      <div class="control">
        <input v-model=contact.recv_street class="input" type="text" placeholder="Katuosoite"/>
      </div>
      <p v-if="v$.recv_street.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <p class="control is-expanded">
            <input v-model=contact.recv_zip class="input" type="text" placeholder="Postinumero"/>

          </p>
          <p v-if="v$.recv_zip.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
        </div>
        <div class="field">
          <p class="control is-expanded">
            <input v-model=contact.recv_town class="input" type="text" placeholder="Postitoimipaikka"/>
          </p>
          <p v-if="v$.recv_town.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <input class="input" disabled type="text" value="Suomi"/>
      </div>
    </div>          

    <div class="field" v-if="isCompany">
      <div class="control">
        <input v-model=contact.company_reference class="input" type="text" placeholder="Tilaajan oma viite laskulle"/>
      </div>
      <p v-if="v$.company_reference.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div v-if="isCompany" class="field">
      <div class="control">
        <input v-model=contact.cust_vat_nr class="input" type="text" placeholder="Y-tunnus"/>
      </div>
      <p v-if="v$.cust_vat_nr.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      <p class="help footnote">* Y-tunnus on pakollinen, jos maksat laskulla</p>
    </div>

    <div class="field" v-if="isCompany">
      <div class="control">
        <input v-model=contact.company_e_invoice_address class="input" type="text" placeholder="Verkkolaskutusosoite (mikäli käytössä)"/>
      </div>
      <p v-if="v$.company_e_invoice_address.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div class="field" v-if="isCompany">
      <div class="control">
        <input v-model=contact.company_operator_name class="input" type="text" placeholder="Välittäjätunnus ja -nimi (mikäli käytössä)"/>
      </div>
      <p v-if="v$.company_operator_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
    </div>

    <div v-if="isCompany" class="field mb-3">
      <div class="control">
        <label class="checkbox">
        <input type="checkbox" v-model="need_billing_address">
          Laskutusosoite on eri kuin yhteystieto
        </label>
      </div>
    </div>

    <div v-show="need_billing_address && isCompany">
      <p class="title is-size-4">Laskutusosoite</p>

      <div class="field">
        <div class="control">
          <input
              v-model=contact.bill_name
              class="input"
              type="text"
              placeholder="Etunimi"
          />
        </div>
        <p v-if="v$.bill_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>

      <div class="field">
        <div class="control">
          <input
              v-model=contact.bill_last_name
              class="input"
              type="text"
              placeholder="Sukunimi"
          />
        </div>
        <p v-if="v$.bill_last_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>

      <div class="field">
        <div class="control">
          <input v-model=contact.bill_company
                 class="input"
                 type="text"
                 placeholder="Yrityksen/Yhdistyksen nimi"/>
        </div>
        <p v-if="v$.bill_company.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>

      <div class="field">
        <div class="control">
          <input v-model=contact.bill_phone class="input" type="text" placeholder="Puhelinnumero (esim +358401234567)"/>
        </div>
        <p v-if="v$.bill_phone.$error" class="help is-danger">Syötä puhelinnumero oikeassa muodossa (esim +358401234567). Puhelinnumerossa tulee olla 7-15 numeroa. Numerossa tulee olla suuntanumero eikä siinä saa olla välilyöntejä.</p>
      </div>

      <div class="field">
        <div class="control">
          <input v-model=contact.bill_street class="input" type="text" placeholder="Katuosoite"/>
        </div>
        <p v-if="v$.bill_street.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input v-model=contact.bill_zip class="input" type="text" placeholder="Postinumero"/>

            </p>
            <p v-if="v$.bill_zip.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input v-model=contact.bill_town class="input" type="text" placeholder="Postitoimipaikka"/>
            </p>
            <p v-if="v$.bill_town.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <input class="input" disabled type="text" value="Suomi"/>
        </div>
      </div>

      <div v-if="isCompany" class="field">
        <div class="control">
          <input v-model=contact.bill_vat_nr class="input" type="text" placeholder="Y-tunnus"/>
        </div>
        <p v-if="v$.bill_vat_nr.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
        <p class="help footnote">* Y-tunnus on pakollinen, jos maksat laskulla</p>
      </div>

      <div class="field" v-if="isCompany">
        <div class="control">
          <input v-model=contact.bill_company_e_invoice_address class="input" type="text" placeholder="Verkkolaskutusosoite (mikäli käytössä)"/>
        </div>
        <p v-if="v$.bill_company_e_invoice_address.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>

      <div class="field" v-if="isCompany">
        <div class="control">
          <input v-model=contact.bill_company_operator_name class="input" type="text" placeholder="Välittäjätunnus ja -nimi (mikäli käytössä)"/>
        </div>
        <p v-if="v$.bill_company_operator_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
      </div>

    </div>

    <div v-if="!isCompany" class="control mt-5" style="padding-left:10px;">
      <label class="checkbox-container mt-2">
        <input type="checkbox" v-model="contact.myfazer">
         <div class="is-inline" v-html=getMyFazerPolicy></div>
        <span class="mark"></span>
      </label>
      <label v-if="contact.myfazer" class="checkbox-container mt-5">
        <input type="checkbox" v-model="contact.marketing">
        <div class="is-inline" v-html="getMarketingPolicy"> </div>
        <span class="mark"></span>
      </label>
      <div v-if="contact.myfazer" class="control mt-5">
        <p>
          *Ruokaelämyksiin keskittyvänä yrityksenä Fazer mahdollistaa jokaiselle päivän parhaista hetkistä nauttimisen.
          Fazer keskittyy kuluttajatuotteisiin ja suoraan kuluttajaliiketoimintaan.
          Konsernilla on toimintaa yhdeksässä maassa ja vientiä noin 40 maahan.
        </p>
      </div>
    </div>

    <div class="field mt-3" v-if="!getCalendarMode()">
      <div class="control">
        <textarea class="input" v-model="contact.additional_details" placeholder="Onko sinulla jotain kysyttävää tai lisätietoja tilaukselle? Voit syöttää ne tähän. Ilmoita myös, mikäli haluat keskustella enemmän asiakaspalvelijamme kanssa. Vastaamme mielellämme kysymyksiin"></textarea>
      </div>
    </div>

  </div>
</template>

<script>
// docs on vuelidate on vue 3 -> https://vuelidate-next.netlify.app/#install-via-plugin-in-vue-3-0
import { reactive, ref } from 'vue'
import {email, required} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {mapGetters, useStore} from 'vuex'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import {mapMutations} from 'vuex'

export default {
  name: 'shipmetAddress',
  components: {
    VueDatePicker
  },
  data: () => {
    return {
      need_billing_address: false,
      date_format: null,
      tmp_expected_delivery_date: ''
    }
  },
  setup: () => {
    const store = useStore()
    let contactData = {
      recv_name: '',
      expected_delivery_date: '',
      recv_last_name: '',
      recv_company: '',
      recv_phone: '',
      recv_street: '',
      recv_zip: '',
      recv_town: '',

      bill_name: '',
      bill_last_name: '',
      bill_company: '',
      bill_phone: '',
      bill_street: '',
      bill_zip: '',
      bill_town: '',
      company_e_invoice_address: '',
      company_operator_name: '',
      bill_company_e_invoice_address: '',
      bill_company_operator_name: '',
      company_reference: '',

      cust_email: '',
      cust_vat_nr: '',
      bill_vat_nr: '',
      additional_details: '',
      marketing: false,
      myfazer: false,
      company: false
    }
    if(store.getters.getContactData){
      contactData = store.getters.getContactData
    }
    const contact = reactive(contactData)
    const phoneValidation = (value) =>  value.startsWith('+') && value.length >= 8 && value.length < 17
    const billPhoneValidation = (value) =>  value == '' || (value.startsWith('+') && value.length >= 8 && value.length < 17)
    const rules = {
        expected_delivery_date: {
          required
        },
        recv_name: {
          required,
        },
        recv_last_name: {
          required,
        },
        recv_company: {},
        recv_phone: {
          required,
          phoneValidation
        },

        recv_street: {required},
        recv_zip: {required},
        recv_town: {required},

        cust_email: {
          email,
          required,
        },
        cust_vat_nr: {},
        bill_vat_nr: {},

        bill_name: {},
        bill_last_name: {},
        bill_company: {},
        bill_phone: {
          billPhoneValidation
        },
        bill_street: {},
        bill_zip: {},
        bill_town: {},
        company_e_invoice_address: {},
        company_operator_name: {},
        bill_company_e_invoice_address: {},
        bill_company_operator_name: {},
        company_reference: {},
       additional_details: {}
      }

    const v$ = useVuelidate(rules, contact)

    return { contact, v$ }
  },
  computed: {
    isCompany() {
      return this.$store.getters.getIsCompany
    },
    getMyFazerPolicy() {
      return this.htmlDecode(this.$store.getters.getMyFazerPolicy)
    },
    getMarketingPolicy() {
      return this.htmlDecode(this.$store.getters.getMarketingPolicy)
    }
  },
  mounted() {
    this.need_billing_address = this.$store.state.billing
    this.tmp_expected_delivery_date = this.initDate(this.contact.expected_delivery_date)
  },
  methods: {
    ...mapMutations([
        'setSpinnerState',
        'setCartSpinnerState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getCalendarMode: 'getCalendarMode'
    }),
    submitForm() {
      if (this.tmp_expected_delivery_date == undefined) {
        this.contact.expected_delivery_date = null
      } else {
        this.contact.expected_delivery_date = this.getBackendDate(this.tmp_expected_delivery_date)
      }
      this.contact.company = this.isCompany
      this.v$.$touch()
      if (this.v$.$error) return

      const contact_wo = Object.assign({order: this.getOrderData().id}, this.contact)

      this.setSpinnerState(true)

      let mutation = 'postContact'

      if (this.$store.state.postContactData != null) {
        mutation = 'updateContact'
      }
      this.$store.dispatch(mutation, contact_wo).then(()=>{
        this.$router.push({name: 'payment', params: {uuid: this.getOrderData().id}})
      }).catch((error) => {
        // FIXME: doAsync handles the catch of axios call, we are never getting to here
        console.log('Error saving contact', error)
      }).finally(()=>{
         this.setSpinnerState(false)
      })

    },
    saveTemporalContact() {
      this.$store.state.temporal_contact_data = this.contact
    },
    htmlDecode(input) {
      var e = document.createElement('textarea');
      e.innerHTML = input;
      // handle case of empty input
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    getBackendDate(date) {
      if (date != '') {
        if (typeof(date) == 'string') {
          let [day, month, year] = date.split('-')
          date = new Date(year, month - 1, day);
        } else {
          date = new Date(date)
        }
        let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
        let month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date)
        let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
        return `${year}-${month}-${day}`
      } else {
        return ''
      }
    },
    initDate(date) {
      if (date != '') {
        let [year, month, day] = date.split('-')
        return new Date(year, month - 1, day)
      }
    }
  },
  watch: {
    need_billing_address: function (val) {
      this.$store.state.billing = val
    },
  }
}
</script>

<style scoped lang="scss">

.footnote {
  color: #dedbd9;
}

p {
  font-size: 18px;
  font-family: gill-sans-nova;
}

// checkbox
.checkbox-container {
  display: table;
  position: relative;
  padding-left: 24px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: gill-sans-nova;
}

/* Hide the default checkbox */
.checkbox-container input {
  //visibility: hidden;
  display: none;
  cursor: pointer;
}


.container input:checked ~ .mark {
  background-color: #15256B;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 1px solid #15256B;
}

.checkbox-container:hover input ~ .mark {
  background-color: gray;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.checkbox-container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.checkbox-container .mark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

textarea {
  border: 2px solid #dedbd9 !important;
  height: 100px !important;
  resize: none;
}

:deep(input.dp__input_readonly) {
  border: 2px solid #dedbd9;
  border-radius: 0px;
  color: hsl(0deg, 0%, 21%);
  font-family: gill-sans-nova;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px
}

:deep(.date-picker-placeholder) {
  input {
    color: rgba(54, 54, 54, 0.3) !important;
  }
}

</style>
