<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="this.$store.getters.getNotificationActive" tabindex="0" ref="modalroot">
      <div class="modal-background" @click="answerNAY"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="buttons is-right">
            <button class="button is-secondary is-inverted" @click="answerNAY">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body pt-0">
          <p>
            {{message}}
          </p>
          <slot></slot>
        </section>
      </div>
    </div>
  </teleport>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  name: 'notificationModal',
  methods: {
    ...mapMutations([
      'setNotificationActiveState'
    ]),
    answerNAY(...args) {
      document.documentElement.style.overflow = 'auto'
      this.setNotificationActiveState({'notificationState': false, 'message': ""})
    },
  },
  computed: {
    message() {
      return this.$store.getters.getNotificationMessage
    }
  }
}
</script>
<style scoped lang="scss">
.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}

.modal {
  z-index: 100;
  .buttons {
    width: 100%;
  }
  .modal-card {
    width: 20%;
    @media (max-width: 768px) {
      min-width: 0px !important;
      width: 100% !important;
    }
  }
}
.modal-card-body {
  p {
    font-size: 20px;
  }
}
</style>
