<template>
  <div class="editor" ref="editorRefWidth">
    <h1 class="mb-6">Pakkauksen muokkaaminen</h1>

    <div class="columns m-0 mb-5">
      <div class="column py-0">
        <p class="size16 has-text-centered">
          Muokkaa tuote mieleiseksesi ja lisää se ostoskoriin. Kun olet lisännyt tuotteen ostoskoriin, pääset ostoskorissa valitsemaan haluamasi toimitustavan tuotteelle.
        </p>
      </div>
    </div>

    <asModalDialog
      heading-text="3d esikatselu"
      :show-ok="false"
      :show-cancel="false"
      width="80%"
      min-width="500px"
      ref="previewModal"
    >
      <ThreeDPreview
        v-if="show3dPreview"
        :texture-width="1280"
        :texture-height="960"
        material-name="frontFace"
        :textures="textures"
        :texture-uri="textureUri"
      />
    </asModalDialog>

    <div class="columns m-0">
      <div class="column py-0">
        <p class="size16 has-text-left">Domino</p>
      </div>
    </div>    

    <div class="columns mt-6 is-mobile top-editor-section">
      <div class="column is-2-tablet is-3-mobile">
        <div class="add-logo" @click="changeLogo">
          <i class="fas fa-star clone-icon"></i>
          <p class="underline">Aseta logo</p>
        </div>
        <asModalDialog :show-ok="false" :show-cancel="false" heading-text="Aseta Logo" ref="logoEditorModal">
          <logo-editor :mutation="'partialUpdateBox'"/>
        </asModalDialog>
      </div>

      <div class="column is-2-tablet is-3-mobile">
        <div class="remove-logo" @click="openRemoveModalLogo" v-bind:class="{'fa-disabled': !product.original_logo_url}">
          <i class="fas fa-trash-alt trash-icon"></i>
          <p class="underline">Poista logo</p>
        </div>
      <asModalDialog ref="deleteModalLogo" heading-text="Poista logo">
        <p>Haluatko varmasti poistaa logon?</p>
      </asModalDialog>
      </div>

      <!--div class="column is-2-tablet is-3-mobile">
        <div class="remove-logo" @click="cloneBox()">
          <i class="fas fa-clone"></i>
          <p class="underline">Tee kopio</p>
        </div>
      </div-->

      <div class="column is-4-tablet is-3-mobile">
        <div class="remove-logo" @click="previewModal()">
          <i class="fas fa-2x fa-cube"></i>
          <p class="underline">Katso 3D-kuva valmiista tuotteesta</p>
        </div>
      </div>
    </div>
    <canvas-image
      ref="textCanvas"
      :image-width=croppieInitialWidth
      :image-height=croppieInitialHeight
      :canvas-text="product.text"
      :options="{
        backgroundColor: '#f4acbc',
        color: '#503120',
        font: fontFamily,
        fontSize: fontSize,
        lineHeight: lineHeight,
      }"
    />
    <div class="columns is-mobile">
      <div class="column" :class="{ 'pr-0': isPortrait }">
        <div id="editor-wrapper" ref="editorWrapper" :class="{ landscape: isLandscape, portrait: isPortrait }">
          <div v-if="isLandscape" class="column is-fullwidth">
            <div
              v-if="$refs.editorRefWidth"
              class="text-preview columns is-vcentered is-flex is-justify-content-center is-align-items-center is-mobile"
              :style="textPreviewCss"
              id="text-box-wrapper-preview"
            >
              <p v-html="text" class="is-primary" id="text-box-preview" :style="textFontPreviewCss"></p>
            </div>
          </div>
          <div
            v-if="$refs.editorRefWidth"
            id="croppieBoundary"
            :style="croppieOrientationScaleCss"
            class="column is-two-thirds pl-0 pt-0"
            :class="{ 'is-pulled-right': isPortrait, 'pr-0': isPortrait }"
          >
            <div
              v-show="product.croppied_logo_url != null && product.croppied_logo_url != ''"
              :style="userLogoCss"
              class="userLogo"
              alt="Domino logo"
              :src="product.croppied_logo_url"
            />
            <vue-croppie
              ref="croppieRef"
              :enableOrientation="true"
              :enableExif="true"
              :viewport="viewportWidthHeight"
              :boundary="viewportWidthHeight"
              :showZoomer="false"
              :enableResize="false"
              :minZoom="croppie_zoom_min"
              :maxZoom="croppie_zoom_max"
              @update="croppieUpdated"
            >
            </vue-croppie>
          </div>
        </div>
      </div>

      <div v-if="isPortrait" class="column is-one-third pl-0">
        <div
          class="text-preview portrait is-flex is-justify-content-center is-align-items-center is-pulled-left"
          :style="[textPreviewCss]"
        >
          <p v-html="text" class="vtext is-primary" :style="textFontPreviewCss"></p>
        </div>
      </div>
    </div>
    <div class="columns pb-0 mb-0">
      <div class="column is-12 pb-0">
        <span class="is-block editor-top-span">Kohdista kuva raahaamalla sitä pakkauksen päällä</span>
      </div>
    </div>
    <div class="columns mb-5 is-vcentered is-mobile is-multiline pt-0 border-bottom pt-0 mt-0">
      <div class="column is-one-third-desktop is-half-mobile has-text-left border-right">
        <span class="is-block has-text-weight-bold has-text-left editor-span-left">Pakkauksen orientaatio</span>
        <div id="toggle-orientation" class="field">
          <label for="switchRoundedDefault" v-bind:class="{ 'has-text-weight-bold': isPortrait }" class="left-label editor-span-left"
            >Pysty</label
          >

          <input
            id="switchRoundedDefault"
            type="checkbox"
            name="switchRoundedDefault"
            class="switch is-rounded"
            checked="checked"
            true-value="OL"
            false-value="OP"
            v-model="product.orientation"
            @change="orientationUpdated($event)"
          />

          <label
            for="switchRoundedDefault"
            v-bind:class="{ 'has-text-weight-bold': isLandscape }"
            class="right-label"
            >Vaaka</label
          >
        </div>
      </div>
      <div class="column is-one-third-desktop is-hidden-mobile border-right">
        <div id="rotate-wrapper is-clickable">
          <span class="icon is-large is-clickable" @click="rotate(90)">
            <i class="fas fa-2x fa-redo-alt"></i>
          </span>
          <br />
          <span class="m-t-md is-block">Käännä kuvaa</span>
        </div>
      </div>
      <div class="column is-half-mobile is-hidden-tablet border-bottom" style="height: 111px;">
        <div id="rotate-wrapper is-clickable">
          <span class="icon is-large is-clickable" @click="rotate(90)">
            <i class="fas fa-2x fa-redo-alt"></i>
          </span>
          <br />
          <span class="m-t-md is-block">Käännä kuvaa</span>
        </div>
      </div>

      <div class="column is-one-third-desktop is-12-mobile pt-0 pb-0">
        <div id="slider-wrapper">
          <input
            class="slider is-fullwidth is-large is-circle"
            type="range"
            step="0.0001"
            aria-label="zoom"
            v-bind:min="croppie_zoom_min"
            v-bind:max="croppie_zoom_max"
            @change="updateCroppieZoom"
            :value="croppie_zoom"
          />
        </div>
        <span class="is-block">Loitonna / lähennä kuvaa</span>
      </div>
    </div>
    <div class="columns mt-6 is-mobile is-multiline border-bottom" style="position: relative">
      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
          <input
            class="is-checkradio is-secondary"
            id="exampleRadioSuccess"
            type="checkbox"
            name="exampleRadioSuccess"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label class="checkradio-label" for="exampleRadioSuccess">Ei tekstiä</label>
        </div>
      <div class="lang-selection">
        <div class="dropdown is-right" :class="{ 'is-active': fontFamilyMenuActive }">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              @click="fontFamilyMenuActive = !fontFamilyMenuActive"
            >
              <span>Vaihda fontti</span>
              <span class="icon is-medium-important"> <a class="fas fa-font"></a><br /> </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                role="button"
                class="dropdown-item"
                style="font-family: 'Gotham Black'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_GOTHAM' }"
                @click="tmpCandyBoxFont = 'F_GOTHAM';"
              >
                Gotham Black
              </a>
              <a
                role="button"
                class="dropdown-item"
                style="font-family: 'Tartine Script Black'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_TARTINE' }"
                @click="tmpCandyBoxFont = 'F_TARTINE';"
              >
                Tartine Script
              </a>
              <a
                role="button"
                class="dropdown-item"
                style="font-family: 'Praho Pro'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_PARAHO' }"
                @click="tmpCandyBoxFont = 'F_PARAHO';"
              >
                Praho Pro
              </a>
              <div class="font-selection-buttons-wrapper">
                <button class="mt-3 mr-1 button is-primary" @click="changeFont()">
                  <span>Valitse fontti</span>
                </button>
                <button class="mt-3 mr-1 button is-primary" @click="fontFamilyMenuActive = !fontFamilyMenuActive">
                  <span>Peruuta</span>
                </button>                 
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third is-hidden-mobile has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Pakkauksen teksti</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="exampleRadioSuccess"
            type="checkbox"
            name="exampleRadioSuccess"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label for="exampleRadioSuccess">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor
          :notext="product.notext"
          :maxRows="3"
          :fontStyle="{ 'font-family': fontFamily }"
          @inputtextarea="inputTextarea"
          :maxCharactersPerRow="40"
          :producttext="product.text"
          name="text-editor-text-desktop"
          ref="textEditor">
        </TextEditor>
        <span v-if="textEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0">
        <TextEditor
          :notext="product.notext"
          :maxRows="3"
          :fontStyle="{ 'font-family': fontFamily }"
          @inputtextarea="inputTextarea"
          :maxCharactersPerRow="40"
          :producttext="product.text"
          name="text-editor-text-mobile"
          ref="textEditor"
        >
        </TextEditor>
        <span v-if="textEmpty" style="color: red;">Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span>
      </div>
    </div>

    <div class="columns">
      <div class="column is-4-tablet is-12-mobile has-text-left has-text-centered-mobile">
        <span class="editor-span-left">
          <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
        </span>
      </div>
      <div class="column is-8-tablet is-12-mobile has-text-left has-text-centered-mobile">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input type="number" @keypress="preventNonNumericalInput(event)" min="0" @change="changedAmount()" v-model="product.amount" class="input has-text-centered" />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCroppieComponent from '@/components/helpers/VueCroppieComponent'
import TextEditor from '@/components/TextEditor'
import LogoEditor from '@/components/LogoEditor'
import getSize from 'element-size'
import debounce from 'lodash/debounce'
import asModalDialog from '@/components/helpers/asModalDialog'
import ThreeDPreview from '@/components/3d/ThreeDPreview'
import canvasImage from '@/components/canvasImage'
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'

import {mapMutations, mapGetters} from 'vuex'

require('style-loader!../../node_modules/croppie/croppie.css')

let fontMapping = {
  F_GOTHAM: ['Gotham Black', '28px', '35px'],
  F_TARTINE: ['Tartine Script Black', '35px', '35px'],
  F_PARAHO: ['Praho Pro', '30px', '35px'],
}

export default {
  name: 'DominoEditor',
  props: {
    productId: String
  },
  components: {
    'vue-croppie': VueCroppieComponent,
    TextEditor,
    asModalDialog,
    ThreeDPreview,
    canvasImage,
    LogoEditor
  },
  mixins: [inputNumberMixin],  
  data() {
    return {
      croppieInitialWidth: 960,
      croppieInitialHeight: 260,
      croppie_ready: false,
      cropped: null,
      dominoEditorWidth: 900,
      dominoEditorHeight: 450,
      dominoPreviewWidth: 900,
      dominoPreviewHeight: 230,
      dominoPreviewTextW: 650,
      textureUri: `${process.env.BASE_URL}3d/domino3.glb`,
      croppie_zoom: 1,
      croppie_zoom_min: 0.2,
      croppie_zoom_max: 2.5,
      temporalText: '',
      show3dPreview: false,
      product: {
        notext: false,
        amount: 1,
        id: null,
        text: '',
        orientation: 'OL',
        font: 'F_GOTHAM',
        position: null,
        image: {
          image: null,
          image_croppied: null,
          croppie_points: null,
          croppie_zoom: 1,
          croppie_orientation: null,
          text_image_64: null,
          text_image: null,
        },
      },
      fontFamilyMenuActive: false,
      element_zoom: 0, // text preview element zoom
      Url: null,
      textAsImageUrl: '',
      editorScale: 1,
      textEmpty: false,
      cloning: false,
      prevWidth: 0,
      currWidth: 0,
      tmpCandyBoxFont: 'F_GOTHAM',   
    }
  },
  mounted() {
    this.initEditorState(this.productId)
    this.croppie_ready = true // prevend watch triggering too early prevend

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.handleTextPreviewResize()
      this.onResize()
    })
    if(!this.product.draft) {
      if(!this.product.text && !this.product.notext) this.toggleNoText()
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    croppedImageTexture() {
      return {
        uri: this.product.image.image_croppied,
        height: 640,
        width: 1280,
        rotation: this.isLandscape ? 0 : 90,
      }
    },
    textImageTexture() {
      return {
        uri: this.textAsImageUrl,
        height: 320,
        width: 1280,
        rotation: 0,
      }
    },
    logoImageTexture() {
      const logo_url = this.product.croppied_logo_url
      if (logo_url == null) {
        return {}
      }
      if (this.isLandscape) {
        return {
          uri: logo_url,
          height: 180,
          width: 180,
          rotation: 0,
          offsetX: 1090,
          offsetY: 750,
        }
      } else {
        return {
          uri: logo_url,
          height: 180,
          width: 180,
          rotation: 90,
          offsetX: 1090,
          offsetY: -330,
        }
      }
    },
    textures() {
      if (this.product.croppied_logo_url != '') {
        return [this.textImageTexture, this.croppedImageTexture, this.logoImageTexture]
      }
      return [this.textImageTexture, this.croppedImageTexture]
    },
    text() {
      if (this.product.text == '' && !this.product.notext) {
        return 'Kirjoita teksti'
      } else {
        return this.product.text.replace(/\n/g, '<br>')
      }
    },
    isPortrait() {
      if (this.product.orientation === 'OP') {
        // Orientation Portrait
        return true
      }
      return false
    },
    isLandscape() {
      // Orientation Landscape
      if (this.product.orientation === 'OL') {
        return true
      }
      return false
    },
    viewportWidthHeight() {
      if (this.product.orientation === 'OL') {
        return {
          width: this.dominoEditorWidth * this.editorScale,
          height: this.dominoEditorHeight * this.editorScale,
        }
      }
      return { width: this.dominoEditorHeight * this.editorScale, height: this.dominoEditorWidth * this.editorScale }
    },
    croppieOrientationScaleCss() {
      // use transform properties for firefox and opera
      let styles = {} // this.zoomStyles
      if (this.product.orientation === 'OL') {
        // landscape
        return Object.assign(styles, {
          width: `${this.dominoEditorWidth * this.editorScale}px`,
          height: `${this.dominoEditorHeight * this.editorScale}px`,
        })
      } else {
        return Object.assign(styles, {
          width: `${this.dominoEditorHeight * this.editorScale}px`,
          height: `${this.dominoEditorWidth * this.editorScale}px`,
        })
      }
    },
    userLogoCss() {
      const logo_url = this.product.croppied_logo_url
      if (logo_url != null) {
        let css = {
          'background-image': `url(${logo_url})`,
        }
        if (this.isLandscape) {
          return Object.assign(css, {
            right: '10px',
            bottom: '20px',
            zoom: this.editorScale,
          })
        }
        return Object.assign(css, {
          right: '10px',
          bottom: '20px',
          zoom: this.editorScale,
        })
      }
      return {
        display: 'none',
      }
    },
    textPreviewCss() {
      let styles = {}

      styles = Object.assign(styles, {
        'font-family': this.fontFamily,
        'font-size': this.fontSize,
        'line-height': this.lineHeight,
      })

      if (this.isLandscape) {
        return Object.assign(styles, {
          height: `${this.dominoPreviewHeight * this.editorScale}px`,
          width: `${this.dominoPreviewWidth * this.editorScale}px`,
        })
      } else {
        return Object.assign(styles, {
          height: `${this.dominoPreviewWidth * this.editorScale}px`,
          width: `${this.dominoPreviewHeight * this.editorScale}px`,
        })
      }
    },
    textFontPreviewCss() {
      let styles = {}

      if (this.isLandscape) {
        styles = Object.assign(styles, {
          transform: 'scale(' + this.element_zoom + ')',
          'max-width': this.dominoPreviewTextW + 'px',
        })
      } else {
        styles = Object.assign(styles, {
          transform: 'scale(' + this.element_zoom + ')',
          'max-width': this.dominoPreviewTextW + 'px',
          'max-height': this.dominoPreviewTextW + 'px',
        })
      }

      return styles
    },
    textareaCharacters() {
      return this.product.text.length
    },
    fontFamily() {
      return fontMapping[this.product.font][0]
    },
    fontSize() {
      return fontMapping[this.product.font][1]
    },

    lineHeight() {
      return fontMapping[this.product.font][2]
    },
    editorHeightResizer() {
      if (this.$refs.editorWrapper != null) {
        return this.$refs.editorWrapper.clientHeight
      }
      return this.dominoEditorHeight
    }
  },
  watch: {
    productId: function (newId, oldId) {
      if (newId === oldId) {
        return
      }
      this.setSpinnerState(true)
      const previousBox = JSON.parse(JSON.stringify(this.product)) // copy of old box, drops proxies
      this.saveBox(previousBox)
        .then(() => {
          this.initEditorState(this.productId)
        })
        .catch((error) => {
          console.log('error saving box', error)
        })
        .finally(() => {
          this.setSpinnerState(false)
        })
    },
    temporalText: function (val) {
      val = val.split('\n').slice(0, 3).join('\n')

      if (!this.product.notext) {
        this.product.text = val
      }
    },
    'product.font'() {
      this.fontFamilyMenuActive = false
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState',
      'updateProductAmount',
      'setNotificationActiveState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData'
    }),
    onResize() {
      this.currWidth = window.innerWidth
      if (this.prevWidth == 0 || this.currWidth != this.prevWidth) {
        if (this.$refs.editorRefWidth) {
          let editor = this.$refs.editorRefWidth
          let size = getSize(editor) // uncomment this to enable zoom support
          this.editorScale = size[0] / this.dominoEditorWidth
        }
        this.$nextTick(() => {
          this.initEditorState(this.productId)
        })
        this.prevWidth = this.currWidth
      }
    },
    previewModal() {
      // take snapshot of image and text as it is and show 3d preview
      let self = this
      let text_node = this.$refs.textCanvas

      text_node
        .getImageAsDataUrl()
        .then(function (dataUrl) {
          self.textAsImageUrl = dataUrl // store screenshot of text area
          return self.updateBoxCroppieData(self.product) // update cropped image dataURL
        })
        .then((productObject) => {
          // now both computed properties,
          // croppedImageTexture and croppedImageTexture should be up to data
          self.show3dPreview = true
          return self.$refs.previewModal.showModal()
        })
        .then((amount, ...args) => {
          console.log('3d preview closed', args)
        })
        .catch((error) => {
          console.log('3d preview closed', error)
        })
    },
    initEditorState(productId) {
      let product = this.$store.getters.getProductById(productId)
      this.product = product
      this.temporalText = this.product.text
      this.$nextTick().then(() => {
        this.$refs.croppieRef.refresh()
        this.bindCroppieImage()
      })
    },
    async bindCroppieImage() {
      // we need 1 render cycle to change the parameters of vue-croppie
      // poins is stored as json string in backend
      const points = JSON.parse(this.product.image.croppie_points)
      if (this.product.image.croppie_zoom == null) {
        this.product.image.croppie_zoom = this.croppie_zoom
      }
      this.croppie = await this.$refs.croppieRef.bind({
        url: this.product.image.image,
        points: points,
        orientation: this.product.image.croppie_orientation,
        zoom: this.product.image.croppie_zoom,
        viewport: this.viewportWidthHeight,
        boundary: this.viewportWidthHeight,
      })

      this.$nextTick().then(() => {
        this.croppie_zoom = this.product.image.croppie_zoom
        this.croppie_zoom_min = Number(this.$refs.croppieRef.croppie.elements.zoomer.min)
        this.croppie_zoom_max = Number(this.$refs.croppieRef.croppie.elements.zoomer.max)
      })
    },
    croppieUpdated(val) {
      // signal handler for croppie update events
      let croppie_state = this.$refs.croppieRef.get()
      if (parseFloat(this.croppie_zoom, 10) !== parseFloat(croppie_state.zoom, 10)) {
        this.croppie_zoom = croppie_state.zoom
      }
      this.product = {
        ...this.product,
        image: { ...this.product.image, croppie_zoom: croppie_state.zoom, croopie_points: croppie_state.points },
      }
    },
    result(output) {
      this.cropped = output
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle)
    },
    orientationUpdated(event) {
      this.$nextTick().then(() => {
        this.handleTextPreviewResize()
        this.$refs.croppieRef.refresh()
        this.bindCroppieImage()
      })
    },
    increaseAmount() {
      this.product.amount += 1
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
    },
    decreaseAmount() {
      if (this.product.amount > 1) {
        this.product.amount -= 1
      }
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
    },
    changedAmount() {
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
    },
    toggleNoText() {
      this.product.notext = !this.product.notext
      if (this.product.notext) {
        this.product.text = ''
      } else {
        this.product.text = this.temporalText
      }
    },
    changeFont() {
      this.product.font = this.tmpCandyBoxFont
      this.fontFamilyMenuActive = false
    },    
    updateBoxCroppieData(productObject) {
      return new Promise((resolve, reject) => {
        let options = {
          type: 'base64',
          size: 'original',
          format: 'jpeg',
          circle: false,
        }
        let croppieInst = this.$refs.croppieRef
        croppieInst.result(options, (cropped_image) => {
          productObject.image.image_croppied = cropped_image
          const { orientation, points, zoom } = croppieInst.get()
          productObject.image.croppie_orientation = orientation
          productObject.image.croppie_zoom = zoom
          productObject.image.croppie_points = JSON.stringify(points)
          resolve(productObject)
        })
      })
    },
    updateCroppieZoom(event) {
      this.$refs.croppieRef.setZoom(event.target.value)
    },
    saveBox(productObject) {
      this.textEmpty = false
      let scrollToError = ''
      if (this.product.text == '' && !this.product.notext) {
        this.textEmpty = true
        scrollToError = 'text-editor-text'
        this.setSpinnerState(false)
        if(scrollToError) {
          document.getElementById(`${scrollToError}-desktop`).scrollIntoView({ block: "center", behavior: "smooth" });
          document.getElementById(`${scrollToError}-mobile`).scrollIntoView({ block: "center", behavior: "smooth" });
        }        
        return Promise.reject('Check product')
      } else {
        if (productObject == null) productObject = this.product
        return this.$refs.textCanvas.getImageAsDataUrl().then((dataUrl) => {
          productObject.image.text_image_64 = dataUrl;
          productObject.image.text_image = dataUrl;
          return this.updateBoxCroppieData(productObject)
              .then((updatedBox) => {
                updatedBox['draft'] = false
                return this.$store.dispatch('updateOrder',{'products': [updatedBox]})
              })
              .catch((error) => {
                console.log('network error', error)
              })
        })
      }
    },
    handleTextPreviewResize: debounce(function () {
      // scale editor as page scales
      if (this.isLandscape) {
        let size = document.querySelector('.text-preview').offsetWidth
        this.element_zoom = size / 946
      } else {
        let size = document.querySelector('.text-preview.portrait').offsetWidth
        this.element_zoom = size / 241
      }
    }, 300),
    inputTextarea(event) {
      this.product.text = event
    },
    changeLogo() {
      this.$refs.logoEditorModal
        .showModal()
        .then(() => {
          this.initEditorState(this.productId)
          console.log('ok')
        })
        .catch(() => {
          console.log('error')
        })
    },
    openRemoveModalLogo(box) {
      if (this.product.original_logo_url) {
        this.$refs.deleteModalLogo
        .showModal()
        .then(() => {
          this.setSpinnerState(true)
          let boxData = {
            id: this.productId,
            logo: '',
            logo_croppie_points: null,
            logo_croppie_zoom: null,
            logo_croppie_orientation: null,
            original_logo: ''
          }
          this.$store
            .dispatch('partialUpdateBox', boxData)
            .then(() => {
              this.initEditorState(this.productId)
              this.setSpinnerState(false)
            })
            .catch(() => {
              this.setSpinnerState(false)
            })
        })
        .catch(() => {
        })
      }
    },
    cloneBox() {
      this.setNotificationActiveState({
        'notificationState': true,
        'message': "Tuote on nyt kopioitu ja voit nyt muokata uusinta tuotetta. Lisää oma tekstisi ja/tai kuvasi pakkaukseen."
      })
      let product = this.$store.getters.getProductById(this.productId)
      product['draft'] = false
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      let self = this
      this.cloning = true
      this.saveBox(product)
      .then(() => {
        self.$store.dispatch('cloneDominoBox', {product}).then(() => {
          self.setSpinnerState(false)
          self.setCartSpinnerState(false)
          self.$router.push({name: 'editor', params: {uuid: self.getOrderData().last_product}})
        })
      })
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

$total-width: 1110px;
$croppie-width: 555px;
$croppie-height: 555px;
$croppie-right-off: 0px;
$original-width: 4 00px;
$switch-background: #726f6f;
$switch-background-active: #726f6f;

.userLogo {
  --origWidthLogo: 400;
  --origHeightLogo: 400;
  --wantedWidth: 120px; // change this to change the width
  width: var(--wantedWidth);
  height: calc(var(--origHeightLogo) / var(--origWidthLogo) * var(--wantedWidth));
  background-image: url('../assets/img/domino_logo.png');
  position: absolute;
  z-index: 40;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
}

#croppieBoundary :v-deep(.cr-boundary) {
  margin: 0 !important;
}

#croppieBoundary {
  position: relative;

  .landscape {
    margin-left: auto;
    margin-right: auto;
  }

  &.portrait {
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.editor {
  @media (max-width: 500px) {
    margin-top: 20px;
  }
  .top-editor-section p.underline {
    @media (max-width: 400px) {
      font-size: 12px !important;
    }
  }
  span {
    font-size: 18px;
    @media (max-width: 400px) {
      font-size: 12px;
    }
    font-family: gill-sans-nova;
    color: $primary;
  }

  label {
    font-size: 18px;
    font-family: gill-sans-nova;
    color: $primary;
  }

  .switch[type='checkbox'].is-rounded + label::before {
    border: 1px solid grey;
  }

  label.togglebold {
    color: $primary;
    font-weight: bold;
  }

  .amount-options {
    .svg-wrapper {
      display: inline-block;

      svg {
        font-size: 25px;
        top: 3px;
        position: relative;
        color: $primary;
      }

      svg:hover {
        cursor: pointer;
        color: $primary;
      }
    }

    span {
      width: 70px;
      display: inline-block;
      border: 1px solid gray;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #rotate-wrapper {
    svg.fa-redo-alt {
      display: block;
      margin-left: auto;
      margin-right: auto;
      color: $primary;
    }
  }

  #editor-title {
    text-align: left;
  }

  .is-checkradio[type='radio']:hover:not([disabled]) + label::before {
    border: 0.1rem solid $secondary !important;
  }

  .is-checkradio[type='radio']:checked + label::after {
    background-color: $secondary !important;

    &:hover {
      border: 0.1rem solid $secondary !important;
    }
  }

  #toggle-orientation {
    .left-label {
      @media screen and (min-width: 1720px) {
        margin-right: 50px;
      }
      margin-right: 20px;
      @media screen and (max-width: 500px) {
        margin-right: 10px;
        font-size: 12px !important;
      }
    }

    .right-label {
      padding-left: 4rem;
      @media screen and (min-width: 1720px) {
        padding-left: 6rem;
      }
      @media screen and (max-width: 500px) {
        padding-left: 4rem;
        font-size: 12px !important;
      }
    }
  }
}

#slider-wrapper {
  margin-right: 20px;
  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }
}

.level-right {
  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
}

.level-item p {
  color: $primary;
  font-family: gill-sans-nova;
  margin-top: 33px;
}

.text-preview {
  background-color: $domino-primary;

  &.portrait {
    // display: flex;
    //align-items: center;
    //justify-content: center;
    width: 180px;
    height: 700px;

    .vtext {
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
  }
}

.lang-selection {
  position: absolute;
  right: 12px;
  top: -28px;

  @media screen and (max-width: 500px) {
    top: -3px;
    right: 0px;
  }

  a {
    font-size: 18px;
    text-align: left;
  }

  span.is-medium-important {
    font-size: 1.8rem !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.amount-input-wrapper {
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

#text-box-preview {
  max-height: 110px;
  overflow: hidden;
  overflow-wrap: break-word;
  color: #503120;
}

.checkradio-label {
  font-size: 18px !important;
  @media screen and (max-width: 500px) {
    font-size: 12px !important;
  }
}

.userLogo {
  @media screen and (max-width: 500px) {
    width: 50px !important;
    height: 50px !important;
    bottom: 10px !important;
  }
}

.add-logo:hover {
  cursor: pointer;
}

.add-logo {
  cursor: pointer;
  color: $primary;
  svg {
    font-size: 35px;
  }
  svg:hover {
    cursor: pointer;
  }
  p {
    color: $primary;
    font-family: gill-sans-nova;
  }
  p:hover {
    text-decoration: underline;
  }
}

.remove-logo:hover {
  cursor: pointer;
}

.remove-logo {
  cursor: pointer;
  color: $primary;
  svg {
    font-size: 35px;
  }
  svg:hover {
    cursor: pointer;
  }
  p {
    color: $primary;
    font-family: gill-sans-nova;
  }
  p:hover {
    text-decoration: underline;
  }
}

.editor-top-span {
  border-bottom: 1px solid $primary;
  padding-bottom: 10px;
}

.editor-span-left {
  padding-left: 20px;
  padding-bottom: 20px;
  @media (max-width: 1230px) {
    font-size: 15px !important;
  }
  @media (max-width: 768px) {
    padding-left: 0px !important;
  }
}

.border-bottom {
  margin-left: 1px;
  margin-right: 1px;
  border-bottom: 1px solid $primary;
  @media (max-width: 768px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.border-right {
  border-right: 1px solid $primary;
  height: 111px;
  @media (max-width: 768px) {
    border-bottom: 1px solid $primary;
  }
}

.fa-disabled {
  color: lightgray !important;
  p {
    color: lightgray !important;
  }
}

.fa-disabled:hover {
  p {
    text-decoration: none;
  }
  pointer: auto;
}

.dropdown-content {
  padding: 20px;
  .font-selection-buttons-wrapper {
    display: flex;
    justify-content: center;
    button {
      span {
        color: white;
      }
    }
  }
} 


</style>
