<template>
  <canvas ref="canvasimageCanvas" class="is-hidden"></canvas>
</template>

<script>
const default_options = {
  font: '30px Arial',
  color: 'white',
  fontSize: '30px',
  lineHeight: '35px',
}

export default {
  name: 'canvasImage',
  props: {
    imageWidth: Number,
    imageHeight: Number,
    canvasText: {
      type: String,
      default: '',
    },
    canvasSignature: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      canvas: null,
      canvasContext: null,
      int_options: {},
    }
  },

  created() {
    this.int_options = Object.assign({}, default_options, this.options)
  },
  mounted() {
    this.initCanvas()
    this.cleanCanvas()
    this.writeText()
  },
  watch: {
    canvasText: function (val){
      this.cleanCanvas()
      this.writeText()
    },
    canvasSignature: function (val){
      this.cleanCanvas()
      this.writeText()
    },
    options: function (val){
      this.int_options = Object.assign({}, default_options, this.options)
      this.cleanCanvas()
      this.writeText()
    }
  },
  methods: {
    getCanvasContext() {
      return this.canvasContext
    },
    async getImageAsDataUrl() {
      return this.canvas.toDataURL()
    },
    initCanvas() {
      this.canvas = this.$refs.canvasimageCanvas
      this.canvas.width = this.imageWidth
      this.canvas.height = this.imageHeight
      this.canvasContext = this.canvas.getContext('2d') // draw image in canvas starting left-0 , top - 0     context.drawImage(image, 0, 0, width, height );  //  downloadImage(canvas); need to implement};image.src = blobURL;
      if (this.options['translate'])
        this.canvasContext.translate(this.options['translate'][0], this.options['translate'][1])
      if (this.options['rotate'])
        this.canvasContext.rotate(this.options['rotate'] * Math.PI / 180)
    },
    cleanCanvas(){
      if (this.options.backgroundColor != undefined || this.options.backgroundColor != 'transparent') {
        this.canvasContext.fillStyle = this.options.backgroundColor
      }
      this.canvasContext.fillRect(0, 0, this.imageWidth, this.imageHeight)
    },
    writeText() {
      let ctx = this.canvasContext
      if (this.options.backgroundColor == 'transparent') {
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      }
      ctx.fillStyle = this.int_options.color
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.font = this.canvasFont
      let rows = this.canvasText.split('\n')
      const rowHeight = parseInt(this.int_options.lineHeight)
      const yOffset = (rowHeight * (rows.length -1)) / 2
      for (let row = 0; row < rows.length; row++){
        ctx.fillText(rows[row], this.imageWidth / 2, (this.imageHeight / 2) - yOffset + row * rowHeight)
      }
      if (this.canvasSignature != '') {
        let signatureHeight = 350
        if (this.options.product != undefined && this.options.product == 'bar') {
          signatureHeight = 300
        }
        ctx.font = '35px Felt That'
        const rowHeight = parseInt(this.int_options.lineHeight)
        ctx.fillText(this.canvasSignature, this.imageWidth / 2, signatureHeight)
      }
    },
    redrawCanvas() {
      this.initCanvas()
      this.writeText()
    },
  },
  computed: {
    canvasFont() {
      return `${this.int_options.fontSize} ${this.int_options.font}`
    }
  },
}
</script>

<style scoped></style>
