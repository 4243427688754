import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Upload from '@/views/UploadPage'
import EditorPage from '@/views/EditorPage'
import EditorTabPage from '@/views/EditorTabPage'
import ShipmentPage from '@/views/ShipmentPage'
import CartPage from '@/views/CartPage'
import PaymentPage from '@/views/PaymentPage'
import ThankYouPage from '@/views/ThankYouPage'
import { waitForStorageToBeReady } from '@/utils'
import RulesPage from '@/views/RulesPage'
import FAQPage from '@/views/FAQPage'
import HintsPage from '@/views/HintsPage'
import PricingPage from '@/views/PricingPage'
import OrderDeliveryFormPage from "@/views/OrderDeliveryFormPage"
import OrderDeliveryFormThanksPage from "@/views/OrderDeliveryFormThanksPage"
import FundraisingPage from "@/views/FundraisingPage"
import ScannerPage from "@/views/ScannerPage"


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/upload',
    name: 'upload',
    component: Upload,
  },
  {
    path: '/:uuid/editor',
    name: 'editor',
    component: EditorPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/:uuid/editor-tab',
    name: 'editor-tab',
    component: EditorTabPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/form',
    name: 'form',
    component: OrderDeliveryFormPage
  },
  {
    path: '/thanksform',
    name: 'orderthanks',
    component: OrderDeliveryFormThanksPage,
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: ScannerPage,
  },
  {
    path: '/:uuid/shipment',
    name: 'shipment',
    component: ShipmentPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/:uuid/cart',
    name: 'cart',
    component: CartPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/:uuid/payment',
    name: 'payment',
    component: PaymentPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: ThankYouPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesPage,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQPage,
  },
  {
    path: '/hints',
    name: 'hints',
    component: HintsPage,
  },
      {
    path: '/varainhankinta-fazerin-tuotteilla',
    name: 'fundraising',
    component: FundraisingPage,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingPage,
  },
  // Redirect 404 to landing page
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


router.beforeEach((to, from, next) => {
  if (to.hash.includes('#/')) {
    let newUrl = to.hash.replace(/#/g,'')
    next(newUrl)
  }

  waitForStorageToBeReady().then(() => {
    // eslint-disable-next-line no-unused-vars
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (to.params.uuid !== undefined) {
        store.dispatch('checkExpirationSession', { product_id: to.params.uuid }).then(() => {
          if (store.state.checkExpirationSessionStatusCode != 200) {
            store.commit('resetData')
            next({
              path: '/',
            })
          } else {
            store.dispatch('listDiscountVolumes', {}).then(() => {
              store.dispatch('listShippingPrices', {}).then(() => {
                store.dispatch('listPolicies', {}).then(() => {
                  next()
                })
              })
            })
          }
        })
      } else {
        if (to.name == 'thanks') {
          next()
        } else {
          next({
            path: '/',
          })
        }
      }
    } else {
      next()
    }
  })
})

export default router
