<template>
  <div id="textarea-wrapper">
    <textarea
        :id="name"
        :disabled="notext"
        v-model="text"
        maxlength="{{maxLength}}"
        :placeholder="notext ? '' : 'Kirjoita teksti'"
        :style="fontStyle">
    </textarea>
    <p v-if="maxRows > 1">Huom. Enintään {{maxRows}} riviä tekstiä</p>
    <span id="charNum" class="gray">{{ textareaCharacters }}/{{maxCharactersPerRow*maxRows}}</span>
    <span v-if="maxRows > 1" id="charNumFirstRow" class="gray">{{ textareaCharactersFirstRow }}/{{maxCharactersPerRow}}</span>
    <span v-if="maxRows >= 2" id="charNumSecondRow" class="gray">{{ textareaCharactersSecondRow }}/{{maxCharactersPerRow}}</span>
    <span v-if="maxRows >= 3" id="charNumThirdRow" class="gray">{{ textareaCharactersThirdRow }}/{{maxCharactersPerRow}}</span>
    <span v-if="maxRows >= 4" id="charNumFourthRow" class="gray">{{ textareaCharactersFourthRow }}/{{maxCharactersPerRow}}</span>
    <span v-if="maxRows >= 5" id="charNumFifthRow" class="gray">{{ textareaCharactersFifthRow }}/{{maxCharactersPerRow}}</span>
    <span v-if="maxRows >= 6" id="charNumSixthRow" class="gray">{{ textareaCharactersSixthRow }}/{{maxCharactersPerRow}}</span>
  </div>
</template>

<script>


import emojiStrip from "emoji-strip";

export default {
  name: 'TextEditor',
  props: {
    notext: {type: Boolean, default: false},
    producttext: {type: String, default: ''},
    maxRows: {type: Number, default: 3},
    maxCharactersPerRow: {type: Number, default: 40},
    fontStyle: {type: Object},
    name: {type: String, default: 'pm-text-area'}
  },
  data(){
    return {
      text: '',
      cursorPosition: null
    }
  },
  computed: {
    maxLength: function () {
      return this.maxRows * this.maxCharactersPerRow
    },
    textareaCharacters: function () {
      let numberOfHiddenChars = this.text.split("\n")
      return this.text.length - numberOfHiddenChars.length + 1
    },
    textareaCharactersFirstRow: function () {
      let rows = this.text.split("\n")
      if (rows[0] != undefined) {
        return rows[0].length
      }
      return 0
    },
    textareaCharactersSecondRow: function () {
      let rows = this.text.split("\n")
      if (rows[1] != undefined) {
        return rows[1].length
      }
      return 0
    },
    textareaCharactersThirdRow: function () {
      let rows = this.text.split("\n")
      if (rows[2] != undefined) {
        return rows[2].length
      }
      return 0
    },
    textareaCharactersFourthRow: function () {
      let rows = this.text.split("\n")
      if (rows[3] != undefined) {
        return rows[3].length
      }
      return 0
    },
    textareaCharactersFifthRow: function () {
      let rows = this.text.split("\n")
      if (rows[4] != undefined) {
        return rows[4].length
      }
      return 0
    },
    textareaCharactersSixthRow: function () {
      let rows = this.text.split("\n")
      if (rows[5] != undefined) {
        return rows[5].length
      }
      return 0
    },
  },
  watch: {
    producttext(newValue, oldValue) {
      this.text = newValue
    },
    text(newValue, oldValue) {
      if (this.cursorPosition != null) {
        this.setCursorPos(
          document.getElementById(this.name),
          this.cursorPosition.start,
          this.cursorPosition.end
          )
          this.cursorPosition = null
        }
        let updatedText = this.text
        let lines = newValue.split("\n") //splitting the line by \n so we can get num. of lines
        if (lines.length > this.maxRows) { //if num. og lines > maxRows
          updatedText = oldValue // prevent characters from appearing (setting it back to the last value saved)
        } else { //if num of lines <= maxRows
          for (let i = 0; i < lines.length; i++) { //loop to run till the num of lines
              if (lines[i].length > this.maxCharactersPerRow) { //if the current line exceeded maxCharactersPerRow
                  // let's see if we can wrap and fill next row
                  if (i !== this.maxRows - 1) { // if we are in the last line we cannot wrap
                    lines[i] = lines[i].split(" ")
                    let lastWord = lines[i].pop() // get last word
                    // get next line
                    let nextLine = ''
                    if (lines[i+1] != undefined) {
                      nextLine = lines[i+1]
                    }
                    // let's see if word fits in the next line, +1 is for additional white space
                    // if the next line is full we need to make space there too
                    // if all the lines are full we need to truncate
                    if (nextLine.length + lastWord.length + 1 <= this.maxCharactersPerRow) {
                      nextLine = lastWord + ' ' + nextLine
                      lines[i+1] = nextLine
                      lines[i] = lines[i].join(" ")
                      updatedText = lines.join("\n")
                      // remember cursor position
                      this.cursorPosition = this.getCursorPos(document.getElementById(this.name))
                      break
                    } else { // search for space
                      if (i+2 !== this.maxRows - 1) {
                        /*for (let j = i + 2; j < lines.length; j++) {
                          if (lines[j].length > this.maxCharactersPerRow) {
                            lastWord
                          }
                        }*/
                        nextLine = lastWord + ' ' + nextLine
                        lines[i+1] = nextLine
                        lines[i] = lines[i].join(" ")
                        updatedText = lines.join("\n")
                        // remember cursor position
                        this.cursorPosition = this.getCursorPos(document.getElementById(this.name))
                      } else {
                        // no more space, we truncate
                        updatedText = oldValue // prevent characters from appearing (setting it back to the last value saved)
                        this.text = updatedText
                        this.$emit('inputtextarea', updatedText);
                        return
                      }
                    }/*else {
                      updatedText = oldValue // prevent characters from appearing (setting it back to the last value saved)
                      break
                    }*/
                  } else {
                    updatedText = oldValue // prevent characters from appearing (setting it back to the last value saved)
                    break
                  }
              } else if (lines[i].length <= this.maxCharactersPerRow && i == lines.length - 1) { //checking if the last line's chars are less than or equal to maxCharactersPerRow
                  updatedText = newValue; //saving the current value in the dummy string so we can use this string to reset the textarea value
              }
              else {
                  updatedText = oldValue
              }

          }
      }
      this.text = updatedText
      //newValue = "pepe"
      this.$emit('inputtextarea', updatedText);
    }
  },
  methods: {
    onPaste(event) {
      this.text = wrap(this.text, this.maxRows);
    },
    sanitizeFont(text) {
      text = emojiStrip(text);
      // remove anything not supported by fazer font
      text = text.replace(/[^a-zA-Z0-9äöåıÄÖÅ¢¨«»¯${}[\]()|%&#!¡?"'`´¸><.,;:=/*+_ ^ˆˇ˘˙˚˛˝‘’“”₤€\r\n-]/g, '');
      return text;
    },
    getCursorPos(input) {
      if ("selectionStart" in input && document.activeElement == input) {
          return {
              start: input.selectionStart,
              end: input.selectionEnd
          };
      }
      else if (input.createTextRange) {
          let sel = document.selection.createRange()
          if (sel.parentElement() === input) {
              let rng = input.createTextRange()
              rng.moveToBookmark(sel.getBookmark())
              for (let len = 0; rng.compareEndPoints("EndToStart", rng) > 0; rng.moveEnd("character", -1)) {
                  len++;
              }
              rng.setEndPoint("StartToStart", input.createTextRange())
              for (let pos = { start: 0, end: len }; rng.compareEndPoints("EndToStart", rng) > 0; rng.moveEnd("character", -1)) {
                  pos.start++
                  pos.end++
              }
              return pos;
          }
      }
      return -1;
    },
    setCursorPos(input, start, end) {
      if (arguments.length < 3) end = start
      if ("selectionStart" in input) {
        setTimeout(function () {
          input.selectionStart = start
          input.selectionEnd = end
        }, 1);
      } else if (input.createTextRange) {
        let rng = input.createTextRange()
        rng.moveStart("character", start)
        rng.collapse()
        rng.moveEnd("character", end - start)
        rng.select()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/sass/mybulma';

  #textarea-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    textarea {
      padding: 7px 7px 7px 7px;
      resize: none;
      height: 80px;
      width: 100%;
    }

    span {
      color: $primary;
      font-family: gill-sans-nova;
    }
    p {
      color: $primary;
      font-family: gill-sans-nova;
    }
  }

  #charNum {
    position: absolute;
    bottom: 6px;
    right: 5px;
    font-size: 20px;
    @media screen and (max-width: 500px) {
      font-size: 15px;
      //bottom: 30px;
    }
  }

  #charNumFirstRow {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
  }

  #charNumSecondRow {
    position: absolute;
    top: 23px;
    right: 5px;
    font-size: 15px;
  }

  #charNumThirdRow {
    position: absolute;
    top: 41px;
    right: 5px;
    font-size: 15px;
  }

  #charNumFourthRow {
    position: absolute;
    top: 59px;
    right: 5px;
    font-size: 15px;
  }

  #charNumFifthRow {
    position: absolute;
    top: 77px;
    right: 5px;
    font-size: 15px;
  }

  #charNumSixthRow {
    position: absolute;
    top: 97px;
    right: 5px;
    font-size: 15px;
  }

  span.gray {
    color: $primary;
  }
</style>
