<template>
  <div class="cart">
    <SectionHeader></SectionHeader>
    <asModalDialog :showCancel="false" ref="linkModal" okButtonText="OK">
      <div class="field has-addons">
        <div class="control is-expanded">
          <input
            class="input is-medium"
            type="text"
            contenteditable="false"
            readonly="readonly"
            v-model="linkUri"
            ref="textToCopy"
          />
        </div>
        <div class="control">
          <a class="button is-primary is-medium no-radius">
            <span class="icon is-large is-right is-clickable" @click="copyToClipboard">
              <i class="fas fa-copy fa-2x"></i>
            </span>
          </a>
        </div>
      </div>
    </asModalDialog>

    <div class="section hero is-fullheight pt-0">
      <div class="container has-background-white">
        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section pb-0">
              <h1>Ostoskori</h1>
            </div>
          </div>
        </div>

        <div class="columns mt-0" v-if="!getCalendarMode()">
          <div class="column is-12">
            <div class="section delivery-address-selector pb-0 pt-0">
              <h3 class="title is-size-4">Valitse toimitustavat tuotteille</h3>
              <label>
                <input type="radio" id="same_address" value="ALL_TO_SAME_ADDRESS" v-model="delivery_type">
                <span class="graphical-radio"></span>
                Kaikki tuotteet toimitetaan yhteen toimitusosoitteeseen Suomessa
              </label><br>
              <label v-if="!hasDominoOrKfProduct">
                <input type="radio" id="different_addresss" value="ALL_TO_MULTIPLE_ADDRESS" v-model="delivery_type">
                <span class="graphical-radio"></span>
                Fazer lähettää tuotteet yksittäin suoraan jokaisella vastaanottajalle Suomessa (Pyydämme osoitetiedot tilauksen jälkeen)
              </label><br>
              <label>
                <input type="radio" id="mixed" value="MIXED" v-model="delivery_type">
                <span class="graphical-radio"></span>
                Haluan valita rivikohtaisesti toimitusosoitteen/-tavan tai osa tuotteista toimitetaan ulkomaille. Voit syöttää toimitustiedot erikseen kullekin riville.
              </label><br>
            </div>
          </div>
        </div>

        <div class="columns mt-0">
          <div class="column is-12">
            <div class="section pt-0">
              <h3 class="title is-size-4">Tilauksen sisältö</h3>
              <product-list ref="productData" :order-delivery-type="delivery_type"/>
            </div>

            <div class="section mt-0 p-0">
              <div class="columns">
                <div class="column">
                  <p class="place-at-bottom mb-3 pt-3 border-top">
                    <i class="fas fa-save"></i>
                    Keskeneräinen tilaus säilyy palvelussa kaksi viikkoa, jona aikana voit muokata tilausta.
                    Muutokset tilaukseen tallennetaan automaattisesti ja ovat nähtävillä tämän osoitteen kautta kaksi
                    viikkoa. <a @click="openModal" class="is-underlined">Kopioi tai jaa linkki.</a>
                  </p>
                </div>
              </div>
              <div class="columns bottom-info">
                <div class="column is-5 is-hidden-mobile">
                  <button @click="goBackToEditor" class="button back-editor-button is-primary is-wide" >
                    <i class="fas fa-arrow-left mr-5"></i>
                    Takaisin
                  </button>
                </div>
                <div class="column is-12-mobile pb-0 is-hidden-tablet">
                  <button @click="goBackToEditor" class="button is-primary is-fullwidth">
                    <i class="fas fa-arrow-left mr-5"></i>
                    Takaisin
                  </button>
                </div>
                <div class="column is-5 is-offset-2 bottom-aligned pb-0 right-button-column" v-if="!getCalendarMode()">
                  <button class="button is-primary right-button is-block is-wide"
                         v-if="!hasInternationalDeliveries"
                          @click="toShipmentPage(false)"
                          :disabled="this.products.length == 0
                           || (this.$store.getters.getTotalAmount('domino') != 0 && this.$store.getters.getTotalAmount('domino') < 24)
                           || (this.$store.getters.getTotalAmount('blue') != 0 && this.$store.getters.getTotalAmount('blue') < 20)
                           || (this.$store.getters.getTotalAmount('bar') != 0 && this.$store.getters.getTotalAmount('bar') < 50)
                           || (this.$store.getters.getTotalAmount('marianne') != 0 && this.$store.getters.getTotalAmount('marianne') < 50)
                           || (this.$store.getters.getTotalAmount('custom-candy-box') != 0 && this.$store.getters.getTotalAmount('custom-candy-box') < 50)
                           || (this.$store.getters.getTotalAmount('calendar-b2b') != 0 && this.$store.getters.getTotalAmount('calendar-b2b') < 20)
                           || (this.delivery_type == 'MIXED' && this.$refs.productData != undefined && (this.$refs.productData.hasProductsWithoutAddress || this.$refs.productData.hasProductsWithoutMailboxSelected))"
                  >
                    <p>Jatka asiakastietoihin <i class="fas fa-arrow-right"></i></p>
                    <p>(HENKILÖASIAKAS)</p>
                  </button>
                  <button class="button is-primary mt-3 right-button is-block is-wide"
                          @click="toShipmentPage(true)"
                          :disabled="this.products.length == 0
                           || (this.$store.getters.getTotalAmount('domino') != 0 && this.$store.getters.getTotalAmount('domino') < 24)
                           || (this.$store.getters.getTotalAmount('blue') != 0 && this.$store.getters.getTotalAmount('blue') < 20)
                           || (this.$store.getters.getTotalAmount('bar') != 0 && this.$store.getters.getTotalAmount('bar') < 50)
                           || (this.$store.getters.getTotalAmount('marianne') != 0 && this.$store.getters.getTotalAmount('marianne') < 50)
                           || (this.$store.getters.getTotalAmount('custom-candy-box') != 0 && this.$store.getters.getTotalAmount('custom-candy-box') < 50)
                           || (this.$store.getters.getTotalAmount('calendar-b2b') != 0 && this.$store.getters.getTotalAmount('calendar-b2b') < 20)
                           || (this.delivery_type == 'MIXED' && this.$refs.productData != undefined && (this.$refs.productData.hasProductsWithoutAddress || this.$refs.productData.hasProductsWithoutMailboxSelected))"
                  >
                    <p>Jatka asiakastietoihin <i class="fas fa-arrow-right"></i></p>
                    <p>(YRITYS / YHDISTYS)</p>
                  </button>
                  <span style="color: red; text-align: center; width: 350px;" v-if="this.delivery_type == 'MIXED' && this.$refs.productData != undefined && (this.$refs.productData.hasProductsWithoutAddress || this.$refs.productData.hasProductsWithoutMailboxSelected)">Lisää ja tallenna puuttuva toimitustieto</span>
                </div>

                <div class="column is-5 is-offset-2 is-hidden-mobile" v-if="getCalendarMode()">
                  <button class="button is-primary is-block is-wide right-button-calendar" style="margin-left: auto;"
                          @click="toShipmentPage(false)"
                          :disabled="this.$store.getters.getTotalAmount('calendar') < 1"
                  >
                    <p>Jatka toimitustietoihin
                    <i class="fas fa-arrow-right mr-5"></i></p>
                  </button>
                </div>
                <div class="column is-12 is-hidden-tablet" v-if="getCalendarMode()">
                  <button class="button is-primary is-fullwidth" style="margin-left: auto;"
                          @click="toShipmentPage(false)"
                          :disabled="this.$store.getters.getTotalAmount('calendar') < 1"
                  >
                    <p style="color: white; width: 100%; text-align: right; margin-right: 15px;">Jatka toimitustietoihin</p>
                    <i class="fas fa-arrow-right mr-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import productList from '@/components/shipment/productList'
import SectionHeader from '@/components/SectionHeader'
import asModalDialog from '@/components/helpers/asModalDialog'
import Footer from '@/components/Footer'
import {useMeta} from "vue-meta"

import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'ShoppingCartPage',
  data(){
    return {
      triggerSubmit: false,
      accept_terms: false,
      accept_rules: false,
      order_needs_to_update: false,
      delivery_type: 'ALL_TO_SAME_ADDRESS',
    }
  },
  emits: ['submit-form'],
  components: {
    SectionHeader,
    productList,
    asModalDialog,
    Footer
  },
  mounted() {
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    }
    this.order_needs_to_update = false
    this.getProducts()
    if (this.getOrderData() == null) {
      this.$router.push('/')
    }
    this.delivery_type = this.getOrderData().delivery_type
    window.scrollTo(0, 0)
    this.listDiscountVolumes()
    this.listShippingPrices()
    this.listFreightPrices()
    let product = this.$store.getters.getProductById(this.getOrderData().last_product)
    // check if new product is blue box or domino box and it hadnt any of those before
    // then we need to force delivery option MIXED
    let found_old_domino_blue_box = false
    for (let i = 0; i < this.products.length; i++) {
      if (!this.products[i].draft && this.products[i].id != this.getOrderData().last_product) {
        if (this.products[i].product.name == 'domino' || this.products[i].product.name == 'blue' || this.products[i].product.name == 'calendar-b2b') {
          found_old_domino_blue_box = true
        }
      }
    }
    // if delivery method B and a blue box or domino box has been added then the old products have to switch their delivery method from B to C2
    if (this.delivery_type == 'ALL_TO_MULTIPLE_ADDRESS' && (product.product.name == 'blue' || product.product.name == 'domino' || product.product.name == 'calendar-b2b')) {
      for (let i = 0; i < this.products.length; i++) {
        this.products[i].address_type = 'FINLAND_MAILBOX'
        this.products[i].mailbox_selected = true
        this.order_needs_to_update = true
      }
    }
    if (!found_old_domino_blue_box && (product.product.name == 'blue' || product.product.name == 'domino' || product.product.name == 'calendar-b2b') && ! this.allProductsAreDominoOrKfProduct) {
      this.delivery_type = 'MIXED'
      this.order_needs_to_update = true
    }
    if (this.order_needs_to_update) {
      this.updateOrder({'products': this.products, 'delivery_type': this.delivery_type})
      this.order_needs_to_update = false
    }
  },
  computed: {
    products() {
      return this.getSavedItems()
    },
    linkUri() {
      return window.location
    },
    hasDominoOrKfProduct() {
      for (let i = 0; i < this.products.length; i++) {
        if (!this.products[i].draft) {
          if (this.products[i].product.name == 'domino' || this.products[i].product.name == 'blue' || this.products[i].product.name == 'calendar-b2b') {
            return true
          }
        }
      }
      return false
    },
    allProductsAreDominoOrKfProduct() {
      for (let i = 0; i < this.products.length; i++) {
        if (!this.products[i].draft) {
          if (this.products[i].product.name != 'domino' && this.products[i].product.name != 'blue' && this.products[i].product.name != 'calendar-b2b') {
            return false
          }
        }
      }
      return true
    },
    hasInternationalDeliveries() {
      if (this.delivery_type == 'MIXED') {
        for (let i = 0; i < this.products.length; i++) {
          if (!this.products[i].draft) {
            if ((this.products[i].address_type == 'INTERNATIONAL_FREIGHT' || this.products[i].address_type == 'INTERNATIONAL_MAILBOX')) {
              return true
            }
          }
        }
      }
      return false
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData',
      getSavedItems: 'getSavedItems',
      getCalendarMode: 'getCalendarMode'
    }),
    ...mapActions([
      'partialUpdateOrder',
      'updateOrder',
      'listDiscountVolumes',
      'listShippingPrices',
      'listFreightPrices',
      'getProducts'
    ]),
    goBackToEditor() {
      this.setSpinnerState(true)
      this.updateOrder({'products': this.products, 'delivery_type': this.delivery_type}).then(() => {
        if (this.getSavedItems().length == 0)
        {
          this.setSpinnerState(false)
          this.$router.push({name: 'Home'})
        }
        else {
          let product = this.$store.getters.getProductById(this.getOrderData().last_product)
          this.setSpinnerState(false)
          // if thumbnail is empty it means it didnt go through the editor
          if (product.product.name == 'bar' || product.product.name == 'marianne' || product.product.name == 'custom-candy-box') {
            this.$router.push(
                {
                  name: 'editor-tab', params:
                      {
                        uuid: product.id,
                        product: product.product.name
                      }
                }
            )
          } else {
            this.$router.push(
                {
                  name: 'editor', params:
                      {
                        uuid: product.id,
                        product: product.product.name
                      }
                }
            )
          }
        }
      })
    },
    submitForm() {
      if (this.accept_terms && this.accept_rules) {
        this.$refs.shipmentAddress.submitForm()
      }
    },
    toShipmentPage(isCompany) {
      this.$store.commit("setIsCompany", isCompany)
      this.setSpinnerState(true)
      this.updateOrder({'products': this.products, 'delivery_type': this.delivery_type}).then(() => {
        this.$router.push({name: 'shipment', params: {uuid: this.getOrderData().id}})
        this.setSpinnerState(false)
      })
    },
    openModal() {
      this.updateOrder({'products': this.products, 'delivery_type': this.delivery_type}).then(() => {
        this.$refs.linkModal.showModal()
      })
    }
  },
   watch: {
     delivery_type: function (new_val, old_val) {
       this.setSpinnerState(true)
       if (new_val == 'ALL_TO_MULTIPLE_ADDRESS') {
         for (let i = 0; i < this.products.length; i++) {
           this.products[i].delivery_address = null
           this.products[i].address_type = null
         }
         this.updateOrder({'products': this.products, 'delivery_type': new_val}).then(() => {
           this.setSpinnerState(false)
         })
       } else if (new_val == 'ALL_TO_SAME_ADDRESS') {
         for (let i = 0; i < this.products.length; i++) {
           this.products[i].delivery_address = null
           this.products[i].address_type = null
         }
         this.updateOrder({'products': this.products, 'delivery_type': new_val}).then(() => {
           this.setSpinnerState(false)
         })
       } else {
         this.partialUpdateOrder({'delivery_type': new_val}).then(() => {
           this.setSpinnerState(false)
         })
       }
     }
   }
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';
p {
  font-size: 16px;
  font-family: gill-sans-nova;
}

span {
  font-size: 16px;
  font-family: gill-sans-nova;
}

section {
  font-family: gill-sans-nova;
}

.bottom-aligned {
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.place-at-bottom {
  margin-top: auto;
  font-size: 18px;
  font-family: gill-sans-nova;
}

.is-danger {
  color: red;
}

.is-danger-border {
  border: 1px solid red;
  padding: 5px;
}

.cart {
  background-color: white;
}

.back-editor-button {
  position: absolute;
  bottom: 0;
}
.bottom-info {
  p {
    color: $primary;
  }
}

.right-button-column {
  @media only screen and (min-width: 1701px) {
    margin-left: 34.667% !important;
  }
}

.right-button {
  height: 65px;
  text-align: right;
  @media only screen and (min-width: 1701px) {
    max-width: 350px;
  }
  svg {
    margin-left: 5px;
  }
  p {
    color: white;
    font-family: gill-sans-nova;
  }
}

.right-button-calendar {
  text-align: right;
  max-width: 350px;
  svg {
    margin-left: 5px;
  }
  p {
    color: white;
    font-family: gill-sans-nova;
  }
}

.border-top {
  border-top: 1px solid $primary;
  font-size: 15px;
  color: $primary;
  svg {
    font-size: 40px;
    display: block;
    margin-bottom: 5px;
  }
}

.no-radius {
  border-radius: 0px !important;
}

input[type="radio"] {
  display: none;
}

.graphical-radio {
  background: white;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #15256B;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
  position: relative;
  top: 4px;
}

input[type="radio"]:checked + .graphical-radio {
  background: #15256B;
}

.delivery-address-selector {
  label {
    font-size: 23px;
    @media (max-width: 768px) {
      font-size: 15px;
    }
    padding-bottom: 5px;
    display: inline-block;
    color: black;
  }
  label:hover {
    cursor: pointer;
  }
}
</style>
