<template>
  <div>
    <div class="pane">
      <div class="three-dee-component">
        <ThreeDee
          :uri="textureUri"
          :materialName="materialName"
          :textures="textures"
          :textureWidth="textureWidth"
          :textureHeight="textureHeight"
          :options="threeDeeOptions"
          :debug="false"
          v-on:renderready="ready()"
        >
          <ThreeDeeOverlay :overlayMessage="'Kierrä kuvaa raahaamalla ja zoomaa kuvaa hiiren rullalla'"/>
        </ThreeDee>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {ThreeDee, ThreeDeeOverlay} from '@packagemedia/vue-three-dee'
import {mapMutations} from "vuex";

export default {
  name: 'ServeDev',
  components: {ThreeDee, ThreeDeeOverlay},
  props: {
    textureUri: {
      required: true
    },
    materialName: {
      required: true
    },
    textures: {
      required: true
    },
    textureWidth: {
      required: true
    },
    textureHeight: {
      required: true
    },
    productZoom: {
      type: Object,
      default: () => (
        { min: 250, max: 250 }
      )
    },
  },

  setup(props) {
    const threeDeeOptions = computed(() => {
        return {
          autoRotate: false,
          autoRotateSpeed: -10,
          background: false,
          backgroundColor: '#FFFFFF',
          cameraDistance: {min: 180, max: 180,},
          grid: false,
          kiosk: false,
          preset: null,
          screenSpacePanning: false,
          skeleton: false,
          wireframe: false,
          zoom: 1,
        }
      }
    )

    return {
      threeDeeOptions
    }
  },

  mounted() {
    this.setSpinnerState(true)
  },
  methods: {
    ...mapMutations(['setSpinnerState']),
    ready() {
      this.setSpinnerState(false)
    }
  }

}

</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.relative {
  position: relative;
  height: 200px;
  width: 200px;
}

body {
  color: navy;

  h1 {
    font-size: 3rem;
    margin: 2rem 1rem;
  }

  .pane {
    //display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .three-dee-component {
    width: 100%;
    height: 50vh;
    min-height: 500px;
  }

}
</style>
