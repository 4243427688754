<template>
  <div class="footer-wrapper hero-foot">
    <p>© Oy Fazer Ab. All rights reserved. Terms of use Privacy policy</p>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>

  @import '../assets/sass/mybulma';

  .footer-wrapper {
    height: 73px;
    background-color: $primary;
    width: 100%;
    p {
      color: white;
      text-align: center;
      padding-top: 25px;
      font-family: gill-sans-nova;
      font-weight: bold;
      font-size: 15px;
    }
  }
</style>