<template>
  <div class="rules">
    <SectionHeader></SectionHeader>
    <div class="is-full pt-0">
      <img class="is-fullwidth" :src="`${publicPath}imgs/varainhankintahero.png`"/>
    </div>
    <div class="section">
      <div class="container is-relative fix-position">
        <div class="columns">
          <div class="column is-full mb-6">
            <div class="content is-medium">
              <h1 class="has-text-centered">Varainhankinta Fazerin kustomoiduilla herkkupaketeilla</h1>

              <h3>Varainhankinta, mitä se on?</h3>
              <p>
                Varainhankinta on tapa kerätä varoja erilaisille yhdistyksille, seuroille ja koululuokille.
                Varainhankinnan tavoitteena on kattaa toimintaan liittyviä kustannuksia ja näin mahdollistaa toiminta yhä
                useammalle taloudellisesta tilanteesta riippumatta.
              </p>

              <h3>Kuka voi tehdä varainhankintaa?</h3>

              <p>
                Varainhankintaa voivat tehdä koululuokat, luokkatoimikunnat, vanhempainyhdistykset, vanhempaintoimikunnat
                sekä yleishyödylliset yhdistykset, seurat sekä urheilujoukkueet, jotka keräävät varoja yhteisen toiminnan
                kattamiseen.
              </p>

              <p>
                Varainhankinta yleishyödylliselle yhteisölle ei ole verottajan mukaan elinkeinotoiminnaksi katsottavaa
                toimintaa, kunhan varainhankinta ei ole selkeää elinkeinon harjoittamista ja tuotot pysyvät pieninä.
                Esimerkiksi koululuokkien varainhankinkinnan tuotot ovat pääsääntöisesti aina verovapaita.
              </p>

              <h3>Varainhankinta Fazerin herkuilla</h3>

              <p class="mb-4">
                Custom Fazer -palvelumme on mukana tukemassa seurojen ja yhdistysten toimintaa. Kun tilaat meiltä
                tuotteita varainkeruuta varten, käytä tilatessa koodia: VARAINHANKINTA ja saat 15 % alennuksen
                tilauksestasi. Katso hinnasto <a @click="$router.push({name: 'pricing'})">täältä</a>.
              </p>

              <div class="columns middle-section">
                <div class="column is-3">
                  <figure class="image">
                    <img :src="`${publicPath}imgs/Custom-fazer-varainkeruu-domino@2x.png`">
                  </figure>
                  <p>Domino-paketti omalla kuvalla ja logolla</p>
                  <button
                      class="upload-button button is-primary"
                      @click="goToEditor('domino')"
                    >
                      <span>Tilaa</span>
                  </button>
                </div>
                <div class="column is-3">
                  <figure class="image">
                    <img :src="`${publicPath}imgs/Custom-fazer-varainkeruu-kf270@2x.png`">
                  </figure>
                  <p>Karl Fazer -konvehtirasia omalla tekstillä</p>
                  <button
                      class="upload-button button is-primary"
                      @click="goToEditor('fazer')"
                    >
                      <span>Tilaa</span>
                  </button>
                </div>
                <div class="column is-3">
                  <figure class="image">
                    <img :src="`${publicPath}imgs/Custom-fazer-varainkeruu-marianne@2x.png`">
                  </figure>
                  <p>Marianne-paketti omalla tervehdyksellä</p>
                  <button
                      class="upload-button button is-primary"
                      @click="goToEditor('marianne')"
                    >
                      <span>Tilaa</span>
                  </button>
                </div>
                <div class="column is-3">
                  <figure class="image">
                    <img :src="`${publicPath}imgs/Custom-fazer-varainkeruu-suklaalevy@2x.png`">
                  </figure>
                  <p>Karl Fazer -suklaalevy omalla viestillä</p>
                  <button
                      class="upload-button button is-primary"
                      @click="goToEditor('fazer-bar')"
                    >
                      <span>Tilaa</span>
                  </button>
                </div>
              </div>

              <p>
                Fazerin herkullisia klassikkotuotteita on mukava tarjota ystäville, tuttaville ja sukulaisille, sillä ne
                maistuvat taatusti jokaiselle. Valitsemalla laadukas varainkeruutuote kauppaa syntyy ja tuottoa karttuu
                helpommin. Valikoimastamme löydät neljä herkullista vaihtoehtoa: Domino-paketit, Karl Fazer -konvehtirasiat,
                Marianne-paketit ja Karl Fazer -suklaalevyt.
              </p>

              <p>
                Useampi ostaa jos tarjolla on heille sopivia tuotteita. Herkkuvalikoimastamme löydät myös vegaanisia
                herkkuvaihtoehtoja (Domino ja Marianne).
              </p>

              <p>
                Varainkeruu voidaan toteuttaa teille sopivana ajankohtana, sillä tuotteet eivät ole sesonkeihin sidottuja.
              </p>

              <h3>Personoidut varainkeruuherkut Custom Fazerista</h3>

              <p>
                Custom Fazer -palvelussa voit personoida Fazerin klassikkoherkut helposti oman näköiseksesi.
                riippuen voit itse suunnitella tuotteen tekstit, lisätä kuvan sekä logon. Oma Domino -pakkaukseen voit
                lisätä kuvan vaikka jokaisesta pelaajasta. Muihin tuotteisiin voit personoida tekstiä omaan tarpeeseesi
                sopivaksi, esimerkiksi “Kiitos tuesta!” -teksti pakkauksen eteen ja pidempi saatekirje pakkauksen taakse.
                Minimitilausmäärä on riippuvainen valitusta tuotteesta.
              </p>

              <p>
                Tuotteiden toimitusaika on noin 2-3 viikkoa. Lähetämme laskun kun tuotteet ovat valmistuneet ja lähteneet
                toimitukseen. Laskun maksuaika on 14 päivää. Tilauksen voi myös maksaa maksukortilla Custom Fazer
                -palvelussa.
              </p>

              <h3>Tuottojen kerryttäminen</h3>

              <p>
                Esimerkiksi jos tuotteen tuotto on 2 € / kpl ja myyjiä on 30, joista jokainen myy 30 kpl, niin varoja
                kertyy 1 800 €. Mitä enemmän saatte myytyä, sitä edullisempi on hinta tuotteelle ja sitä helpommin saatte
                kerrytettyä varoja.
              </p>

              <h3>Vinkkejä myyntiin</h3>
              <p>
                - Harjoitelkaa myyntiargumentteja ja tuotteiden esittelyä etukäteen.
              </p>
              <p>
                - Kertokaa ostajalle, että hän tukee toimintaanne. Kertokaa myös, mihin varainkeruuvarat on tarkoitus hyödyntää.
              </p>
              <p>
                - Ostaja saa tuttuja herkkuja tunnetuilta tuotemerkeilta, mutta paketissa on kuitenkin oma persoonallinen sävy.
              </p>
              <p>
                - Hyödyntäkää digitaalista mediaa – näin tavoitatte laajemman yleisön!
              </p>

              <h3>Ota yhteyttä</h3>

              <p>
                Me Fazerilla olemme aina valmiita auttamaan teidät alkuun. Olkaa yhteydessä asiakaspalveluumme ja
                kuvailkaa tarvettanne, niin voimme antaa vinkkejä ja tarkempia tietoja.
              </p>

              <p>
                Mikäli sinulla on kysyttävää, ota rohkeasti yhteyttä <a @click="$router.push({name: 'form'})">yhteydenottolomakkeella</a>
              </p>


              <button
                class="upload-button button is-primary mt-6"
                @click="$router.push({name: 'Home'})"
              >
                <span>Takaisin</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import { useMeta } from 'vue-meta'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {setAxiosAuthToken} from "@/utils";

let initial_errors = {
  non_field_errors: [],
}

export default {
  name: 'HintsPage.vue',
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  components: {
    SectionHeader,
    Footer
  },
  setup () {
    useMeta({
      title: 'Varainhankinta Fazerin tuotteilla – Custom Fazer',
      description: 'Varainhankinta yhdistyksille, seuroille ja koululuokille Fazerin herkuilla onnistuu varmasti. Lue lisää  ja hyödynnä varainhankinta-alennus.'
    })
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData'
    }),
    ...mapActions([
      'login',
      'getProducts',
      'postFazerBar',
      'postMarianneBox',
      'postCustomCandyBox',
      'postFazerBox'
    ]),
    routerParamToProductId(param) {
      if (param == 'fazer-bar') {
        return 'bar'
      } else if (param == 'marianne') {
        return 'marianne'
      } else if (param == 'custom-candy-box') {
        return 'custom-candy-box'
      } else if (param == 'fazer') {
        return 'blue'
      }
    },
    getPostFunction(item) {
      const products = {
        'fazer-bar': this.postFazerBar,
        'marianne': this.postMarianneBox,
        'custom-candy-box': this.postCustomCandyBox,
        'fazer': this.postFazerBox,
      }
      return products[item]
    },
    goToEditor(item) {
      let self = this
      self.setSpinnerState(true)
      this.login({})
      .then(() => {
        let token = self.$store.state.postLoginData.token
        setAxiosAuthToken(token)
      })
        .then(() => {
          self.setSpinnerState(false)
          if (item == 'domino') {
            self.$router.push({name: 'upload', params: {product: item}})
          } else {
            let data = {
              'product': this.$store.getters.getProductId(this.routerParamToProductId(item)),
              'type': 'ALL_TO_SAME_ADDRESS', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
              'csv_status': 'NO_CSV_NEEDED', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
              'draft': true
            }
            if (this.$store.state.order != null) {
              let order_id = this.$store.state.order.id
              if (order_id != undefined) {
                data['order_id'] = order_id
              }
            }
            this.getPostFunction(item)(data)
              .then(() => {
                if (item == 'fazer') {
                  self.$router.push({name: 'editor', params: {uuid: self.getOrderData().last_product}})
                } else {
                  self.$router.push({name: 'editor-tab', params: {uuid: self.getOrderData().last_product}})
                }
              })
          }
        })
        .catch(function (error) {
          if (!error.response) {
            // no response from server.. maybe network down?
            self.setSpinnerState(false)
            self.errors = Object.assign({}, initial_errors, {non_field_errors: [error.message]}) // combine initial and response
          } else {
            self.setSpinnerState(false)
            self.errors = Object.assign({}, initial_errors, error.response.data) // combine initial and response
          }
        })
    },
  },
  mounted() {
    this.getProducts()
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/mybulma';

h1 {
  color: $primary !important;
  font-size: 45px !important;
}

h3 {
  color: $primary !important;
}

ol {
  counter-reset: item;
  margin-bottom: 2rem;
}

li {
  display: block;
  font-weight: bold;

  li{
    font-weight: normal;
  }
}

li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
  font-weight: bold;
}

.content {
  padding: 20px;
}

button {
  span {
    font-size: 20px;
  }
}

.middle-section {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  text-align: center;
  background-color: #EBEFF5;
  figure.image {
    margin-top: 15px;
    margin-bottom: 0px;
    img {
      width: 264px;
    }
  }
  p {
    font-weight: bold;
    font-size: 23px;
    color: #15256B;
    margin-left: 2em;
    margin-right: 2em;
  }
  button {
    margin-bottom: 20px;
  }
}
</style>
