<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="isActive" @keydown.esc=closeModal() tabindex="0" ref="modalroot" :class="{ 'international-modal': international }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="buttons is-right head-button-wrapper">
            <button class="button is-secondary is-inverted" @click="closeModal">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body">
          <p class="title is-size-4">Toimitusosoite</p>
          <div v-for="(delivery_address, index) in productsWithAddresses" :key="delivery_address.id">
            <label class="add-address" v-if="((international && delivery_address.recv_country != 'Suomi') || (!international && delivery_address.recv_country == 'Suomi'))">
              <input type="radio" :id="delivery_address.id" :value="delivery_address.id" v-model="address_selector" @click="autoFillAddress(delivery_address)">
              <span class="graphical-radio"></span>
              {{addressParser(delivery_address)}}
            <br></label>
          </div>
          <div class="mb-4">
            <label class="add-address">
              <input type="radio" id="new_address" value="new_address" v-model="address_selector" @click="resetAddress()">
              <span class="graphical-radio"></span>
              Uusi osoite
            </label><br>
          </div>

          <div class="field">
            <div class="control">
              <input
                  v-model=address.recv_name
                  class="input"
                  type="text"
                  placeholder="Lähetyksen vastaanottajan nimi"
              />
            </div>
            <p v-if="recv_name_error" class="help is-danger">Tämä kenttä on pakollinen</p>
          </div>

          <div class="field">
            <div class="control">
              <input v-model=address.recv_company
                     class="input"
                     type="text"
                     placeholder="Yrityksen/Yhdistyksen nimi"/>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input v-model=address.recv_phone class="input" type="text" placeholder="Puhelinnumero (esim +358401234567)"/>
            </div>
            <p v-if="recv_phone_error" class="help is-danger">Syötä puhelinnumero oikeassa muodossa (esim +358401234567). Puhelinnumerossa tulee olla 7-15 numeroa. Numerossa tulee olla suuntanumero eikä siinä saa olla välilyöntejä.</p>
          </div>

          <div class="field">
            <div class="control">
              <input v-model=address.recv_street class="input" type="text" placeholder="Katuosoite"/>
            </div>
            <p v-if="recv_street_error" class="help is-danger">Tämä kenttä on pakollinen</p>
          </div>

          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input v-model=address.recv_zip class="input" type="text" placeholder="Postinumero"/>

                </p>
                <p v-if="recv_zip_error" class="help is-danger">Tämä kenttä on pakollinen</p>
              </div>
              <div class="field">
                <p class="control is-expanded">
                  <input v-model=address.recv_town class="input" type="text" placeholder="Postitoimipaikka"/>
                </p>
                <p v-if="recv_town_error" class="help is-danger">Tämä kenttä on pakollinen</p>
              </div>
            </div>
          </div>

          <div class="field" v-if="!international">
            <div class="control">
              <input class="input" disabled type="text" v-model="address.recv_country"/>
            </div>
          </div>

          <div class="field" v-else>
            <div class="control country-selector" @click="country_dropdown_active=!country_dropdown_active">
              <div class="button is-small" type="button">
                <span v-if="selected_country == ''" style="font-size: 1rem; color: #dedbd9; font-weight: normal;">Valitse maa</span>
                <span v-else style="font-size: 1rem;">{{selected_country}}</span>
              </div>
               <div class="country-selector-arrow"><i class="fa-solid fa-arrow-down"></i></div>
              <p v-if="recv_country_error" class="help is-danger">Tämä kenttä on pakollinen</p>

              <div class="dropdown" :class="{ 'is-open': country_dropdown_active == true }">
                <ul>
                  <li v-for="(country, idx) in countries" @click="selectCountry(country)"><a class="nav-item">{{country}}</a></li>
                </ul>
              </div>
            </div>
          </div>

          <slot></slot>
          <div class="buttons buttons-footer columns mt-5 mr-3">
            <button class="button column is-offset-10 is-primary" @click="answerOK">Tallenna</button>
          </div>
        </section>
      </div>
    </div>
  </teleport>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import {mapMutations} from 'vuex'
import { uuid } from 'vue-uuid';

export default {
  name: 'deliveryAddressModal',
  emits: ['modal-result-ok'],
  data() {
    return {
      isActive: false,
      activeStep: 1,
      resolve: null,
      address: {
        id: uuid.v1(),
        recv_name: '',
        recv_company: '',
        recv_phone: '',
        recv_street: '',
        recv_zip: '',
        recv_town: '',
        recv_country: ''
      },
      original_address: undefined,
      submitted: false,
      address_selector: 'new_address',
      country_dropdown_active: false,
      countries: [
        'Alankomaat',
        'Belgia',
        'Espanja',
        'Irlanti',
        'Italia',
        'Itävalta',
        'Kreikka',
        'Kroatia',
        'Kypros',
        'Latvia',
        'Liettua',
        'Luxemburg',
        'Malta',
        'Puola',
        'Portugali',
        'Ranska',
        'Ruotsi',
        'Saksa',
        'Slovakia',
        'Slovenia',
        'Tanska',
        'Tšekki',
        'Unkari',
        'Viro'
      ],
      selected_country: ''
    }
  },
  props: {
    productId: {
      type: String,
      default: '',
    },
    international: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.productId != '') {
      let product = this.$store.getters.getProductById(this.productId)
      if (product.delivery_address != undefined) {
        this.address = product.delivery_address
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData',
      products: 'products',
      getOrderDeliveryAddress: 'getOrderDeliveryAddress'
    }),
    ...mapActions([
      'getDeliveryAddress'
    ]),
    showModal(productId) {
      this.getDeliveryAddress().then(() => {
        this.submitted = false
        this.country_dropdown_active = false
        document.documentElement.style.overflow = 'hidden'
        this.isActive = true

        // init product address data
        let product = undefined
        if (productId != '') {
          product = this.$store.getters.getProductById(productId)
          if (product.delivery_address != undefined && ((product.delivery_address.recv_country == 'Suomi' && product.address_type != 'INTERNATIONAL_FREIGHT') || (product.delivery_address.recv_country != 'Suomi' && product.address_type == 'INTERNATIONAL_FREIGHT'))) {
            this.address = Object.assign({}, product.delivery_address)
            this.address_selector = product.delivery_address.id
          } else {
            this.address_selector = 'new_address'
            this.address = {
              id: uuid.v1(),
              recv_name: '',
              recv_company: '',
              recv_phone: '',
              recv_street: '',
              recv_zip: '',
              recv_town: '',
              recv_country: ''
            }
            this.selected_country = ''
          }
        }

        if (!this.international) {
          this.address.recv_country = 'Suomi'
        }

        this.$nextTick().then(() => {
          this.$refs.modalroot.focus()
        })
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      })
    },
    setActiveStep(step) {
      this.activeStep = step;
    },
    answerOK(...args) {
      this.submitted = true
      if (!this.international) {
        this.address.recv_country = 'Suomi'
      }
      if (this.recv_name_error || this.recv_country_error || this.recv_town_error || this.recv_street_error || this.recv_zip_error || this.recv_phone_error) {
        return
      }
      let product = this.$store.getters.getProductById(this.productId)
      product.delivery_address = this.address
      document.documentElement.style.overflow = 'auto'
      this.$emit('modal-result-ok')
      this.isActive = false
      if (this.resolve != null) {
        return this.resolve(...args)
      }
    },
    closeModal() {
      document.documentElement.style.overflow = 'auto'
      this.isActive = false
      this.address = this.original_address
    },
    addressParser(delivery_address) {
      let company = delivery_address.recv_company
      if (company != '') {
        company = company + ', '
      }
      return delivery_address.recv_name + ', ' + company + delivery_address.recv_street
      + ', ' + delivery_address.recv_zip  + ', ' + delivery_address.recv_town + ', ' + delivery_address.recv_phone      + ', ' + delivery_address.recv_country

    },
    resetAddress() {
      this.submitted = false
      this.selected_country = ''
      this.address = {
        id: uuid.v1(),
        recv_name: '',
        recv_company: '',
        recv_phone: '',
        recv_street: '',
        recv_zip: '',
        recv_town: '',
        recv_country: ''
      }
      if (!this.international) {
        this.address.recv_country = 'Suomi'
      }
    },
    autoFillAddress(delivery_address) {
      this.address = delivery_address
      this.selected_country = delivery_address.recv_country
    },
    selectCountry(country) {
      this.selected_country = country
      this.address.recv_country = country
    }
  },
  computed: {
    productsWithAddresses() {
      return this.getOrderDeliveryAddress()
    },
    recv_name_error() {
      if (this.submitted && this.address.recv_name == '') {
        return true
      }
      return false
    },
    recv_phone_error() {
      if (this.submitted) {
        if (this.address.recv_phone == '') {
          return true
        } else {
          if (this.address.recv_phone.startsWith('+') && this.address.recv_phone.length >= 8 && this.address.recv_phone.length < 17) {
            return false
          } else {
            return true
          }
        }
      }
      return false
    },
    recv_street_error() {
      if (this.submitted && this.address.recv_street == '') {
        return true
      }
      return false
    },
    recv_zip_error() {
      if (this.submitted && this.address.recv_zip == '') {
        return true
      }
      return false
    },
    recv_town_error() {
      if (this.submitted && this.address.recv_town == '') {
        return true
      }
      return false
    },
    recv_country_error() {
      if (this.submitted && (this.address.recv_country == '' || this.address.recv_country == undefined)) {
        return true
      }
      return false
    },
  },
  watch: {
     international: function (new_val, old_val) {
        if (!this.international && this.address != undefined) {
          this.address.recv_country = 'Suomi'
        }
     }
   }
}
</script>
<style scoped lang="scss">

.modal-card-head {
  position: relative;
  .head-button-wrapper {
    position: absolute;
    top: 6px;
    right: 0;
  }

}
.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}
.international-modal {
  .modal-card {
    height: 600px !important;
  }
  .buttons-footer {
    margin-top: 100px !important;
  }
}

.modal {
  z-index: 100;
  .modal-card {
    .modal-steps{
      margin-top: 10px;
      h4{
        font-size: 23px;
        font-weight: 500;
        color: #222
      }
      div{
        display: flex;
        gap: 10px;

        span{
          height: 44px;
          width: 44px;
          border-radius: 50%;
          font-size: 20px;
          font-weight: 600;
          background-color: #EBEFF5;
          color: #15256B;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active{
            background-color: #15256B;
            color: #fff;
          }
        }
      }
    }
    @media (max-width: 500px) {
      min-width: 0px !important;
      width: 100% !important;
    }
  }
}

.buttons {
  margin-left: 15px;
  .button {
    min-width: 50px;
    text-align: center;
    display: block;
  }
}

input[type="radio"] {
  display: none;
}

.graphical-radio {
  background: white;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #15256B;
  margin-right: 6px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 4px;
}

input[type="radio"]:checked + .graphical-radio {
  background: #15256B;
}

label {
  font-size: 15px;
  padding-bottom: 5px;
  display: inline-block;
  color: black;
}

label.add-address:hover {
  cursor: pointer;
}

.dropdown {
  box-shadow: 0 0 2px #777;
  display: none;
  left: 0;
  position: absolute;
  padding: 2px;
  z-index: 10;
  max-height: 165px;
  overflow-y: scroll;
  font-size: 15px;
  color: #dedbd9;
  width: 125px;
  li {
    a {
      color: black;
    }
  }
}

.dropdown a {
  font-size: 12px;
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
}

.dropdown.is-open {
  display: block;
}

.country-selector {
  border: 2px solid #dedbd9;
  .button {
    font-size: 1rem;
    font-weight: normal;
    font-family: gill-sans-nova;
  }
  .button:hover {
    border: none !important;
  }
}

.country-selector-arrow {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  color: #dedbd9;
}

</style>
