<template>
  <section class="hero is-fullheight landing-wrapper">
    <div class="hero-body py-0">
      <div class="container">
        <div class="columns is-multiline is-relative">
          <div
            class="column is-4 is-offset-4 pm-bg is-flex is-flex-direction-column is-justify-content-center"
          >
            <p class="has-text-centered mb-2">PM Scanner</p>
            <input
              type="text"
              class="pm-input has-text-centered"
              ref="pmInputRef"
              :maxlength="MAX_LENGTH"
              v-model="inputValue"
              :disabled="spinning"
              autofocus
            />
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-center">
            <p class="has-text-centered">History ({{ history.length }})</p>
            <div class="history-wrapper">
              <a
                class="history-link is-block has-text-centered"
                v-for="(obj, idx) in history"
                :key="idx"
                :style="{ backgroundColor: idx % 2 === 0 ? '#E0E0E0' : 'transparent' }"
                v-on:mouseover="onHoverEvent(idx)"
                v-on:mouseleave="cleanHoverEvent"
                >{{ obj.id }} <br />
                {{ obj.datetime }}</a
              >
            </div>
          </div>
          <div class="spinner-wrapper" v-if="spinning">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
          <div class="detail-wrapper" v-if="isItemHover">
            <p class="has-text-centered has-text-weight-bold mb-2">{{ hoverItem.id }}</p>
            <p class="has-text-centered">Order id: {{ hoverItem.order_id }}</p>
            <p class="has-text-centered">Calendar id: {{ hoverItem.id }}</p>
            <p class="has-text-centered">{{ hoverItem.name }} {{ hoverItem.last_name }}</p>
            <p class="has-text-centered">{{ hoverItem.email }}</p>
            <p class="has-text-centered">{{ hoverItem.street }}, {{ hoverItem.zip }}</p>
            <p class="has-text-centered">{{ hoverItem.town }}</p>
            <p class="has-text-centered">{{ hoverItem.phone }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, reactive, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'LandingPage',
  components: {},
  setup() {
    const store = useStore()
    const history = ref([])
    const MAX_LENGTH = 10
    const inputValue = ref('')
    const pmInputRef = ref(null)
    const spinning = ref(false)
    const isItemHover = ref(false)
    let hoverItem = reactive({})
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Use 24-hour time format
    }

    onMounted(() => {
      document.addEventListener('mouseup', (e) => {
        pmInputRef.value.focus()
      })
    })

    watch(inputValue, (newValue, oldValue) => {
      if (newValue.length === MAX_LENGTH) {
        // api call
        spinning.value = true
        store.dispatch('scanBarCode', {id:newValue}).then(() => {
          let res = store.getters.getBarCodeData
          history.value.unshift({
            datetime: new Date().toLocaleString('en-GB', options),
            id: newValue,
            order_id: res.order_id,
            name: res.name,
            lastName: res.last_name,
            phone: res.phone,
            zip: res.zip,
            town: res.town,
            street: res.street,
            email: res.email,
            week: res.week
          })

          const fileName = res.week + '_omakalenteri-label-' + res.order_number + '.pdf'
          downloadPDF(res.pdf, fileName)

          spinning.value = false
          inputValue.value = ''
        })
      }
    })

    watch(spinning, (newValue, oldValue) => {
      if ((oldValue === true) & (newValue === false)) {
        // Focus input after removing input disabled
        setTimeout(() => {
          // Without settimeout it doesn't work
          pmInputRef.value.focus()
        }, 300)
      }
    })

    const onHoverEvent = (idx) => {
      isItemHover.value = true
      Object.assign(hoverItem, history.value[idx])
    }

    const cleanHoverEvent = () => {
      isItemHover.value = false
      Object.assign(hoverItem, {})
    }

    const downloadPDF = async (pdf, filename) => {
      try {
        const response = await fetch(pdf)
        if (!response.ok) throw new Error('Download error')

        const blob = await response.blob()

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename

        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
        window.URL.revokeObjectURL(link.href)
      } catch (error) {
        console.error('Download error ', error)
      }
    }

    return {
      inputValue,
      history,
      spinning,
      pmInputRef,
      MAX_LENGTH,
      isItemHover,
      onHoverEvent,
      cleanHoverEvent,
      hoverItem
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

.columns {
  display: flex;
  height: 100vh;
}

.pm-bg {
  background-color: #24b5bc;
}
.pm-input {
  height: 30px;
  min-width: 280px;
}

.history-wrapper {
  height: 218px;
  overflow: hidden;
  overflow-y: scroll;
}

.history-link {
  font-size: 16px;
}

.spinner-wrapper {
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
}

.detail-wrapper {
  border-radius: 15px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  background-color: grey;
  width: 340px;
  height: 250px;
  p {
    color: white;
    font-size: 16px;
  }
}
</style>
