<template>
  <teleport v-if="spinning" to="#modals">
    <div class="modal spinner is-active is-layout-fixed">
      <div class="modal-background"></div>
      <div class="modal-content is-clipped">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'SpinnerModal',
  props: {
    spinning: Boolean,
  },
  watch: {
    spinning: function (new_val, old_val) {
      if (new_val == true) {
        document.documentElement.style.overflow = 'hidden'
      }else{
        document.documentElement.style.overflow = 'auto'
      }
    },
  },
}
</script>

<style scoped>
.is-layout-fixed{
  z-index: 99999;
  position: fixed !important;
}
</style>
